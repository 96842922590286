import React from 'react';
import styled from 'styled-components/macro';
import { TrainingRecordByCourseDto, TrainingRecordDataDto } from '../../models/training-record';
import { ExpandableComponent } from '../../shared/ExpandableComponent';
import { Header5, Header6 } from '../../shared/Headers';

import { loraxShade, midnight, midnightMediumTint } from '../../styling/colours';
import { fontBase, fontHeading6, fontSmall } from '../../styling/fonts';
import { spacingXXS } from '../../styling/spacing';

interface UserCourseTableProps {
  course: TrainingRecordByCourseDto;
}

export const UserCourseTable = ({ course }: UserCourseTableProps) => {
  const latestCourse = course.trainingRecords[0];
  const checkIfCourseExpired = (course: TrainingRecordDataDto) => {
    if (course.expiryDate == null) {
      return false;
    }

    const today = new Date();
    const expiry = new Date(course.expiryDate);

    return expiry <= today;
  };

  return (
    <ExpandableComponent
      key={course.courseName}
      header={
        <ExpandableHeader>
          <TableRowContainer>
            <CourseInfo>{course.courseName}</CourseInfo>
            <CourseInfo>{latestCourse.completionDate}</CourseInfo>
            {checkIfCourseExpired(latestCourse) ? (
              <CourseExpiredInfo>Expired {latestCourse.expiryDate}</CourseExpiredInfo>
            ) : (
              <CourseInfo>{latestCourse.expiryDate}</CourseInfo>
            )}
            <CourseInfo>{latestCourse.notes}</CourseInfo>
          </TableRowContainer>
        </ExpandableHeader>
      }
    >
      <>
        <CourseHistoryHeader>Course History</CourseHistoryHeader>
        <TableHeaderGroup>
          <TableHeader>Completion date</TableHeader>
          <TableHeader>Expiry date</TableHeader>
          <TableHeader>Notes</TableHeader>
          <TableHeader>Approval Status</TableHeader>
        </TableHeaderGroup>
        {course.trainingRecords.map((singleCourse: TrainingRecordDataDto, key) => (
          <TableRowContainerSmaller key={key} isApproved={singleCourse.isApproved}>
            <CourseInfoSmaller>{singleCourse.completionDate}</CourseInfoSmaller>
            {checkIfCourseExpired(singleCourse) ? (
              <CourseExpiredInfoSmaller>Expired {singleCourse.expiryDate}</CourseExpiredInfoSmaller>
            ) : (
              <CourseInfoSmaller>{singleCourse.expiryDate}</CourseInfoSmaller>
            )}
            <CourseInfoSmaller>{singleCourse.notes}</CourseInfoSmaller>
            <CourseInfoSmaller>
              {singleCourse.isApproved ? 'Approved' : 'Awaiting Approval'}
            </CourseInfoSmaller>
          </TableRowContainerSmaller>
        ))}
      </>
    </ExpandableComponent>
  );
};

const ExpandableHeader = styled.div`
  align-items: center;
  width: 100%;
  font: ${fontHeading6};
`;

const CourseHistoryHeader = styled(Header5)`
  margin-bottom: ${spacingXXS};
  font-size: ${fontBase};
`;

const TableHeader = styled(Header6)`
  margin-bottom: ${spacingXXS};
  width: 33%;
  font-size: ${fontSmall};
`;

const TableHeaderGroup = styled.div`
  display: flex;
  width: 80%;
  justify-content: space-evenly;
`;

const TableRowContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-evenly;
`;

const TableRowContainerSmaller = styled(TableRowContainer)<{ isApproved?: boolean }>`
  width: 80%;
  color: ${(props) => (props.isApproved ? midnight : midnightMediumTint)};
`;

const CourseInfo = styled.div`
  text-align: center;
  font-weight: normal;
  width: 25%;
  text-align: center;
`;

const CourseInfoSmaller = styled(CourseInfo)`
  width: 33%;
  text-align: left;
  font-size: ${fontSmall};
`;

const CourseExpiredInfoSmaller = styled(CourseInfoSmaller)`
  color: ${loraxShade};
`;

const CourseExpiredInfo = styled(CourseInfo)`
  color: ${loraxShade};
`;
