import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { isFetchJsonError } from '../../api';
import { useCourseApi } from '../../hooks/useCourseApi';
import { useTrainingRecordApi } from '../../hooks/useTrainingRecordApi';
import { CourseDto, ReporterDto } from '../../models/course';
import {
  CreateAndApproveMultipleTrainingRecordsDto,
  CreateTrainingRecordDto,
  EditMultipleTrainingRecordsDto,
  GetLatestTrainingRecordsByCourseIdDto,
} from '../../models/training-record';
import { AlertType } from '../../shared/Alert';
import { ButtonRow, CancelButton, DangerButton } from '../../shared/Button';
import { LargeLoadingIndicator } from '../../shared/LargeLoadingIndicator';
import { Modal } from '../../shared/Modal';
import { AssertUserHasPermission } from '../../shared/permission/AssertUserHasPermission';
import {
  AlertIfRequiredByLocationState,
  SingleUseAlertIfRequiredByLocation,
} from '../../shared/SingleUseAlertIfRequiredByLocation';
import { CurrentUserContext } from '../../shared/user/CurrentUserContext';
import { alertText } from '../../styling/colours';
import { spacingM, spacingS } from '../../styling/spacing';
import { isUserCourseReporterOrAdminCourseDto } from '../../utils/auth';
import { EmployeesToBeAddedFormValues } from './BulkAddTrainingRecordsForm';
import { CourseOverviewBody } from './CourseOverviewBody';
import { CourseOverviewTableFormValues } from './CourseOverviewTableForm';
import { CourseOverviewHeader } from './CourseOverviewHeader';

type CourseOverviewParams = {
  id: string;
};

export const CourseOverview = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentCourseDto, setCurrentCourseDto] = useState<CourseDto | null>(null);
  const [reporterIdsToUsers, setReporterIdsToUsers] = useState<{
    [id: number]: ReporterDto;
  } | null>(null);
  const [
    trainingCourseInfo,
    setTrainingCourseInfo,
  ] = useState<GetLatestTrainingRecordsByCourseIdDto | null>(null);
  const [isAddEmployeesSelected, setIsAddEmployeesSelected] = useState<boolean>(false);
  const [isAboutToDeleteCourse, setIsAboutToDeleteCourse] = useState<boolean>(false);
  const { currentUser } = useContext(CurrentUserContext);
  const { getCourse, deleteCourse } = useCourseApi();
  const {
    editMultipleTrainingRecords,
    createMultipleTrainingRecordsForCourseId,
    getLatestTrainingRecordsPerActiveUserFromCourseId,
  } = useTrainingRecordApi();

  const urlParams = useParams<CourseOverviewParams>();
  const history = useHistory<AlertIfRequiredByLocationState>();

  const refreshCourse = useCallback(() => {
    getCourse(urlParams.id).then((courseResponse) => {
      if (!isFetchJsonError(courseResponse)) {
        setCurrentCourseDto(courseResponse);
        const newReporterIdsToUsers: { [id: number]: ReporterDto } = {};
        courseResponse.reporters.forEach((reporter) => {
          newReporterIdsToUsers[reporter.id] = reporter;
        });
        setReporterIdsToUsers(newReporterIdsToUsers);
      }
    });
  }, [getCourse, urlParams.id]);

  const refreshTrainees = useCallback(() => {
    getLatestTrainingRecordsPerActiveUserFromCourseId(urlParams.id).then(
      (latestTrainingRecordsPerActiveUser) => {
        if (!isFetchJsonError(latestTrainingRecordsPerActiveUser)) {
          setTrainingCourseInfo(latestTrainingRecordsPerActiveUser);
        }
      },
    );
  }, [getLatestTrainingRecordsPerActiveUserFromCourseId, urlParams]);

  useEffect(() => {
    setIsLoading(true);
    refreshCourse();
    refreshTrainees();
    setIsLoading(false);
  }, [refreshCourse, refreshTrainees]);

  if (
    isLoading ||
    currentCourseDto == null ||
    trainingCourseInfo == null ||
    reporterIdsToUsers == null
  ) {
    return <LargeLoadingIndicator />;
  }

  const pushErrorToHistory = (message: string) => {
    history.push({
      pathname: window.location.pathname,
      state: {
        alert: {
          title: 'Error',
          children: message,
          type: AlertType.Error,
          closeable: true,
        },
      },
    });
  };

  const handleDeleteButtonClicked = () => {
    deleteCourse(urlParams.id).then((response) => {
      if (!isFetchJsonError(response) && response) {
        history.push({
          pathname: '/',
          state: {
            alert: {
              title: 'Success',
              children: `Course named ${currentCourseDto.courseName} successfully deleted`,
              type: AlertType.Success,
              closeable: true,
            },
          },
        });
      } else {
        pushErrorToHistory(
          `Unable to delete course named ${currentCourseDto.courseName}` +
            (isFetchJsonError(response) ? `: ${response.errorMessage}` : ''),
        );
      }
    });
  };

  const DeleteCourseModal = () => {
    return (
      <AssertUserHasPermission permission={'CreateEditCourses'}>
        <DeleteModal isOpen={isAboutToDeleteCourse}>
          <DeleteModalText>Are you ABSOLUTELY sure you want to delete this course?</DeleteModalText>
          <ButtonRow>
            <DangerButton onClick={handleDeleteButtonClicked}>YES</DangerButton>
            <CancelButton onClick={() => setIsAboutToDeleteCourse(false)}>No</CancelButton>
          </ButtonRow>
        </DeleteModal>
      </AssertUserHasPermission>
    );
  };

  const approveTrainingRecords = async (values: CourseOverviewTableFormValues): Promise<void> => {
    const editedTrainingRecordListDto: EditMultipleTrainingRecordsDto = {
      trainingRecords: Object.values(values.trainingRecordsToBeActioned).map((dto) => ({
        id: dto.id,
        completionDate: dto.completionDate,
        expiryDate: dto.expiryDate,
        notes: dto.notes,
        isApproved: true,
      })),
    };

    await editMultipleTrainingRecords(editedTrainingRecordListDto).then((response) => {
      if (!isFetchJsonError(response)) {
        refreshTrainees();
      } else {
        pushErrorToHistory(`Unable to approve training records: ${response.errorMessage}`);
      }
    });
  };

  const createNewTrainingRecords = async (values: EmployeesToBeAddedFormValues): Promise<void> => {
    const trainingCourseListDto: CreateAndApproveMultipleTrainingRecordsDto = {
      trainingRecords: values.employeeIdsToBeAddedToCourse.map(
        (id): CreateTrainingRecordDto => ({
          courseId: parseInt(urlParams.id),
          userId: id,
          completionDate: values.completionDate,
          expiryDate: values.expiryDate.length > 0 ? values.expiryDate : undefined,
          notes: values.notes,
        }),
      ),
    };

    await createMultipleTrainingRecordsForCourseId(trainingCourseListDto, urlParams.id).then(
      (response) => {
        if (!isFetchJsonError(response)) {
          refreshTrainees();
        } else {
          pushErrorToHistory(`Unable to create new training records: ${response.errorMessage}`);
        }
      },
    );
  };

  const handleOnBulkAddEmployeesFormSubmitted = (values: EmployeesToBeAddedFormValues) => {
    setIsLoading(true);
    createNewTrainingRecords(values).then((_response) => {
      setIsAddEmployeesSelected(false);
      setIsLoading(false);
    });
  };

  const handleOnApproveFormSubmitted = (values: CourseOverviewTableFormValues) => {
    setIsLoading(true);
    approveTrainingRecords(values).then((_response) => {
      setIsAddEmployeesSelected(false);
      setIsLoading(false);
    });
  };

  if (!isUserCourseReporterOrAdminCourseDto(currentCourseDto, currentUser)) {
    return (
      <p>You do not have access to this page. If this is incorrect, contact the Helpdesk team.</p>
    );
  }

  return (
    <CourseOverviewContentContainer>
      {isAboutToDeleteCourse ? <DeleteCourseModal /> : <></>}
      <SingleUseAlertIfRequiredByLocation />
      <CourseOverviewHeader course={currentCourseDto} />
      <CourseOverviewBody
        currentCourseDto={currentCourseDto}
        onApproveSubmit={handleOnApproveFormSubmitted}
        onBulkAddEmployeesSubmit={handleOnBulkAddEmployeesFormSubmitted}
        onResetChanges={() => setIsAddEmployeesSelected(false)}
        latestTrainingRecordDataDtos={trainingCourseInfo}
        onAddEmployeesButtonClicked={() => setIsAddEmployeesSelected(true)}
        onCancelAddEmployeesButtonClicked={() => setIsAddEmployeesSelected(false)}
        onDeleteCourseButtonClicked={() => setIsAboutToDeleteCourse(true)}
        isAddAttendeesSelected={isAddEmployeesSelected}
        defaultExpiryTime={currentCourseDto.defaultExpiryTime}
      />
    </CourseOverviewContentContainer>
  );
};

const CourseOverviewContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`;

const DeleteModal = styled(Modal)`
  padding: ${spacingM};
`;

const DeleteModalText = styled.p`
  padding-bottom: ${spacingS};
  color: ${alertText};
`;
