import styled from 'styled-components/macro';
import React from 'react';
import { CourseDto } from '../../models/course';
import { Header2 } from '../../shared/Headers';
import { EmployeeItem } from '../../shared/user/EmployeeItem';
import { getPathToUserCourses } from '../view-user/ViewUser';
import { getTimeStringInYearsMonthsWeeksAndDays } from '../../utils/dates';
import { InlineLink } from '../../shared/Link';
import { spacingL, spacingM, spacingS, spacingXS, spacingXXS } from '../../styling/spacing';
import { revolver } from '../../styling/colours';
import { fontParagraph } from '../../styling/fonts';

type Props = {
  course: CourseDto;
};
export const CourseOverviewHeader = ({ course }: Props) => {
  return (
    <>
      <CourseNameHeaderContainer>
        <Header2>{course.courseName}</Header2>
        <StyledLink to={`/course/${course.id}/edit`}>Edit course</StyledLink>
      </CourseNameHeaderContainer>
      <InfoContainer>
        <InfoItem>
          <CourseDetailsHeader>Reporters</CourseDetailsHeader>
          {course.reporters.map((reporter) => (
            <ReporterContainer key={reporter.name}>
              <EmployeeItem
                name={reporter.name}
                azureId={reporter.azureId}
                fishId={reporter.id}
                link={getPathToUserCourses(reporter.id)}
              />
            </ReporterContainer>
          ))}
        </InfoItem>
        <InfoItem>
          <CourseDetailsHeader>Requires all employees</CourseDetailsHeader>
          <p>{course.requiredForAllEmployees ? 'Yes' : 'No'}</p>
        </InfoItem>
        <InfoItem>
          <CourseDetailsHeader>Default expiry time</CourseDetailsHeader>
          <p>
            {course.defaultExpiryTime
              ? getTimeStringInYearsMonthsWeeksAndDays(course.defaultExpiryTime)
              : 'N/A'}
          </p>
        </InfoItem>
      </InfoContainer>
    </>
  );
};

const CourseNameHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledLink = styled(InlineLink)`
  font-weight: bold;
`;

const InfoContainer = styled.div`
  display: flex;
  padding-top: ${spacingM};
  padding-bottom: ${spacingM};
`;

const InfoItem = styled.div`
  padding-left: ${spacingS};
  padding-right: ${spacingL};
`;

const CourseDetailsHeader = styled.div`
  margin-bottom: ${spacingM};
`;

const ReporterContainer = styled.div`
  border-style: solid;
  border-color: ${revolver};
  border-width: 1px;
  text-align: left;
  padding: ${spacingXS} ${spacingS};
  font: ${fontParagraph};
  margin-bottom: ${spacingXXS};
  border-radius: 5px;
`;
