import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { isFetchJsonError } from '../../api';
import { useCourseApi } from '../../hooks/useCourseApi';
import { useTrainingRecordApi } from '../../hooks/useTrainingRecordApi';
import { CoursesDto } from '../../models/course';
import { TrainingRecordDto, EditTrainingRecordDto } from '../../models/training-record';
import { AlertType } from '../../shared/Alert';
import { Header2 } from '../../shared/Headers';
import { LargeLoadingIndicator } from '../../shared/LargeLoadingIndicator';
import {
  AlertIfRequiredByLocationState,
  SingleUseAlertIfRequiredByLocation,
} from '../../shared/SingleUseAlertIfRequiredByLocation';
import {
  TrainingRecordForm,
  CreateTrainingRecordForm,
} from '../../shared/training-record/TrainingRecordForm';
import { CurrentUserContext } from '../../shared/user/CurrentUserContext';
import { isUserCourseReporterOrAdminCourseInCourseListDto } from '../../utils/auth';

export type EditTrainingRecordParams = {
  id: string;
};

type LocationState = {
  prevPath: string;
};

export const EditTrainingRecord = () => {
  const [trainingRecord, setTrainingRecord] = useState<TrainingRecordDto>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [courses, setCourses] = useState<CoursesDto>();

  const { editTrainingRecord, getTrainingRecord } = useTrainingRecordApi();
  const { getAllCourses } = useCourseApi();
  const { currentUser } = useContext(CurrentUserContext);

  const params = useParams<EditTrainingRecordParams>();
  const history = useHistory<AlertIfRequiredByLocationState>();

  const locationState = useLocation<LocationState>();
  const prevPath = locationState.state?.prevPath ?? undefined;

  const refreshTrainingRecord = useCallback(() => {
    setIsLoading(true);
    getTrainingRecord(params.id).then((res) => {
      if (!isFetchJsonError(res)) {
        setTrainingRecord(res);
        setIsLoading(false);
      }
    });
  }, [getTrainingRecord, params]);

  const refreshCourseList = useCallback(() => {
    setIsLoading(true);
    getAllCourses().then((res) => {
      if (!isFetchJsonError(res)) {
        setCourses(res);
        setIsLoading(false);
      }
    });
  }, [getAllCourses]);

  useEffect(() => {
    setIsLoading(true);
    refreshTrainingRecord();
    refreshCourseList();
    setIsLoading(false);
  }, [refreshTrainingRecord, refreshCourseList]);

  if (isLoading || trainingRecord == null || courses == null) {
    return <LargeLoadingIndicator />;
  }

  const mapCreateTrainingRecordFormToEditTrainingRecord = (
    createTrainingRecordForm: CreateTrainingRecordForm,
    hasDefault: boolean,
  ): EditTrainingRecordDto => {
    if (hasDefault) {
      return {
        id: parseInt(params.id),
        completionDate: createTrainingRecordForm.completionDate,
        expiryDate: null,
        notes: createTrainingRecordForm.notes ?? '',
        isApproved: trainingRecord.isApproved,
      };
    }
    return {
      id: parseInt(params.id),
      completionDate: createTrainingRecordForm.completionDate,
      expiryDate: createTrainingRecordForm.expiryDate ?? '',
      notes: createTrainingRecordForm.notes ?? '',
      isApproved: trainingRecord.isApproved,
    };
  };
  const onFormSubmit = (
    trainingRecordForm: CreateTrainingRecordForm,
    userId: string,
    hasDefault: boolean,
  ) => {
    const trainingRecordDto = mapCreateTrainingRecordFormToEditTrainingRecord(
      trainingRecordForm,
      hasDefault,
    );
    editTrainingRecord(trainingRecordDto, userId.toString()).then((res) => {
      if (isFetchJsonError(res)) {
        history.push({
          pathname: `/training-record/${params.id}/edit`,
          state: {
            alert: {
              title: 'Error',
              children: 'An error occurred whilst saving your changes',
              type: AlertType.Error,
              closeable: true,
            },
          },
        });
      } else {
        const submitSuccessPath = prevPath ?? `/course/${res.courseId}/overview`;
        history.push({
          pathname: submitSuccessPath,
          state: {
            alert: {
              title: 'Success',
              children: 'Your changes were saved successfully',
              type: AlertType.Success,
              closeable: true,
            },
          },
        });
      }
    });
  };

  const course = courses.courses.find((course) => {
    return course.id === Number(params.id);
  });

  if (
    course !== undefined &&
    !isUserCourseReporterOrAdminCourseInCourseListDto(course, currentUser)
  ) {
    return (
      <p>You do not have access to this page. If this is incorrect, contact the Helpdesk team.</p>
    );
  }

  return (
    <>
      <SingleUseAlertIfRequiredByLocation />
      <Header2>Edit Training Record</Header2>
      <TrainingRecordForm
        onSubmit={onFormSubmit}
        initialState={trainingRecord}
        courses={courses}
        disableSelect={true}
      />
    </>
  );
};
