import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { fontXl } from '../../../styling/fonts';
import ghystonG from '../../ghystonG.png';
import { AvatarContext } from './AvatarContext';

type AvatarSize = 'small' | 'medium' | 'large';

interface AvatarProps {
  azureId: string;
  name: string;
  size: AvatarSize;
}

export const Avatar: React.FC<AvatarProps> = ({ azureId, name, size }) => {
  const [image, setImage] = useState<string>('');
  const { getAvatar } = useContext(AvatarContext);

  useEffect(() => {
    if (azureId != null) {
      getAvatar(azureId).then((res) => {
        setImage(res);
      });
    }
  }, [azureId, getAvatar]);

  return <ProfileImage size={size} src={image || ghystonG} alt={`Profile Image of ${name}`} />;
};

const getSize = (size?: AvatarSize) => {
  switch (size) {
    case 'small':
      return '50px';
    case 'medium':
      return '60px';
    default:
      return '150px';
  }
};

const ProfileImage = styled.img<{ size?: AvatarSize }>`
  height: ${(props) => getSize(props.size)};
  width: ${(props) => getSize(props.size)};
  text-decoration: none;
  border-radius: 50%;
  font-size: ${fontXl};
  line-height: 10px;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  color: lightgray;
  text-align: center;
  flex-direction: column;
`;
