import { useField, useFormikContext } from 'formik';
import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { alertText, revolver } from '../../styling/colours';
import { fontParagraph } from '../../styling/fonts';
import { spacingXS, spacingXXXS } from '../../styling/spacing';
import { FormErrorMessage, FormLabel } from './SharedFormElements';

export type SelectOption<TValue> = {
  value: TValue;
  label: string;
};

export type SelectFieldProps<TValue> = {
  name: string;
  label: string;
  options: Array<SelectOption<TValue>>;
};

export const SelectField = <TValue,>({ options, label, ...props }: SelectFieldProps<TValue>) => {
  const [field, meta] = useField(props);
  const form = useFormikContext();

  const showError = !!meta.error && (meta.touched || form.submitCount > 0);

  return (
    <SelectInputContainer>
      <FormLabel>{label}</FormLabel>
      <StyledSelect
        {...field}
        {...props}
        classNamePrefix="Select"
        options={options}
        value={options.find((option) => option.value === field.value)}
        onChange={(option) =>
          form.setFieldValue(field.name, (option as SelectOption<TValue>).value)
        }
        showError={showError}
      />
      {showError && <FormErrorMessage>{meta.error}</FormErrorMessage>}
    </SelectInputContainer>
  );
};

const SelectInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledSelect = styled(Select)<{ showError: boolean }>`
  .Select__control {
    width: 100%;
    height: 54px;
    background: #ffffff 0 0 no-repeat padding-box;
    border: 1px solid ${(props) => (props.showError ? alertText : revolver)};
    border-radius: ${spacingXXXS};
    opacity: 1;

    font: ${fontParagraph};
    letter-spacing: 0;
    color: #333f48;

    .Select__value-container {
      padding: 0 ${spacingXS};
    }
  }
`;
