import React from 'react';
import { formatDate } from '../utils/dates';

export interface FormattedDateProps {
  isoDate?: string | null;
}

export const FormattedDate = (props: FormattedDateProps) => {
  if (!props.isoDate) {
    return null;
  }

  return <>{formatDate(props.isoDate)}</>;
};
