import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import React, { Context, createContext, useContext, useEffect, useState } from 'react';
import { ConfigContext } from '../config/ConfigContext';
import { LargeLoadingIndicator } from '../LargeLoadingIndicator';
import { createAuthProvider } from './authProvider';

export type AuthContextValue = {
  authProvider: IPublicClientApplication;
  acquireAccessToken: () => Promise<string>;
  requiredScopes: string[];
};

export const AuthContext: Context<AuthContextValue> = createContext<AuthContextValue>({
  authProvider: {} as IPublicClientApplication,
  acquireAccessToken: () => Promise.resolve(''),
  requiredScopes: [],
});

type Props = {
  children?: React.ReactNode;
};

export const AuthContextProvider = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [authProvider, setAuthProvider] = useState<IPublicClientApplication | null>(null);

  const { config } = useContext(ConfigContext);

  useEffect(() => {
    setLoading(true);
    createAuthProvider(config).then((authProvider) => {
      setAuthProvider(authProvider);
    });
    setLoading(false);
  }, [config]);

  if (loading || authProvider == null) {
    return <LargeLoadingIndicator />;
  }

  const requiredScopes = ['api://' + config.serverId + '/.default'];

  const acquireAccessToken = async () => {
    const activeAccount = authProvider.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = authProvider.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
      console.error('No active account or accounts found');
    }
    const request = {
      scopes: requiredScopes,
      account: activeAccount || accounts[0],
    };

    return authProvider.acquireTokenSilent(request).then((response) => {
      return response.accessToken;
    });
  };

  return (
    <AuthContext.Provider value={{ authProvider, acquireAccessToken, requiredScopes }}>
      <MsalProvider instance={authProvider}>{props.children}</MsalProvider>
    </AuthContext.Provider>
  );
};
