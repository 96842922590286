import React from 'react';
import styled from 'styled-components';
import { useUserApi } from '../../hooks/useUserApi';
import { HotDeskUserStatusDto } from '../../models/hot-desk';
import { Permission } from '../../models/permission';
import { GetRequest } from '../../shared/apiHelpers/GetRequest';
import { Header2 } from '../../shared/Headers';
import { InlineLink } from '../../shared/Link';
import { AssertUserHasPermission } from '../../shared/permission/AssertUserHasPermission';
import { ReactComponent as CheckIcon } from '../../shared/icons/check.svg';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderItem,
  TableHeaderRow,
  TableItem,
  TableRow,
} from '../../shared/Table';
import { UserAvatar } from '../../shared/UserAvatar';
import { spacingS } from '../../styling/spacing';
import { getPathToUserDesksSummary } from '../desks/UserDesksSummary';
export const testIdHotDesksOverviewPage = 'hot-desks-overview-page';
export const testIdHotDesksOverviewTableRow = 'hot-desks-overview-page-table-row';
export const hotDeskUsersTitle = 'Hot Desk Users';
export const permanentDeskUsersTitle = 'Permanent Desk Users';

export const HotDesksOverview = () => {
  const { fetchHotDesksOverview } = useUserApi();

  const requiredPermission: Permission = 'ReadUserHotDeskStatus';

  return (
    <GetRequest request={fetchHotDesksOverview}>
      {(overview) => (
        <div data-testid={testIdHotDesksOverviewPage}>
          <AssertUserHasPermission permission={requiredPermission}>
            <HotDeskStatusTable title={hotDeskUsersTitle} userStatusList={overview.hotDeskUsers} />
            <HotDeskStatusTable
              title={permanentDeskUsersTitle}
              userStatusList={overview.permanentDeskUsers}
            />
          </AssertUserHasPermission>
        </div>
      )}
    </GetRequest>
  );
};

type HotDeskStatusTableProps = {
  title: 'Hot Desk Users' | 'Permanent Desk Users';
  userStatusList: Array<HotDeskUserStatusDto>;
};

const HotDeskStatusTable: React.FC<HotDeskStatusTableProps> = ({ userStatusList, title }) => {
  if (userStatusList.length === 0) {
    return null;
  }

  userStatusList = userStatusList.sort(
    (a, b) =>
      Number(b.isRapidWorkspaceAssessmentTrained) - Number(a.isRapidWorkspaceAssessmentTrained),
  );

  return (
    <Section role="section">
      <Header2>{title}</Header2>
      <Table>
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderItem></TableHeaderItem>
            <TableHeaderItem>Employee name</TableHeaderItem>
            <TableHeaderItem>Email address</TableHeaderItem>
            <TableHeaderItem>Rapid workspace assessment trained</TableHeaderItem>
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {userStatusList.map((userStatus, index) => (
            <TableRow
              key={userStatus.user.id}
              data-testid={testIdHotDesksOverviewTableRow + `-${index}`}
            >
              <TableItem>
                <UserAvatar user={userStatus.user} />
              </TableItem>
              <TableItem>
                <DeskSummaryLink userId={userStatus.user.id} userName={userStatus.user.name} />
              </TableItem>
              <TableItem>{userStatus.user.emailAddress}</TableItem>
              <TableItem>{userStatus.isRapidWorkspaceAssessmentTrained && <CheckIcon />}</TableItem>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Section>
  );
};

const DeskSummaryLink: React.FC<{ userId: number | null; userName: string }> = ({
  userId,
  userName,
}) =>
  userId != null ? (
    <InlineLink to={getPathToUserDesksSummary(userId)}>{userName}</InlineLink>
  ) : (
    <>{userName}</>
  );

const Section = styled.section`
  margin-bottom: ${spacingS};
`;
