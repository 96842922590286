import { useCallback } from 'react';
import {
  CourseDto,
  CoursesDto,
  CoursesWithExpiredCountDto,
  CreateUpdateCourseDto,
} from '../models/course';
import { isFetchJsonError } from '../api';
import { useAuthorizedFetchJson } from './useAuthorizedFetch';

export const useCourseApi = () => {
  const getCourseAuthorizedFetch = useAuthorizedFetchJson<CourseDto>('GET');
  const getCourse = useCallback(
    (courseId: string) => getCourseAuthorizedFetch(`course/${courseId}`),
    [getCourseAuthorizedFetch],
  );

  const getAllCoursesAuthorizedFetch = useAuthorizedFetchJson<CoursesDto>('GET');
  const getAllCourses = useCallback(() => getAllCoursesAuthorizedFetch(`course`), [
    getAllCoursesAuthorizedFetch,
  ]);

  const getAllCoursesWithExpiryAuthorizedFetch = useAuthorizedFetchJson<CoursesWithExpiredCountDto>(
    'GET',
  );
  const getAllCoursesWithExpiry = useCallback(
    () => getAllCoursesWithExpiryAuthorizedFetch(`course/expiry`),
    [getAllCoursesWithExpiryAuthorizedFetch],
  );

  const createCourseAuthorizedFetch = useAuthorizedFetchJson<CourseDto, CreateUpdateCourseDto>(
    'POST',
  );
  const createCourse = useCallback(
    (createUpdateCourseDto: CreateUpdateCourseDto) =>
      createCourseAuthorizedFetch(`course/create`, createUpdateCourseDto),
    [createCourseAuthorizedFetch],
  );

  const editCourseAuthorizedFetch = useAuthorizedFetchJson<CourseDto, CreateUpdateCourseDto>('PUT');
  const editCourse = useCallback(
    (courseId: string, createUpdateCourseDto: CreateUpdateCourseDto) =>
      editCourseAuthorizedFetch(`course/${courseId}`, createUpdateCourseDto),
    [editCourseAuthorizedFetch],
  );

  const deleteCourseAuthorizedFetch = useAuthorizedFetchJson<null>('DELETE');
  const deleteCourse = useCallback(
    (courseId: string) =>
      deleteCourseAuthorizedFetch(`course/${courseId}`).then((res) => !isFetchJsonError(res)),
    [deleteCourseAuthorizedFetch],
  );

  return {
    getCourse,
    getAllCourses,
    getAllCoursesWithExpiry,
    createCourse,
    editCourse,
    deleteCourse,
  };
};
