import { UserNameDto } from './user';

export const maxNotesLength = 512;

export type WorkspaceAssessmentQuestionDto = {
  id: number;
  orderIndex: number;
  questionText: string;
  extraInfo: string | null;
};

export type WorkspaceAssessmentSectionsDtoSection = {
  id: number;
  orderIndex: number;
  name: string;
  questions: Array<WorkspaceAssessmentQuestionDto>;
};

export type WorkspaceAssessmentSectionsDto = {
  sections: Array<WorkspaceAssessmentSectionsDtoSection>;
};

export type WorkspaceAssessmentDto = {
  creator: UserNameDto;
  createdOn: string;
  approver: UserNameDto | null;
  approvedOn: string | null;
  approvalComment: string | null;
  isOnArchivedDesk?: boolean;
  sections: Array<WorkspaceAssessmentDtoSection>;
};
export type WorkspaceAssessmentDtoSection = {
  name: string;
  answers: Array<WorkspaceAssessmentDtoAnswer>;
};
export type WorkspaceAssessmentDtoAnswer = {
  question: WorkspaceAssessmentQuestionDto;
  isYes: boolean;
  notes: string | null;
  isAddressed: boolean;
  reviewComment: string | null;
};

export type CreateWorkspaceAssessmentDto = {
  answers: Array<CreateWorkspaceAssessmentDtoAnswer>;
};
export type CreateWorkspaceAssessmentDtoAnswer = {
  isYes: boolean;
  notes: string | null;
  questionId: number;
};

export type ApproveWorkspaceAssessmentDto = {
  answers: Array<ApproveWorkspaceAssessmentDtoAnswer>;
  approvalComment: string | null;
  approvedOn: string | null;
};
export type ApproveWorkspaceAssessmentDtoAnswer = {
  questionId: number;
  reviewComment: string | null;
  isAddressed: boolean;
};

export type WorkspaceAssessmentSummaryWithDesk = {
  id: number;
  deskName: string;
  createdOn: string;
  approver: UserNameDto | null;
  approvedOn: string | null;
  isOnArchivedDesk: boolean;
};

export type HotDeskAssessmentDto = AssessmentDto & {
  isRapidWorkspaceAssessment: boolean;
};

export type AssessmentDto = {
  id: number;
  createdOn: string;
  approver: UserNameDto | null;
  approvedOn: string | null;
};
