import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Alert, AlertProps } from './Alert';

export type AlertIfRequiredByLocationState = {
  alert: AlertProps | undefined;
};

export const SingleUseAlertIfRequiredByLocation = () => {
  const history = useHistory<AlertIfRequiredByLocationState | undefined>();
  const location = useLocation<AlertIfRequiredByLocationState | undefined>();
  const [alert, setAlert] = useState<AlertProps | undefined>(location.state?.alert);

  useEffect(() => {
    if (location.state?.alert) {
      setAlert(location.state?.alert);
      history.replace({ ...location, state: { ...location.state, alert: undefined } });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.alert]);

  if (alert) {
    return <Alert {...alert} />;
  }
  return null;
};
