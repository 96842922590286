import styled from 'styled-components';
import { koala, olaf, revolver } from '../styling/colours';
import { fontParagraph } from '../styling/fonts';
import { spacingS, spacingXS, spacingXXS, spacingXXXS } from '../styling/spacing';

export const Table = styled.table`
  border-collapse: separate;
  width: 100%;
  border-spacing: 0 10px;
`;

export const TableRow = styled.tr`
  background-color: ${olaf}};
`;

export const InactiveTableRow = styled(TableRow)`
  background-color: ${koala}};
`;

export const TableHeaderRow = styled.tr``;

export const TableHeaderItem = styled.th`
  text-align: left;
  padding: ${spacingXS} ${spacingS} ${spacingXXS};
  font: ${fontParagraph};
`;

export const TableHeader = styled.thead``;

export const TableItem = styled.td`
  border-style: solid none solid none;
  border-color: ${revolver};
  border-width: 1px;
  text-align: left;
  padding: ${spacingXS} ${spacingS};
  font: ${fontParagraph};
`;

export const TableBody = styled.tbody`
  & ${TableRow} {
    ${TableItem}:first-child {
      border-style: solid none solid solid;
      border-top-left-radius: ${spacingXXXS};
      border-bottom-left-radius: ${spacingXXXS};
    }
    ${TableItem}:last-child {
      border-style: solid solid solid none;
      border-top-right-radius: ${spacingXXXS};
      border-bottom-right-radius: ${spacingXXXS};
    }
  }
`;
