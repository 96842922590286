import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useDeskApi } from '../../hooks/useDeskApi';
import { DesksDtoDesk } from '../../models/desk';
import { Alert, AlertType } from '../../shared/Alert';
import { ButtonRow, SecondaryButton, SubmitButton } from '../../shared/Button';
import { Modal } from '../../shared/Modal';
import { dangerButton } from '../../styling/colours';
import { spacingS } from '../../styling/spacing';

type ArchiveDeskModalProps = {
  closeModal: () => void;
  isOpen: boolean;
  refreshDeskList: () => void;
  deskToBeArchived: DesksDtoDesk | null;
};
export const testIdArchiveDesksButton = 'archive-desk-modal';

export const ArchiveDeskModal = ({
  closeModal,
  isOpen,
  refreshDeskList,
  deskToBeArchived,
}: ArchiveDeskModalProps) => {
  const [isSaving, setIsSaving] = useState(false);

  const { archiveDesk } = useDeskApi();
  const history = useHistory();

  const archive = (isArchivingDesk: DesksDtoDesk | null) => {
    setIsSaving(true);

    if (isArchivingDesk !== null) {
      archiveDesk(isArchivingDesk.id).then((response) => {
        const successfullyArchivedDesk = response === null;
        setIsSaving(false);
        closeModal();
        refreshDeskList();
        history.replace({
          state: {
            alert: {
              title: successfullyArchivedDesk ? 'Success' : 'Error',
              children: successfullyArchivedDesk
                ? `You have successfully archived desk ${isArchivingDesk.name}`
                : response !== null
                ? response.userVisibleErrorMessage
                : `An error occurred archiving your desk: ${isArchivingDesk.name}`,
              type: successfullyArchivedDesk ? AlertType.Success : AlertType.Error,
              closeable: true,
            },
          },
        });
      });
    }
  };

  return (
    <Modal isOpen={isOpen} contentLabel="make a desk modal" onRequestClose={closeModal}>
      {deskToBeArchived !== null && (
        <AlertContainer>
          <Alert title={'Warning'} type={AlertType.Error}>
            <p>
              You are about to archive this desk:
              <b> {deskToBeArchived.name}</b>
            </p>
            <p>
              This change is not reversible. Are you sure you want to archive
              <b> {deskToBeArchived.name}</b>?
            </p>
            <ButtonRow>
              <SecondaryButton onClick={closeModal} disabled={isSaving}>
                Cancel
              </SecondaryButton>
              <DangerSubmitButton
                autoFocus
                isSubmitting={isSaving}
                disabled={isSaving}
                onClick={() => archive(deskToBeArchived)}
              >
                Archive
              </DangerSubmitButton>
            </ButtonRow>
          </Alert>
        </AlertContainer>
      )}
    </Modal>
  );
};

const AlertContainer = styled.div`
  margin: ${spacingS} ${spacingS} 0 ${spacingS};
`;

const DangerSubmitButton = styled(SubmitButton)`
  background-color: ${dangerButton};
`;
