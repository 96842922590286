import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { FetchJsonError, isFetchJsonError } from '../../api';
import { useUserApi } from '../../hooks/useUserApi';
import { HotDeskUserStatusDto } from '../../models/hot-desk';
import { EmployeeTreeDto, UserDto } from '../../models/user';
import { Alert, AlertType } from '../../shared/Alert';
import { PrimaryButton } from '../../shared/Button';
import { ErrorAlert } from '../../shared/error/ErrorAlert';
import { Header1 } from '../../shared/Headers';
import { LargeLoadingIndicator } from '../../shared/LargeLoadingIndicator';
import { IfUserHasPermission } from '../../shared/permission/IfUserHasPermission';
import { SingleUseAlertIfRequiredByLocation } from '../../shared/SingleUseAlertIfRequiredByLocation';
import { Table, TableBody } from '../../shared/Table';
import { CurrentUserContext } from '../../shared/user/CurrentUserContext';
import { spacingM } from '../../styling/spacing';
import {
  ActiveEmployeeNonTreeRow,
  DisabledEmployeeRow,
  HierarchyTreeEmployeeLeaf,
  HierarchyTreeEmployeeNode,
} from './EmployeeTreeComponents';

export const EmployeeTree = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [employeeTree, setEmployeeTree] = useState<EmployeeTreeDto | null>(null);
  const [userHotDeskStatus, setUserHotDeskStatus] = useState<HotDeskUserStatusDto | null>(null);
  const [error, setError] = useState<string | undefined>(undefined);

  const { currentUser } = useContext(CurrentUserContext);
  const { fetchEmployeeTree, exportUsers, fetchUserHotDeskStatus } = useUserApi();

  const getEmployeeTree = useCallback(() => {
    setIsLoading(true);
    fetchEmployeeTree()
      .then((result: EmployeeTreeDto | FetchJsonError) => {
        if (!isFetchJsonError(result)) {
          setEmployeeTree(result);
        } else {
          setError(result.userVisibleErrorMessage);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [fetchEmployeeTree]);

  const getCurrentUserHotDeskStatus = useCallback(() => {
    setIsLoading(true);
    fetchUserHotDeskStatus()
      .then((result: HotDeskUserStatusDto | FetchJsonError) => {
        if (!isFetchJsonError(result)) {
          setUserHotDeskStatus(result);
        } else {
          setError(result.userVisibleErrorMessage);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [fetchUserHotDeskStatus]);

  useEffect(() => {
    setIsLoading(true);
    getEmployeeTree();
    getCurrentUserHotDeskStatus();
    setIsLoading(false);
  }, [getCurrentUserHotDeskStatus, getEmployeeTree]);

  if (error) {
    return <ErrorAlert userVisibleError={error || 'Could not load employee list'} />;
  }

  if (isLoading || employeeTree == null || userHotDeskStatus == null) {
    return <LargeLoadingIndicator />;
  }

  return (
    <div>
      <SingleUseAlertIfRequiredByLocation />
      <HotDeskAssessmentWarning userHotDeskStatus={userHotDeskStatus} />
      <HeaderContainer>
        <Header1>Directory</Header1>
        <IfUserHasPermission permission={'ExportUserDetails'}>
          <PrimaryButton onClick={exportUsers}>Export users</PrimaryButton>
        </IfUserHasPermission>
      </HeaderContainer>
      <LmHierarchyTree employeeTree={employeeTree} currentUser={currentUser} />
    </div>
  );
};

const HotDeskAssessmentWarning: React.FC<{
  userHotDeskStatus: HotDeskUserStatusDto;
}> = ({ userHotDeskStatus }) => {
  if (!userHotDeskStatus.isHotDeskUser || userHotDeskStatus.isMostRecentHotDeskAssessmentToday) {
    return null;
  }

  return (
    <Alert type={AlertType.Warning}>
      Hi {userHotDeskStatus.user.name}, you need to fill out a daily{' '}
      <Link to={`/user/${userHotDeskStatus.user.id}/assessments`}>hot desk assessment</Link> if you
      are using a hot desk in the office.
    </Alert>
  );
};

const LmHierarchyTree = (props: { employeeTree: EmployeeTreeDto; currentUser: UserDto }) => {
  const hasDeskCountPermission =
    props.currentUser.userPermissions != null &&
    props.currentUser.userPermissions.includes('ReadDeskCount');
  return (
    <TableContainer>
      {props.employeeTree.activeEmployeeTrees.map((employeeTreeNode, index) => (
        <div key={index}>
          <HierarchyTreeEmployeeLeaf employee={employeeTreeNode.employee} />
          {employeeTreeNode.lineManaging.map((nextEmployee, index) => (
            <HierarchyTreeEmployeeNode employee={nextEmployee} key={index} />
          ))}
        </div>
      ))}
      {props.employeeTree.activeEmployeesOutsideTrees != null && (
        <Table>
          <TableBody>
            {props.employeeTree.activeEmployeesOutsideTrees.map((employee, index) => (
              <ActiveEmployeeNonTreeRow employee={employee} key={index} />
            ))}
          </TableBody>
        </Table>
      )}
      {props.employeeTree.deactivatedEmployees != null && (
        <Table>
          <TableBody>
            {props.employeeTree.deactivatedEmployees?.map((employee, index) => (
              <DisabledEmployeeRow
                employee={employee}
                hasDeskCountPermission={hasDeskCountPermission}
                key={index}
              />
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

const TableContainer = styled.div`
  margin-bottom: ${spacingM};
`;

const HeaderContainer = styled.div`
  margin-bottom: ${spacingM};
  display: flex;
  justify-content: space-between;
`;
