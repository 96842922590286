import React from 'react';
import styled from 'styled-components';
import { useDeskApi } from '../../hooks/useDeskApi';
import { DeskOverviewDtoDesk } from '../../models/desk';
import { Permission } from '../../models/permission';
import { UserSummaryDto } from '../../models/user';
import { GetRequest } from '../../shared/apiHelpers/GetRequest';
import { Header2 } from '../../shared/Headers';
import { InlineLink } from '../../shared/Link';
import { AssertUserHasPermission } from '../../shared/permission/AssertUserHasPermission';
import { SingleUseAlertIfRequiredByLocation } from '../../shared/SingleUseAlertIfRequiredByLocation';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderItem,
  TableHeaderRow,
  TableItem,
  TableRow,
} from '../../shared/Table';
import { UserAvatar } from '../../shared/UserAvatar';
import { spacingS } from '../../styling/spacing';
import { formatDate, formatDateTime } from '../../utils/dates';
import { getPathToUserDesksSummary } from './UserDesksSummary';

const requiredPermission: Permission = 'ReadDeskOverview';

export const testIdDeskOverviewPage = 'desk-overview-page';
export const testIdDeskOverviewUsersWithoutDesks = 'desk-overview-page-users-without-desks';
export const testIdDeskOverviewOverDueAssessments = 'desk-overview-page-overdue-assessments';
export const testIdDeskOverviewAssessmentsAwaitingApproval =
  'desk-overview-page-assessments-awaiting-approval';
export const testIdDeskOverviewUpToDateAssessments = 'desk-overview-page-up-to-date-assessments';

export const DesksOverview = () => {
  const { fetchOverview } = useDeskApi();

  return (
    <GetRequest request={fetchOverview}>
      {(overview) => (
        <AssertUserHasPermission permission={requiredPermission}>
          <div data-testid={testIdDeskOverviewPage}>
            <SingleUseAlertIfRequiredByLocation />
            {overview.usersWithoutDesks.length > 0 && (
              <Section data-testid={testIdDeskOverviewUsersWithoutDesks}>
                <Header2>Users Without Desks</Header2>
                <Table>
                  <TableHeader>
                    <TableHeaderRow>
                      <TableHeaderItem colSpan={2}>Employee name</TableHeaderItem>
                      <TableHeaderItem>Email address</TableHeaderItem>
                    </TableHeaderRow>
                  </TableHeader>
                  <TableBody>
                    {overview.usersWithoutDesks.map((employee, index) => (
                      <TableRow key={index}>
                        <TableItem>
                          <UserAvatar user={employee} />
                        </TableItem>
                        <TableItem>
                          <DeskSummaryLink user={employee} text={employee.name} />
                        </TableItem>
                        <TableItem>{employee.emailAddress}</TableItem>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Section>
            )}
            {overview.overdueAssessments.length > 0 && (
              <Section data-testid={testIdDeskOverviewOverDueAssessments}>
                <Header2>Overdue Assessments</Header2>
                <DeskList desks={overview.overdueAssessments} />
              </Section>
            )}
            {overview.assessmentsAwaitingApproval.length > 0 && (
              <Section data-testid={testIdDeskOverviewAssessmentsAwaitingApproval}>
                <Header2>Assessments Awaiting Approval</Header2>
                <Table>
                  <TableHeader>
                    <TableHeaderRow>
                      <TableHeaderItem colSpan={2}>Employee Name</TableHeaderItem>
                      <TableHeaderItem>Desk Name</TableHeaderItem>
                      <TableHeaderItem>Completed Date</TableHeaderItem>
                    </TableHeaderRow>
                  </TableHeader>
                  <TableBody>
                    {overview.assessmentsAwaitingApproval.map((assessment, index) => (
                      <TableRow key={index}>
                        <TableItem>
                          <UserAvatar user={assessment.creator} />
                        </TableItem>
                        <TableItem>{assessment.creator.name}</TableItem>
                        <TableItem>
                          <DeskSummaryLink user={assessment.creator} text={assessment.deskName} />
                        </TableItem>
                        <TableItem>
                          <InlineLink to={`/assessments/${assessment.id}`}>
                            {formatDateTime(assessment.completedOn)}
                          </InlineLink>
                        </TableItem>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Section>
            )}
            {overview.upToDateAssessments.length > 0 && (
              <Section data-testid={testIdDeskOverviewUpToDateAssessments}>
                <Header2>Up-To-Date Assessments</Header2>
                <DeskList desks={overview.upToDateAssessments} />
              </Section>
            )}
          </div>
        </AssertUserHasPermission>
      )}
    </GetRequest>
  );
};

const DeskList = (props: { desks: Array<DeskOverviewDtoDesk> }) => (
  <Table>
    <TableHeader>
      <TableHeaderRow>
        <TableHeaderItem colSpan={2}>Employee name</TableHeaderItem>
        <TableHeaderItem>Desk name</TableHeaderItem>
        <TableHeaderItem>Due date</TableHeaderItem>
      </TableHeaderRow>
    </TableHeader>
    <TableBody>
      {props.desks.map((desk, index) => (
        <TableRow key={index}>
          <TableItem>
            <UserAvatar user={desk.owner} />
          </TableItem>
          <TableItem>{desk.owner.name}</TableItem>
          <TableItem>
            <DeskSummaryLink user={desk.owner} text={desk.deskName} />
          </TableItem>
          <TableItem>{formatDate(desk.nextAssessmentDue)}</TableItem>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const DeskSummaryLink = (props: { user: UserSummaryDto; text: string }) =>
  props.user.id != null ? (
    <InlineLink to={getPathToUserDesksSummary(props.user.id)}>{props.text}</InlineLink>
  ) : (
    <>{props.text}</>
  );

const Section = styled.div`
  margin-bottom: ${spacingS};
`;
