import { includes } from 'lodash';

export const from = (path: string) => `api/${path}`;

export type HttpMethod = 'DELETE' | 'GET' | 'POST' | 'PUT';
export const createRequestInit = (httpMethod: HttpMethod, jwtAccessToken?: string): RequestInit =>
  ({
    headers: {
      Accept: 'application/json',
      Authorization: jwtAccessToken ? 'Bearer ' + jwtAccessToken : undefined,
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      Pragma: 'no-cache',
    },
    method: httpMethod,
  } as RequestInit);

export const withBody = <TBody>(request: RequestInit, body: TBody): RequestInit => ({
  ...request,
  body: JSON.stringify(body),
});

export const parseJson = <T>(response: Response): Promise<T> => {
  return includes(response.headers.get('content-type') as string, 'application/json')
    ? response.json()
    : response.text();
};

type ErrorResponseBody = {
  message: string;
  userVisibleMessage?: string;
};

export type FetchJsonError = {
  statusCode: number;
  errorMessage: string;
  userVisibleErrorMessage?: string;
};

export const isFetchJsonError = <T>(x: T | FetchJsonError): x is FetchJsonError =>
  (x as FetchJsonError).statusCode !== undefined;

export const fetchJson = <T = unknown>(
  requestInfo: RequestInfo,
  requestInit: RequestInit = createRequestInit('GET'),
): Promise<T | FetchJsonError> =>
  fetch(requestInfo, requestInit).then(async (res) => {
    if (!res.ok) {
      const errorResponseBody = (await res.json()) as ErrorResponseBody;
      return {
        statusCode: res.status,
        errorMessage: errorResponseBody.message,
        userVisibleErrorMessage: errorResponseBody.userVisibleMessage,
      } as FetchJsonError;
    }
    return await parseJson<T>(res);
  });

export const fetchJObjectURL = (
  requestInfo: RequestInfo,
  requestInit: RequestInit = createRequestInit('GET'),
): Promise<string | FetchJsonError> =>
  fetch(requestInfo, requestInit)
    .then(async (res) => {
      if (isFetchJsonError(res)) {
        return {
          statusCode: res.status,
          errorMessage: ((await res.json()) as ErrorResponseBody).message,
        } as FetchJsonError;
      }
      return await res.blob();
    })
    .then((blob) => {
      return window.URL.createObjectURL(blob as Blob);
    });
