import React, { Context, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { FetchJsonError, isFetchJsonError } from '../../api';
import { useUserApi } from '../../hooks/useUserApi';
import { CreateUpdateUserDto, UserDto, UserNotFoundErrorCode } from '../../models/user';
import { spacingXXL } from '../../styling/spacing';
import { logOut } from '../../utils/auth';
import { Alert, AlertType } from '../Alert';
import { AlertIfRequiredByLocationState } from '../SingleUseAlertIfRequiredByLocation';
import { AuthContext } from '../auth/AuthContext';
import { NoUserLayout } from '../Layout';
import { LargeLoadingIndicator } from '../LargeLoadingIndicator';
import { InlineVirtualLink } from '../Link';
import { UserForm } from './UserForm';

export type CurrentUserContextValue = {
  currentUser: UserDto;
  refreshUserData: () => void;
};

export const CurrentUserContext: Context<CurrentUserContextValue> = createContext<
  CurrentUserContextValue
>({
  currentUser: {} as UserDto,
  refreshUserData: () => {
    // do nothing
  },
});

export const CurrentUserContextProvider: React.FC = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNewUser, setIsNewUser] = useState<boolean>(false);
  const [user, setUser] = useState<UserDto>({} as UserDto);
  const [error, setError] = useState<string | undefined>(undefined);

  const history = useHistory<AlertIfRequiredByLocationState>();

  const { authProvider, acquireAccessToken } = useContext(AuthContext);
  const { createUser, fetchCurrentUser } = useUserApi();

  const refreshUserData = useCallback(() => {
    setError(undefined);
    setIsLoading(true);
    fetchCurrentUser().then((result: UserDto | FetchJsonError) => {
      if (isFetchJsonError(result)) {
        if (result.statusCode === UserNotFoundErrorCode) {
          setIsNewUser(true);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setError(result.userVisibleErrorMessage);
        }
      } else {
        setUser(result);
        setIsLoading(false);
      }
    });
  }, [fetchCurrentUser]);

  useEffect(() => {
    refreshUserData();
  }, [refreshUserData]);

  const onFormSubmit = (formUser: CreateUpdateUserDto) => {
    setIsNewUser(false);
    setIsLoading(true);
    createUser(formUser).then((result) => {
      if (isFetchJsonError(result)) {
        setError(result.userVisibleErrorMessage);
      } else {
        setUser(result);
        history.push({
          pathname: `/user/${result.id}/view`,
          state: {
            alert: {
              title: 'Success',
              children: 'Your profile was created successfully',
              type: AlertType.Success,
              closeable: true,
            },
          },
        });
      }
      setIsLoading(false);
    });
  };

  if (error) {
    return (
      <NoUserLayout>
        <Alert type={AlertType.Error} title={error || 'Error'}>
          <p>
            There was an error loading the site, please{' '}
            <InlineVirtualLink onClick={refreshUserData}>try again</InlineVirtualLink> or{' '}
            <InlineVirtualLink onClick={() => logOut(authProvider, acquireAccessToken)}>
              log out
            </InlineVirtualLink>
            .
          </p>
        </Alert>
      </NoUserLayout>
    );
  }

  if (isNewUser) {
    return (
      <NoUserLayout>
        <FormWrapper>
          <UserForm onSubmit={onFormSubmit} />
        </FormWrapper>
      </NoUserLayout>
    );
  }

  if (isLoading || user == null) {
    return <LargeLoadingIndicator />;
  }

  return (
    <CurrentUserContext.Provider value={{ currentUser: user, refreshUserData }}>
      {props.children}
    </CurrentUserContext.Provider>
  );
};

const FormWrapper = styled.div`
  margin: 0 ${spacingXXL};
`;
