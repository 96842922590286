import React, { useCallback, useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { isFetchJsonError } from '../../api';
import { useDeskApi } from '../../hooks/useDeskApi';
import { useWorkspaceAssessmentApi } from '../../hooks/useWorkspaceAssessmentApi';
import { DesksDto, DeskAssessmentDto, DesksDtoDesk } from '../../models/desk';
import { HotDeskAssessmentsSummaryDto, isHotDeskAssessment } from '../../models/hot-desk';
import { AssessmentDto, HotDeskAssessmentDto } from '../../models/workspace-assessment';
import { Alert, AlertType } from '../../shared/Alert';
import { ButtonRow, IconLinkButton, LinkButton, PrimaryButton } from '../../shared/Button';
import { ErrorAlert } from '../../shared/error/ErrorAlert';
import { ExpandableComponent } from '../../shared/ExpandableComponent';
import { Header2, Header6 } from '../../shared/Headers';
import { LargeLoadingIndicator } from '../../shared/LargeLoadingIndicator';
import { InlineLink } from '../../shared/Link';
import { AssertUserHasPermission } from '../../shared/permission/AssertUserHasPermission';
import { IfUserHasPermission } from '../../shared/permission/IfUserHasPermission';
import { SingleUseAlertIfRequiredByLocation } from '../../shared/SingleUseAlertIfRequiredByLocation';
import { CurrentUserContext } from '../../shared/user/CurrentUserContext';
import { alertText, noticeText, peacock } from '../../styling/colours';
import { fontHeading6 } from '../../styling/fonts';
import { spacingS, spacingXS, spacingXXS } from '../../styling/spacing';
import { formatDate } from '../../utils/dates';
import { ArchiveDeskModal } from './ArchiveDeskModal';
import { CreateHotDeskAssessmentModal } from './CreateHotDeskAssessmentModal';
import { CreateOrMoveDeskModal } from './CreateOrMoveDeskModal';
import { DeskRefreshModal } from './DeskRefreshModal';
import { ReactComponent as BellIcon } from '../../shared/icons/bell.svg';
import { ReactComponent as PlusIcon } from '../../shared/icons/plus-icon.svg';
import { ReactComponent as MoveIcon } from '../../shared/icons/move-icon.svg';
import { ReactComponent as ArchiveIcon } from '../../shared/icons/archive-icon.svg';

//sets a different element for testing because the test suite won't run when its looking for 'root'
if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

export const userDesksSummaryTestId = 'user-desks-summary';

export type UserDesksSummaryParams = {
  id: string;
};

enum DeskAssessmentState {
  NoAssessments,
  AssessmentOverdue,
  AssessmentPendingApproval,
  AssessmentApproved,
  Archived,
}

const maxNoOfUnarchivedDesks = 5;
export const getPathToUserDesksSummary = (id: number) => `/user/${id}/assessments`;

const useQuery = () => new URLSearchParams(useLocation().search);

export const hotDeskAssessmentsTitle = 'Hot Desks';

export const UserDesksSummary = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [createOrMoveDeskModalIsOpen, setCreateOrMoveDeskModalIsOpen] = useState<boolean>(false);
  const [deskRefreshModalIsOpen, setDeskRefreshModalIsOpen] = useState<boolean>(false);
  const [archiveDeskModalIsOpen, setArchiveDeskModalIsOpen] = useState<boolean>(false);
  const [createHotDeskAssessmentModalIsOpen, setCreateHotDeskAssessmentModalIsOpen] = useState<
    boolean
  >(false);
  const [refreshingDeskId, setRefreshingDeskId] = useState<number | null>(null);
  const [movingFromDeskId, setMovingFromDeskId] = useState<number | null>(null);
  const [desks, setDesks] = useState<DesksDto>();
  const [hotDeskAssessmentsSummary, setHotDeskAssessmentsSummary] = useState<
    HotDeskAssessmentsSummaryDto
  >();
  const [archivingDesk, setArchivingDesk] = useState<DesksDtoDesk | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const { currentUser } = useContext(CurrentUserContext);
  const { fetchDesksAndUser } = useDeskApi();
  const { fetchHotDeskWorkspaceAssessmentsSummary } = useWorkspaceAssessmentApi();
  const { id } = useParams<UserDesksSummaryParams>();
  const moveDeskId = useQuery().get('move');
  const archiveDeskId = useQuery().get('archive');
  const history = useHistory();

  const isViewingOwnDesks = currentUser.id === parseInt(id);

  const refreshHotDeskAssessmentsSummary = useCallback(() => {
    setIsLoading(true);
    fetchHotDeskWorkspaceAssessmentsSummary(id)
      .then((res) => {
        if (!isFetchJsonError(res)) {
          setHotDeskAssessmentsSummary(res);
        } else {
          setError(res.userVisibleErrorMessage);
        }
      })
      .finally(() => setIsLoading(false));
  }, [fetchHotDeskWorkspaceAssessmentsSummary, id]);

  const refreshDeskList = useCallback(() => {
    setIsLoading(true);
    fetchDesksAndUser(id)
      .then((res) => {
        if (!isFetchJsonError(res)) {
          setDesks(res);
        } else {
          setError(res.userVisibleErrorMessage);
        }
      })
      .finally(() => setIsLoading(false));
  }, [fetchDesksAndUser, id]);

  const handleArchiveButtonClick = (desk: DesksDtoDesk) => {
    setArchivingDesk(desk);
    setArchiveDeskModalIsOpen(true);
  };
  const handleMoveButtonClick = (deskId: number) => {
    setMovingFromDeskId(deskId);
    setCreateOrMoveDeskModalIsOpen(true);
  };
  const handleCloseDeskMoveModal = () => {
    setCreateOrMoveDeskModalIsOpen(false);
    setMovingFromDeskId(null);
  };
  const handleCloseDeskRefreshModal = () => {
    setDeskRefreshModalIsOpen(false);
    setRefreshingDeskId(null);
    setIsSaving(false);
  };

  const handleCloseArchiveDeskModal = () => {
    setArchiveDeskModalIsOpen(false);
    setArchivingDesk(null);
  };

  const handleRefreshClick = (deskId: number) => {
    setRefreshingDeskId(deskId);
    setIsSaving(true);
    setDeskRefreshModalIsOpen(true);
  };

  const handleCreateHotDeskAssessmentClick = () => {
    if (hotDeskAssessmentsSummary?.isRapidWorkspaceAssessmentTrained) {
      setCreateHotDeskAssessmentModalIsOpen(true);
    } else {
      history.push(`/assessments/user/${currentUser.id}/hot-desk/add-assessment`);
    }
  };

  const getDeskState = (desk: DesksDtoDesk) => {
    const { assessments } = desk;
    const activeAssessment = assessments.find((assessment) => assessment.isActive);
    if (desk.isArchived) {
      return DeskAssessmentState.Archived;
    } else if (assessments.length === 0) {
      return DeskAssessmentState.NoAssessments;
    } else if (
      Date.now() > Date.parse(desk.nextAssessmentDue) &&
      assessments.every((assessment) => assessment.createdOn < desk.nextAssessmentDue)
    ) {
      return DeskAssessmentState.AssessmentOverdue;
    } else if (activeAssessment && !activeAssessment.approvedOn) {
      return DeskAssessmentState.AssessmentPendingApproval;
    }

    return DeskAssessmentState.AssessmentApproved;
  };

  useEffect(() => {
    const isValidDeskId = (deskId: string | null): boolean =>
      deskId != null &&
      !isNaN(parseInt(deskId as string)) &&
      desks !== undefined &&
      desks.desks.some(
        (desk) =>
          desk.id === parseInt(deskId as string) &&
          desks.desks.find((d) => d.id === parseInt(deskId as string)) !== undefined,
      );

    if (!desks) {
      return;
    }

    if (isValidDeskId(moveDeskId)) {
      handleMoveButtonClick(parseInt(moveDeskId as string));
    } else if (isValidDeskId(archiveDeskId)) {
      handleArchiveButtonClick(
        desks.desks.find((d) => d.id === parseInt(archiveDeskId ?? '')) as DesksDtoDesk,
      );
    }
  }, [moveDeskId, archiveDeskId, desks]);

  useEffect(() => {
    setIsLoading(true);
    refreshHotDeskAssessmentsSummary();
    refreshDeskList();
    setIsLoading(false);
  }, [refreshDeskList, refreshHotDeskAssessmentsSummary]);

  const numberOfDesks = desks?.desks.length;
  const numberOfUnarchivedDesks = desks?.desks.filter((desk) => !desk.isArchived).length;
  const canAddDesk =
    numberOfUnarchivedDesks !== undefined && numberOfUnarchivedDesks < maxNoOfUnarchivedDesks;

  const getDeskSummary = (desk: DesksDtoDesk, state: DeskAssessmentState) => {
    switch (state) {
      case DeskAssessmentState.Archived:
        return null;
      case DeskAssessmentState.NoAssessments:
        return isViewingOwnDesks ? (
          <DeskAlertInfo>No current assessments - please create a new assessment</DeskAlertInfo>
        ) : (
          <DeskAlertInfo>No current assessments</DeskAlertInfo>
        );
      case DeskAssessmentState.AssessmentOverdue:
        return isViewingOwnDesks ? (
          <DeskAlertInfo>Assessment overdue - please create a new assessment</DeskAlertInfo>
        ) : (
          <DeskAlertInfo>Assessment overdue</DeskAlertInfo>
        );
      case DeskAssessmentState.AssessmentPendingApproval:
        return <DeskSummaryInfo>Assessment pending approval</DeskSummaryInfo>;
      case DeskAssessmentState.AssessmentApproved:
        return (
          <DeskSummaryInfo>
            Assessment next due {new Date(desk.nextAssessmentDue).toLocaleDateString()}
          </DeskSummaryInfo>
        );
    }
  };

  const getApprovalMessage = (assessment: AssessmentDto): string => {
    if (assessment.approvedOn == null) {
      return 'Pending approval';
    }

    if (assessment.approver == null) {
      return `Approved on ${formatDate(assessment.approvedOn)}`;
    }

    if (assessment.approver.name === 'Automatic') {
      return `Automatically approved on ${formatDate(assessment.approvedOn)}`;
    }

    return `Approved on ${formatDate(assessment.approvedOn)} by ${assessment.approver?.name}`;
  };

  const AssessmentsInfo: React.FC<{
    assessments: Array<HotDeskAssessmentDto | DeskAssessmentDto>;
  }> = ({ assessments }) => {
    return (
      <>
        {assessments.length === 0 ? (
          <div>
            <NoAssessmentsText>No assessments</NoAssessmentsText>
          </div>
        ) : (
          <>
            <AssessmentsHeader>All assessments</AssessmentsHeader>
            <table>
              <tbody>
                {assessments.map((assessment) => (
                  <AssessmentInfo assessment={assessment} key={assessment.id} />
                ))}
              </tbody>
            </table>
          </>
        )}
      </>
    );
  };

  const AssessmentInfo: React.FC<{ assessment: HotDeskAssessmentDto | DeskAssessmentDto }> = ({
    assessment,
  }) => {
    const isHotDeskWorkspaceAssessment = isHotDeskAssessment(assessment);

    return (
      <tr key={assessment.id}>
        <td>
          {isHotDeskWorkspaceAssessment && assessment.isRapidWorkspaceAssessment ? (
            formatDate(assessment.createdOn)
          ) : (
            <InlineLink
              to={
                isHotDeskWorkspaceAssessment
                  ? `/assessments/hot-desk/${assessment.id}`
                  : `/assessments/${assessment.id}`
              }
            >
              {formatDate(assessment.createdOn)}
            </InlineLink>
          )}
        </td>
        <td>
          {isHotDeskWorkspaceAssessment && (
            <AssessmentTypeText>
              {assessment.isRapidWorkspaceAssessment ? 'Rapid' : 'Full'}
            </AssessmentTypeText>
          )}
        </td>
        <td>
          <ApprovalMessage>{getApprovalMessage(assessment)}</ApprovalMessage>
        </td>
      </tr>
    );
  };

  const ExpandableDeskInfo: React.FC<{ desk: DesksDtoDesk }> = ({ desk }) => {
    const deskState = getDeskState(desk);
    const shouldShowNotification = [
      DeskAssessmentState.NoAssessments,
      DeskAssessmentState.AssessmentOverdue,
    ].includes(deskState);

    return (
      <ExpandableComponent
        key={desk.id}
        isDisabled={desk.isArchived}
        icon={shouldShowNotification ? <BellIcon fill={alertText} /> : null}
        header={
          <ExpandableHeader>
            <div>
              <InlineTextContainer>
                <DeskNameContainer>{desk.name}</DeskNameContainer>
                {getDeskSummary(desk, deskState)}
              </InlineTextContainer>
            </div>
            {desk.isArchived ? (
              <ArchivedMessage>This desk has been archived</ArchivedMessage>
            ) : (
              <ActionButtons>
                {!isViewingOwnDesks && (
                  <LinkButton onClick={() => handleRefreshClick(desk.id)}>Refresh</LinkButton>
                )}
                <IconLinkButton
                  title="Create a new assessment"
                  onClick={() => {
                    history.push(`/desk/${desk.id}/add-assessment`);
                  }}
                >
                  <PlusIcon fill={peacock} />
                </IconLinkButton>
                <IconLinkButton
                  title="Move Desk"
                  isDanger={false}
                  onClick={() => handleMoveButtonClick(desk.id)}
                >
                  <MoveIcon />
                </IconLinkButton>
                <IconLinkButton
                  title="Archive Desk"
                  isDanger={true}
                  onClick={() => handleArchiveButtonClick(desk)}
                >
                  <ArchiveIcon />
                </IconLinkButton>
              </ActionButtons>
            )}
          </ExpandableHeader>
        }
      >
        <AssessmentsInfo assessments={desk.assessments} />
      </ExpandableComponent>
    );
  };

  const ExpandableHotDeskInfo = () => {
    const shouldShowNotification =
      hotDeskAssessmentsSummary?.isCurrentHotDeskUser &&
      !hotDeskAssessmentsSummary.isMostRecentAssessmentToday;

    return (
      <ExpandableComponent
        icon={shouldShowNotification ? <BellIcon fill={alertText} /> : null}
        header={
          <ExpandableHeader>
            <div>
              <InlineTextContainer>
                <DeskNameContainer>{hotDeskAssessmentsTitle}</DeskNameContainer>
                {shouldShowNotification && (
                  <DeskAlertInfo>Please fill in assessment if using a hot desk</DeskAlertInfo>
                )}
              </InlineTextContainer>
            </div>
            <ActionButtons>
              <IconLinkButton
                title="Create a new hot desk assessment"
                onClick={handleCreateHotDeskAssessmentClick}
              >
                <PlusIcon fill={peacock} />
              </IconLinkButton>
            </ActionButtons>
          </ExpandableHeader>
        }
      >
        <AssessmentsInfo assessments={hotDeskAssessmentsSummary?.assessments ?? []} />
      </ExpandableComponent>
    );
  };

  if (error) {
    return <ErrorAlert userVisibleError={error || 'Could not load desks'} />;
  }

  if (isLoading || desks == null || hotDeskAssessmentsSummary == null) {
    return <LargeLoadingIndicator />;
  }

  return (
    <div>
      <AssertUserHasPermission
        permission={
          currentUser.id === parseInt(id)
            ? 'ReadOwnWorkspaceAssessmentHistory'
            : 'ReadUserWorkspaceAssessmentHistory'
        }
      >
        <>
          {!isSaving && (
            <div data-testid={userDesksSummaryTestId}>
              <SingleUseAlertIfRequiredByLocation />
              <TitleContainer>
                {isViewingOwnDesks ? (
                  <Header2>Your desks</Header2>
                ) : (
                  <Header2>{desks.userName}'s desks</Header2>
                )}
              </TitleContainer>
              <HealthAndSafetyMessage>
                As part of the Health and Safety (Display Screen Equipment) Regulations 1992,
                Ghyston is required to carry out workstation assessments for all employees who
                regularly use DSE as a significant part of their normal work. You should fill one in
                when you join, whenever you move desks or get a new chair, or if you feel your work
                environment's changed enough to warrant one, for whatever reason.
              </HealthAndSafetyMessage>
              {numberOfDesks !== undefined && numberOfDesks === 0 && (
                <>
                  {isViewingOwnDesks ? (
                    <NoDesksText>You currently have no permanent desks</NoDesksText>
                  ) : (
                    <NoDesksText>{desks.userName} currently has no permanent desks</NoDesksText>
                  )}
                </>
              )}
              <>
                {desks.desks
                  .filter((desk) => !desk.isArchived)
                  .map((desk) => (
                    <ExpandableDeskInfo desk={desk} key={desk.id} />
                  ))}
                <ExpandableHotDeskInfo />
                {desks.desks
                  .filter((desk) => desk.isArchived)
                  .map((desk) => (
                    <ExpandableDeskInfo desk={desk} key={desk.id} />
                  ))}
              </>
              <IfUserHasPermission
                permission={
                  currentUser.id === parseInt(id) ? 'CreateOwnDesk' : 'CreateDesksForOtherUsers'
                }
              >
                {numberOfDesks !== undefined && canAddDesk && (
                  <PrimaryButton onClick={() => setCreateOrMoveDeskModalIsOpen(true)}>
                    Add a desk
                  </PrimaryButton>
                )}
              </IfUserHasPermission>
            </div>
          )}
        </>
        <CreateOrMoveDeskModal
          isOpen={createOrMoveDeskModalIsOpen}
          closeModal={() => handleCloseDeskMoveModal()}
          refreshDeskList={refreshDeskList}
          isMovingFromDeskId={movingFromDeskId}
        />

        <DeskRefreshModal
          isOpen={deskRefreshModalIsOpen}
          closeModal={() => handleCloseDeskRefreshModal()}
          deskId={refreshingDeskId as number}
        />

        <ArchiveDeskModal
          closeModal={() => handleCloseArchiveDeskModal()}
          isOpen={archiveDeskModalIsOpen}
          refreshDeskList={refreshDeskList}
          deskToBeArchived={archivingDesk}
        />

        <CreateHotDeskAssessmentModal
          userId={Number(id)}
          closeModal={() => setCreateHotDeskAssessmentModalIsOpen(false)}
          isOpen={createHotDeskAssessmentModalIsOpen}
          refreshHotDeskAssessmentList={refreshHotDeskAssessmentsSummary}
          showWarning={hotDeskAssessmentsSummary.assessments.length < 1}
        />

        {isSaving && <Alert title={'Saving...'} type={AlertType.Notice} />}
      </AssertUserHasPermission>
    </div>
  );
};

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacingS};
`;

const HealthAndSafetyMessage = styled.p`
  margin: ${spacingS} 0;
  max-width: 800px;
  color: ${noticeText};
`;

const NoDesksText = styled(Header6)`
  margin-bottom: ${spacingS};
`;

const ExpandableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font: ${fontHeading6};
`;

const ActionButtons = styled(ButtonRow)`
  & > * {
    &:not(:last-of-type) {
      margin-right: ${spacingS};
    }
  }
`;

const NoAssessmentsText = styled(Header6)``;

const AssessmentsHeader = styled(Header6)`
  margin-bottom: ${spacingXXS};
`;

const ApprovalMessage = styled.div`
  margin-left: ${spacingXS};
`;

const AssessmentTypeText = styled.div`
  margin-left: ${spacingXS};
`;

const InlineTextContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const DeskNameContainer = styled.p`
  margin-right: 30px;
  font-weight: normal;
`;

const DeskSummaryInfo = styled.div`
  font-weight: normal;
`;

const DeskAlertInfo = styled.div`
  color: ${alertText};
`;

const ArchivedMessage = styled.div`
  font-weight: normal;
  color: ${noticeText};
`;
