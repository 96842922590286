import { orderBy } from 'lodash';
import React from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { CourseDto } from '../../models/course';
import { GetLatestTrainingRecordsByCourseIdDto } from '../../models/training-record';
import { ButtonRowSpaceBetween, DangerButton, PrimaryButton } from '../../shared/Button';
import { Header2, Header3 } from '../../shared/Headers';
import { InlineLink } from '../../shared/Link';
import { IfUserHasPermission } from '../../shared/permission/IfUserHasPermission';
import { spacingM, spacingS } from '../../styling/spacing';
import { maxDate } from '../../utils/dates';
import {
  BulkAddTrainingRecordsForm,
  EmployeesToBeAddedFormValues,
} from './BulkAddTrainingRecordsForm';
import { CourseOverviewTableForm, CourseOverviewTableFormValues } from './CourseOverviewTableForm';

type CourseOverviewFormProps = {
  onApproveSubmit: (values: CourseOverviewTableFormValues) => void;
  onBulkAddEmployeesSubmit: (values: EmployeesToBeAddedFormValues) => void;
  onResetChanges: () => void;
  onAddEmployeesButtonClicked: () => void;
  onCancelAddEmployeesButtonClicked: () => void;
  onDeleteCourseButtonClicked: () => void;
  latestTrainingRecordDataDtos: GetLatestTrainingRecordsByCourseIdDto;
  isAddAttendeesSelected: boolean;
  defaultExpiryTime?: number | undefined;
  currentCourseDto: CourseDto;
};

export const CourseOverviewBody = ({
  onApproveSubmit,
  onBulkAddEmployeesSubmit,
  onResetChanges,
  onAddEmployeesButtonClicked,
  onCancelAddEmployeesButtonClicked,
  onDeleteCourseButtonClicked,
  latestTrainingRecordDataDtos,
  isAddAttendeesSelected,
  defaultExpiryTime,
  currentCourseDto,
}: CourseOverviewFormProps) => {
  const history = useHistory();

  const alphabeticallySortedUserAndTrainingRecordDataDtos = orderBy(
    latestTrainingRecordDataDtos.latestTrainingRecords,
    [
      (trainingRecordDataDto) => {
        return trainingRecordDataDto.name;
      },
    ],
  );

  const trainingRecordsAwaitingApproval = alphabeticallySortedUserAndTrainingRecordDataDtos.filter(
    (trainingRecordDataDto) => {
      const trainingRecordDto = trainingRecordDataDto.trainingRecordData;
      if (trainingRecordDto == null) {
        return false;
      }
      const expiryDate = trainingRecordDto.expiryDate;
      return (
        !trainingRecordDto.isApproved && (expiryDate == null || new Date(expiryDate) >= new Date())
      );
    },
  );

  const trainingRecordsExpiredOrMissing = orderBy(
    alphabeticallySortedUserAndTrainingRecordDataDtos.filter((trainingRecordDataDto) => {
      const trainingRecordDto = trainingRecordDataDto.trainingRecordData;
      if (trainingRecordDto == null && currentCourseDto.requiredForAllEmployees) {
        return true;
      } else if (trainingRecordDto != null) {
        const expiryDate = trainingRecordDto.expiryDate;
        return expiryDate != null && new Date(expiryDate) < new Date();
      }
      return false;
    }),
    [
      (userAndTrainingRecordDataDto) => {
        if (userAndTrainingRecordDataDto.trainingRecordData != null) {
          const expiryDate = userAndTrainingRecordDataDto.trainingRecordData.expiryDate;
          return expiryDate != null ? new Date(expiryDate).getTime() : maxDate;
        }
        return maxDate;
      },
    ],
  );

  const trainingRecordsApproved = alphabeticallySortedUserAndTrainingRecordDataDtos.filter(
    (userAndTrainingRecordDataDto) => {
      const trainingRecordDto = userAndTrainingRecordDataDto.trainingRecordData;
      return (
        trainingRecordDto != null &&
        trainingRecordDto.isApproved &&
        (trainingRecordDto.expiryDate == null ||
          new Date(trainingRecordDto.expiryDate) > new Date())
      );
    },
  );

  const handleViewCourseHistoryClick = () => {
    history.push(`/course/${currentCourseDto.id}/history`);
  };

  return (
    <>
      <CourseBodyHeaderContainer>
        <Header2>Course attendees</Header2>
        <StyledLink to={window.location.pathname} onClick={onAddEmployeesButtonClicked}>
          Add new attendees
        </StyledLink>
      </CourseBodyHeaderContainer>
      {isAddAttendeesSelected ? (
        <BulkAddTrainingRecordsForm
          onSubmit={onBulkAddEmployeesSubmit}
          defaultExpiryTime={defaultExpiryTime}
          onCancelAddEmployeesButtonClicked={onCancelAddEmployeesButtonClicked}
        />
      ) : (
        <></>
      )}
      {trainingRecordsAwaitingApproval.length !== 0 && (
        <>
          <TableHeaderContainer>
            <Header3>Attendees awaiting approval</Header3>
          </TableHeaderContainer>
          <CourseOverviewTableForm
            onSubmit={onApproveSubmit}
            onResetChanges={onResetChanges}
            trainingRecordDataDtos={trainingRecordsAwaitingApproval}
            actionLinkText={'Approve'}
          />
        </>
      )}
      {trainingRecordsExpiredOrMissing.length !== 0 && (
        <>
          <TableHeaderContainer>
            <Header3>Expired and missing attendees</Header3>
          </TableHeaderContainer>
          <CourseOverviewTableForm
            onResetChanges={onResetChanges}
            trainingRecordDataDtos={trainingRecordsExpiredOrMissing}
            showMissing={true}
          />
        </>
      )}
      {trainingRecordsApproved.length !== 0 && (
        <>
          <TableHeaderContainer>
            <Header3>Attendees that have completed training</Header3>
          </TableHeaderContainer>
          <CourseOverviewTableForm
            onResetChanges={onResetChanges}
            trainingRecordDataDtos={trainingRecordsApproved}
          />
        </>
      )}
      <IfUserHasPermission permission={'EditTrainingRecord'}>
        <ButtonRowSpaceBetween>
          <PrimaryButton onClick={handleViewCourseHistoryClick}>View Course History</PrimaryButton>
          <IfUserHasPermission permission={'CreateEditCourses'}>
            <DangerButton onClick={onDeleteCourseButtonClicked}>Delete Course</DangerButton>
          </IfUserHasPermission>
        </ButtonRowSpaceBetween>
      </IfUserHasPermission>
    </>
  );
};

const CourseBodyHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacingS};
`;

const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${spacingM};
`;

const StyledLink = styled(InlineLink)`
  font-weight: bold;
`;
