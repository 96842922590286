import React, { useContext } from 'react';
import { Permission } from '../../models/permission';
import { UserHasPermission } from '../../models/user';
import { CurrentUserContext } from '../user/CurrentUserContext';

interface Props {
  children: React.ReactNode;
  permission: Permission;
}

export const noAccessErrorMessage = 'assert-user-has-permission-no-access-error-message';

const ErrorMessage = (
  <div data-testid={noAccessErrorMessage}>
    You do not have access to this page. If this is incorrect, contact the Helpdesk team.
  </div>
);

export const AssertUserHasPermission: React.FC<Props> = ({ children, permission }: Props) => {
  const { currentUser } = useContext(CurrentUserContext);

  return UserHasPermission(currentUser, permission) ? <>{children}</> : ErrorMessage;
};
