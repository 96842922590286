import { createRequestInit, fetchJson, FetchJsonError, from } from '../api';

export type Config = {
  authority: string;
  clientId: string;
  redirectUri: string;
  serverId: string;
};

export const fetchConfig = async (): Promise<Config | FetchJsonError> => {
  return fetchJson<Config>(from('config'), createRequestInit('GET'));
};
