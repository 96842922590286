import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { isFetchJsonError } from '../../api';
import { useCourseApi } from '../../hooks/useCourseApi';
import { useTrainingRecordApi } from '../../hooks/useTrainingRecordApi';
import { Permission } from '../../models/permission';
import { CreateTrainingRecordDto } from '../../models/training-record';
import { AlertType } from '../../shared/Alert';
import { GetRequest } from '../../shared/apiHelpers/GetRequest';
import { Header2 } from '../../shared/Headers';
import { AssertUserHasPermission } from '../../shared/permission/AssertUserHasPermission';
import {
  AlertIfRequiredByLocationState,
  SingleUseAlertIfRequiredByLocation,
} from '../../shared/SingleUseAlertIfRequiredByLocation';
import {
  TrainingRecordForm,
  CreateTrainingRecordForm,
} from '../../shared/training-record/TrainingRecordForm';
import { CurrentUserContext } from '../../shared/user/CurrentUserContext';

export type createTrainingRecordParams = {
  id: string;
};

export const CreateTrainingRecord = () => {
  const { id } = useParams<createTrainingRecordParams>();
  const history = useHistory<AlertIfRequiredByLocationState>();
  const { createTrainingRecord } = useTrainingRecordApi();
  const { getAllCourses } = useCourseApi();
  const { currentUser } = useContext(CurrentUserContext);

  const getEditPermission = (): Permission =>
    currentUser.id === Number(id) ? 'CreateOwnTrainingRecord' : 'EditTrainingRecord';

  const mapCreateTrainingRecordFormToCreateTrainingRecord = (
    createTrainingRecordForm: CreateTrainingRecordForm,
    id: number,
    hasDefault: boolean,
  ): CreateTrainingRecordDto => {
    return {
      courseId: createTrainingRecordForm.courseId,
      userId: id,
      completionDate: createTrainingRecordForm.completionDate,
      expiryDate:
        createTrainingRecordForm.expiryDate === '' || hasDefault
          ? null
          : createTrainingRecordForm.expiryDate,
      notes: createTrainingRecordForm.notes ?? '',
    };
  };

  const onFormSubmit = (
    trainingRecordForm: CreateTrainingRecordForm,
    userId: string,
    hasDefault: boolean,
  ) => {
    const trainingRecordDto = mapCreateTrainingRecordFormToCreateTrainingRecord(
      trainingRecordForm,
      Number(userId),
      hasDefault,
    );
    createTrainingRecord(trainingRecordDto, id.toString()).then((res) => {
      if (isFetchJsonError(res)) {
        history.push({
          pathname: `/training-record/create`,
          state: {
            alert: {
              title: 'Error',
              children: 'An error occurred whilst saving your changes',
              type: AlertType.Error,
              closeable: true,
            },
          },
        });
      } else {
        history.push({
          pathname: `/user/${currentUser.id}/courses`,
          state: {
            alert: {
              title: 'Success',
              children: 'Your changes were saved successfully',
              type: AlertType.Success,
              closeable: true,
            },
          },
        });
      }
    });
  };

  return (
    <GetRequest request={getAllCourses}>
      {(courses) => (
        <>
          <SingleUseAlertIfRequiredByLocation />
          <AssertUserHasPermission permission={getEditPermission()}>
            <Header2>Record New Training Course</Header2>
            <TrainingRecordForm onSubmit={onFormSubmit} courses={courses} disableSelect={false} />
          </AssertUserHasPermission>
        </>
      )}
    </GetRequest>
  );
};
