import { FieldArray, Formik, FormikErrors, FormikTouched, getIn } from 'formik';
import { findIndex } from 'lodash';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import * as yup from 'yup';
import { FetchJsonError } from '../../api';
import { useWorkspaceAssessmentApi } from '../../hooks/useWorkspaceAssessmentApi';
import {
  CreateWorkspaceAssessmentDto,
  WorkspaceAssessmentSectionsDtoSection,
} from '../../models/workspace-assessment';
import { Alert, AlertType } from '../../shared/Alert';
import { GetRequest } from '../../shared/apiHelpers/GetRequest';
import { FormButtonContainer } from '../../shared/form/FormButtonContainer';
import { InlineLink } from '../../shared/Link';
import { AlertIfRequiredByLocationState } from '../../shared/SingleUseAlertIfRequiredByLocation';
import { SubmitButton } from '../../shared/Button';
import { RadioButtonYesNo } from '../../shared/form/RadioButtonYesNo';
import { Header4, Header6 } from '../../shared/Headers';
import { CurrentUserContext } from '../../shared/user/CurrentUserContext';
import { spacingS, spacingXL } from '../../styling/spacing';
import { AssessmentInstructions } from './AssessmentInstructions';
import {
  AlertContainer,
  CommentContainer,
  FormStyle,
  NotesTextAreaInput,
  QuestionAndAnswer,
  QuestionContainer,
  QuestionInfo,
  QuestionRow,
  QuestionText,
  SectionHeader,
  StyledForm,
} from './styles';

interface CompleteWorkspaceAssessmentFormModel {
  answers: WorkspaceAssessmentAnswer[];
}

export interface WorkspaceAssessmentAnswer {
  isYes: string;
  notes: string;
  questionId: number;
}

export interface WorkspaceAssessmentFormValues {
  answers: WorkspaceAssessmentAnswer[];
}

const maxNotesLength = 512;
const workspaceAssessmentValidationSchema = yup.object().shape({
  answers: yup.array().of(
    yup.object().shape({
      isYes: yup.mixed().oneOf(['true', 'false']).required('Must answer every question'),
      notes: yup
        .string()
        .max(maxNotesLength, `Notes must be shorter than ${maxNotesLength} characters`),
      questionId: yup.number(),
    }),
  ),
});

const addWorkspaceAssessment = (
  formModel: CompleteWorkspaceAssessmentFormModel,
): CreateWorkspaceAssessmentDto => ({
  answers: formModel.answers.map((a) => ({
    questionId: a.questionId,
    isYes: a.isYes === 'true',
    notes: a.notes,
  })),
});

const getQuestionIndexOfFirstError = (
  errors: FormikErrors<WorkspaceAssessmentFormValues>,
): number | undefined => {
  if (Array.isArray(errors.answers)) {
    return findIndex<string | FormikErrors<WorkspaceAssessmentAnswer>>(
      errors.answers,
      (answerError) =>
        typeof answerError === 'string' || answerError?.isYes != null || answerError?.notes != null,
    );
  }
};

const scrollToQuestion = (questionIndex: number | undefined) => {
  const questionContainer = document.querySelector(`div[id="questionContainer.${questionIndex}"]`);
  if (questionContainer) {
    const top = questionContainer.getBoundingClientRect().top;
    if (top != null) {
      window.scrollTo({ top: window.scrollY + top, behavior: 'smooth' });
    }
  }
};

const questionHasError = (
  errors: FormikErrors<WorkspaceAssessmentFormValues>,
  orderIndex: number,
  touched: FormikTouched<WorkspaceAssessmentFormValues>,
): boolean => getIn(errors, `answers.${orderIndex}`) && getIn(touched, `answers.${orderIndex}`);

export const AddAssessmentForm: React.FC<{
  deskOrUserId: number;
  onSubmit: (
    itemId: number,
    assessment: CreateWorkspaceAssessmentDto,
  ) => Promise<FetchJsonError | null>;
}> = ({ deskOrUserId, onSubmit }) => {
  const [isSaving, setIsSaving] = useState(false);
  const { currentUser } = useContext(CurrentUserContext);
  const { fetchWorkspaceAssessmentSections } = useWorkspaceAssessmentApi();

  const history = useHistory<AlertIfRequiredByLocationState>();

  const submitWorkspaceAssessment = (
    addWorkspaceAssessmentForm: CompleteWorkspaceAssessmentFormModel,
  ) => {
    setIsSaving(true);

    onSubmit(deskOrUserId, addWorkspaceAssessment(addWorkspaceAssessmentForm)).then((response) => {
      const successfullySubmittedAssessment = response === null;
      setIsSaving(false);
      history.push({
        pathname: `/user/${currentUser.id}/assessments`,
        state: {
          alert: {
            title: successfullySubmittedAssessment ? 'Success' : 'Error',
            children: successfullySubmittedAssessment
              ? 'Workstation assessment successfully submitted'
              : response !== null
              ? response.userVisibleErrorMessage
              : 'An error occurred submitting your assessment',
            type: successfullySubmittedAssessment ? AlertType.Success : AlertType.Error,
            closeable: true,
          },
        },
      });
    });
  };

  const getInitialValues = (
    sections: WorkspaceAssessmentSectionsDtoSection[],
  ): WorkspaceAssessmentFormValues => {
    const answers = sections?.flatMap((section) =>
      section.questions.map((question) => ({
        isYes: '',
        notes: '',
        questionId: question.id,
      })),
    );

    return { answers };
  };

  return (
    <GetRequest request={fetchWorkspaceAssessmentSections}>
      {(sections) => (
        <AddAssessmentPage>
          <Header>Desk assessment</Header>
          <AssessmentInstructions />
          <Formik
            onSubmit={submitWorkspaceAssessment}
            initialValues={getInitialValues(sections)}
            validationSchema={workspaceAssessmentValidationSchema}
          >
            {(props) => {
              const questionIndexOfFirstError = getQuestionIndexOfFirstError(props.errors);
              return (
                <StyledForm>
                  <FieldArray name="Answers">
                    {() => (
                      <FormStyle>
                        {sections.map((workspaceAssessmentSection) => (
                          <div key={workspaceAssessmentSection.id}>
                            <SectionHeader>{workspaceAssessmentSection.name}</SectionHeader>
                            {workspaceAssessmentSection.questions.map((question) => (
                              <QuestionContainer
                                id={`questionContainer.${question.orderIndex}`}
                                key={question.id}
                              >
                                <QuestionRow>
                                  <QuestionAndAnswer>
                                    <QuestionText
                                      hasError={questionHasError(
                                        props.errors,
                                        question.orderIndex,
                                        props.touched,
                                      )}
                                    >
                                      <Header6>{question.questionText}</Header6>
                                    </QuestionText>
                                    <RadioButtonContainer>
                                      <RadioButtonYesNo
                                        name={`answers.${question.orderIndex}.isYes`}
                                        onBlur={props.handleBlur}
                                        onChange={props.handleChange}
                                        value={props.values.answers[question.orderIndex].isYes}
                                      />
                                    </RadioButtonContainer>
                                  </QuestionAndAnswer>
                                  <QuestionInfo>{question.extraInfo}</QuestionInfo>
                                </QuestionRow>
                                <CommentContainer>
                                  Notes
                                  <NotesTextAreaInput
                                    value={props.values.answers[question.orderIndex].notes}
                                    name={`answers.${question.orderIndex}.notes`}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                  />
                                </CommentContainer>
                              </QuestionContainer>
                            ))}
                          </div>
                        ))}
                      </FormStyle>
                    )}
                  </FieldArray>
                  {questionIndexOfFirstError !== undefined && props.submitCount > 0 ? (
                    <AlertContainer>
                      <Alert
                        title={'Error'}
                        type={AlertType.Error}
                        onClick={() => scrollToQuestion(questionIndexOfFirstError)}
                      >
                        <div>Click here to scroll to invalid input</div>
                      </Alert>
                    </AlertContainer>
                  ) : (
                    ''
                  )}
                  <FormButtonContainer>
                    <InlineLink to={`/user/${currentUser.id}/assessments`}>Cancel</InlineLink>
                    <SubmitButton isSubmitting={isSaving}>Submit</SubmitButton>
                  </FormButtonContainer>
                </StyledForm>
              );
            }}
          </Formik>
        </AddAssessmentPage>
      )}
    </GetRequest>
  );
};

const AddAssessmentPage = styled.div`
  position: relative;
  margin: 0 ${spacingXL};
`;

const Header = styled(Header4)`
  margin-bottom: ${spacingS};
`;

const RadioButtonContainer = styled.div`
  margin-left: ${spacingS};
`;
