import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import * as yup from 'yup';
import { EmployeeDropDown } from './EmployeeDropDown';
import { CreateUpdateCourseDto } from '../../models/course';
import { EmployeeListDtoEmployee } from '../../models/user';
import { spacingM, spacingS } from '../../styling/spacing';
import { Alert, AlertType } from '../Alert';
import { FormButtonContainer } from '../form/FormButtonContainer';
import { YesNoInput } from '../form/YesNoInput';
import { PrimaryButton } from '../Button';
import { TextInput } from '../form/TextInput';

export interface CourseFormProps {
  onSubmit: (createUpdateUCourseDto: CreateUpdateCourseDto) => void;
  initialState?: CreateUpdateCourseDto;
  activeEmployees?: EmployeeListDtoEmployee[];
}

type CreateCourseForm = {
  courseName: string;
  defaultExpiryTime?: number | null;
  reporterUserIds: number[];
  requiredForAllEmployees: string;
};

const mapCreateUpdateCourseToCreateCourseForm = (
  course?: CreateUpdateCourseDto,
): CreateCourseForm => {
  return {
    courseName: course?.courseName ?? '',
    reporterUserIds: course?.reporterUserIds ?? [],
    requiredForAllEmployees: course?.requiredForAllEmployees ?? false ? 'true' : 'false',
    defaultExpiryTime: course?.defaultExpiryTime,
  };
};

const mapCreateCourseFormToCreateCourse = (
  createCourseForm: CreateCourseForm,
): CreateUpdateCourseDto => {
  return {
    courseName: createCourseForm.courseName,
    reporterUserIds: createCourseForm.reporterUserIds,
    requiredForAllEmployees: createCourseForm.requiredForAllEmployees === 'true',
    defaultExpiryTime: createCourseForm.defaultExpiryTime,
  };
};

const courseNameMaxLength = 400;
const expiryDateMinLength = 30;

const requiredMessage = 'Required!';
const getTooLongMessage = (propertyName: string, maxLength: number) =>
  `${propertyName} can be no longer than ${maxLength} characters`;
const getTooEarlyMessage = (propertyName: string, minLength: number) =>
  `${propertyName} has to be longer than ${minLength} days`;

const CourseFormValidation = yup.object().shape<CreateCourseForm>({
  courseName: yup
    .string()
    .max(courseNameMaxLength, getTooLongMessage('Course Name', courseNameMaxLength))
    .required(requiredMessage),
  reporterUserIds: yup
    .array<number>()
    .min(1, 'Course must have at least one reporter')
    .required(requiredMessage),
  defaultExpiryTime: yup
    .number()
    .min(expiryDateMinLength, getTooEarlyMessage('Default expiry date', expiryDateMinLength))
    .nullable(),
  requiredForAllEmployees: yup.string().required(requiredMessage),
});

export const CourseForm = ({ initialState, onSubmit, activeEmployees = [] }: CourseFormProps) => {
  const initialValues = mapCreateUpdateCourseToCreateCourseForm(initialState);
  const fishEmployees = useMemo(() => activeEmployees?.filter((e) => e.fishId), [activeEmployees]);

  const formSubmit = (createCourse: CreateCourseForm) => {
    const cast = mapCreateCourseFormToCreateCourse(createCourse);
    onSubmit(cast);
  };

  return (
    <UserFormContainer>
      <Formik
        onSubmit={formSubmit}
        initialValues={initialValues}
        validationSchema={CourseFormValidation}
      >
        {(props) => {
          return (
            <StyledForm>
              <TextInput
                label="Training Course Name"
                name="courseName"
                value={props.values.courseName}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              />
              <EmployeeDropDown
                name={'reporterUserIds'}
                label="Reporters"
                employees={fishEmployees}
                defaultEmployeeIds={props.initialValues.reporterUserIds}
              />
              <YesNoInput
                label="Required for All Employees?"
                name="requiredForAllEmployees"
                value={props.values.requiredForAllEmployees}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              />
              <TextInput
                label="Default Expiry Time for Course"
                name="defaultExpiryTime"
                value={props.values.defaultExpiryTime ?? undefined}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              />

              {!props.isValid && props.submitCount > 0 && (
                <Alert title="Error" type={AlertType.Error}>
                  <div>There are errors in the form that must be resolved before submitting</div>
                </Alert>
              )}

              <FormButtonContainer>
                <PrimaryButton type="submit">Submit</PrimaryButton>
              </FormButtonContainer>
            </StyledForm>
          );
        }}
      </Formik>
    </UserFormContainer>
  );
};

const UserFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${spacingM};
`;

const StyledForm = styled(Form)`
  > * {
    margin-bottom: ${spacingS};
  }
`;
