import { SelectOption } from '../shared/form/SelectField';
import { Permission } from './permission';

export type UserDto = {
  id: number;
  azureUserId: string;
  name: string;
  pronouns: string | null;
  ghystonEmailAddress: string | null;
  sensitiveData: UserDtoSensitiveData | null;
  discretionaryData: UserDiscretionaryData | null;
  protectedData: UserDtoProtectedData | null;
  userPermissions: Array<Permission> | null;
  lineManagerName: string | null;
  lineManagerId: number | null;
  sharePersonalDetails: boolean;
  isRapidWorkspaceAssessmentTrained: boolean;
};

export type UserDtoSensitiveData = {
  homeAddress: string;
  city: string;
  postcode: string;
};

export type UserDiscretionaryData = {
  mobilePhoneNumber: string;
  personalEmailAddress: string;
  emergencyContactName: string;
  emergencyContactRelationship: string;
  emergencyContactDaytimePhoneNumber: string;
  emergencyContactEveningPhoneNumber: string;
  emergencyContactSpecialDetails: string | null;
};

export type UserDtoProtectedData = {
  dateOfBirth: string;
  genderCode: GenderCode | null;
  otherGender: string | null;
  ethnicityCode: EthnicityCode | null;
  otherEthnicity: string | null;
  nationalityCode: NationalityCode | null;
  otherNationality: string | null;
};

export type UserNameDto = {
  id: number | null;
  name: string;
};

export type UserSummaryDto = UserNameDto & {
  azureUserId: string;
  emailAddress: string | null;
};

export type CreateUpdateUserDto = {
  mobilePhoneNumber: string;
  personalEmailAddress: string;
  homeAddress: string;
  city: string;
  postcode: string;
  emergencyContactName: string;
  emergencyContactRelationship: string;
  emergencyContactDaytimePhoneNumber: string;
  emergencyContactEveningPhoneNumber: string;
  emergencyContactSpecialDetails?: string | null;
  sharePersonalDetails: boolean;
  protectedDataDto: CreateUpdateUserProtectedDataDto | null;
  isRapidWorkspaceAssessmentTrained: boolean;
};

export type CreateUpdateUserProtectedDataDto = {
  dateOfBirth: string;
  genderCode: GenderCode;
  otherGender?: string | null;
  ethnicityCode: EthnicityCode;
  otherEthnicity?: string | null;
  nationalityCode: NationalityCode;
  otherNationality?: string | null;
};

export type EmployeeListDto = {
  activeEmployees: Array<EmployeeListDtoEmployee>;
  deactivatedEmployees: Array<EmployeeListDtoEmployee> | null;
};
export type EmployeeListDtoEmployee = {
  fishId: number;
  azureId: string;
  name: string;
  emailAddress: string;
  lineManagerName: string | null;
  lineManagerId: number | null;
  deskCount: number;
  deactivatedOn: string | null;
};

export type EmployeeTreeDto = {
  activeEmployeeTrees: Array<EmployeeTreeDtoEmployeeNode>;
  activeEmployeesOutsideTrees: Array<EmployeeListDtoEmployee> | null;
  deactivatedEmployees: Array<EmployeeListDtoEmployee> | null;
};
export type EmployeeTreeDtoEmployeeNode = {
  employee: EmployeeListDtoEmployee;
  lineManaging: Array<EmployeeTreeDtoEmployeeNode>;
};

export const UserNotFoundErrorCode = 404;

export const genderCodes = ['Male', 'Female', 'Other', 'Abstain'] as const;
export type GenderCode = typeof genderCodes[number];
export const DisplayNameByGenderCode: { [key in GenderCode]: string } = {
  Male: 'Male',
  Female: 'Female',
  Other: 'Other',
  Abstain: 'Prefer not to say',
};
export const GenderOptions: SelectOption<GenderCode>[] = genderCodes.map((code) => ({
  value: code,
  label: DisplayNameByGenderCode[code],
}));

export const ethnicityCodes = ['Asian', 'Black', 'Mixed', 'White', 'Other', 'Abstain'] as const;
export type EthnicityCode = typeof ethnicityCodes[number];
export const DisplayNameByEthnicityCode: { [key in EthnicityCode]: string } = {
  Asian: 'Asian / Asian British',
  Black: 'Black / African / Caribbean / Black British',
  Mixed: 'Mixed / multiple ethnic groups',
  White: 'White',
  Other: 'Other',
  Abstain: 'Prefer not to say',
};
export const EthnicityOptions: SelectOption<EthnicityCode>[] = ethnicityCodes.map((code) => ({
  value: code,
  label: DisplayNameByEthnicityCode[code],
}));

export const nationalityCodes = ['British', 'Other', 'Abstain'] as const;
export type NationalityCode = typeof nationalityCodes[number];
export const DisplayNameByNationalityCode: { [key in NationalityCode]: string } = {
  British: 'British',
  Other: 'Other',
  Abstain: 'Prefer not to say',
};
export const NationalityOptions: SelectOption<NationalityCode>[] = nationalityCodes.map((code) => ({
  value: code,
  label: DisplayNameByNationalityCode[code],
}));

export const UserHasPermission = (user: UserDto, permission: Permission) =>
  user.userPermissions != null && user.userPermissions.includes(permission);
