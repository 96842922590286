import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { alertText, noticeText, successText, warningText } from '../styling/colours';
import { fontMedium } from '../styling/fonts';
import { spacingS, spacingXS, spacingXXS, spacingXXXS } from '../styling/spacing';
import { Header5 } from './Headers';
import { ReactComponent as CloseIcon } from './icons/close-icon.svg';

export enum AlertType {
  Notice,
  Success,
  Warning,
  Error,
}

export interface AlertProps {
  children?: React.ReactNode;
  title?: string;
  type: AlertType;
  onClick?: () => void;
  closeable?: boolean;
  onClose?: () => void;
  testId?: string;
}

export const Alert: React.FC<AlertProps> = (props) => {
  const [closed, setClosed] = useState<boolean>(false);

  const closeAlert = () => {
    setClosed(true);
    props.onClose && props.onClose();
  };

  return (
    <Container
      type={props.type}
      onClick={props.onClick}
      clickable={props.onClick != null}
      closed={closed}
      data-testid={props.testId}
    >
      {props.title != null && (
        <Header type={props.type}>
          <Header5>{props.title}</Header5>
          {props.closeable && <CloseButton onClick={closeAlert} />}
        </Header>
      )}
      {props.children && <Body type={props.type}>{props.children}</Body>}
    </Container>
  );
};

const alertColour = (type: AlertType) => {
  switch (type) {
    case AlertType.Notice:
      return noticeText;
    case AlertType.Success:
      return successText;
    case AlertType.Warning:
      return warningText;
    case AlertType.Error:
      return alertText;
  }
};

const Header = styled.div<{ type: AlertType }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${spacingXXS};
  color: ${(props) => alertColour(props.type)};
`;

const Container = styled.div<{ type: AlertType; clickable: boolean; closed: boolean }>`
  background-color: white;
  display: ${(props) => (props.closed ? 'none' : 'flex')};
  flex-direction: column;
  padding: ${spacingXS};
  border-radius: ${spacingXXXS};
  margin-bottom: ${spacingS};
  border: 1px solid;
  border-color: ${(props) => alertColour(props.type)};
  ${(props) => (props.clickable ? 'cursor: pointer;' : '')}
`;

const Body = styled.div<{ type: AlertType }>`
  display: ${(props) => (props.hidden ? 'none' : 'block')};
  font-size: ${fontMedium};
`;

const CloseButton = styled(CloseIcon)`
  width: 20px;
  height: 20px;
  margin-left: auto;
  cursor: pointer;
`;
