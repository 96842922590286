import React from 'react';
import { useParams } from 'react-router-dom';
import { useWorkspaceAssessmentApi } from '../../hooks/useWorkspaceAssessmentApi';
import { AddAssessmentForm } from './AddAssessmentForm';

export type AddAssessmentParams = {
  id: string;
};

export const AddDeskAssessment = () => {
  const { createWorkspaceAssessment } = useWorkspaceAssessmentApi();
  const { id } = useParams<AddAssessmentParams>();

  return <AddAssessmentForm deskOrUserId={Number(id)} onSubmit={createWorkspaceAssessment} />;
};
