import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { mushroom, olaf } from '../styling/colours';
import { shadowHeavy } from '../styling/shadows';
import { spacingS, spacingXS, spacingXXS } from '../styling/spacing';
import { PrimaryButton } from './Button';
import { ReactComponent as MoreIcon } from '../shared/icons/more.svg';

type Props = {
  buttonTestId?: string;
};

export const Dropdown: React.FC<Props> = ({ buttonTestId, children }) => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = () => {
      if (isOpen) {
        setOpen(false);
      }
    };

    if (isOpen) {
      window.addEventListener('click', handleClickOutside);
    }
    return () => window.removeEventListener('click', handleClickOutside);
  }, [isOpen]);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <DropdownButton onClick={() => setOpen(!isOpen)} data-testid={buttonTestId}>
        <MoreIcon fill={olaf} />
      </DropdownButton>
      {isOpen && (
        <DropdownAnchor>
          <DropdownWrapper>
            <DropdownContents>{children}</DropdownContents>
          </DropdownWrapper>
        </DropdownAnchor>
      )}
    </div>
  );
};

const DropdownButton = styled(PrimaryButton)`
  padding: ${spacingXXS} ${spacingXS};
  width: unset;
  height: unset;
  svg {
    vertical-align: middle;
  }
`;

const DropdownAnchor = styled.div`
  position: relative;
`;

const DropdownWrapper = styled.div`
  position: absolute;
  top: ${spacingXXS};
  right: 0;
`;

const DropdownContents = styled.div`
  padding: ${spacingXS} ${spacingS};
  background: ${mushroom};
  box-shadow: ${shadowHeavy};
  border-radius: 3px;
`;
