import React from 'react';
import { useField, useFormikContext } from 'formik';
import styled from 'styled-components';
import { RadioButtonYesNo } from './RadioButtonYesNo';
import { FormErrorMessage, FormLabel } from './SharedFormElements';

interface YesNoInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
}

export const YesNoInput = ({ label, ...props }: YesNoInputProps) => {
  const [field, meta] = useField(props);
  const form = useFormikContext();

  const showError = !!meta.error && (meta.touched || form.submitCount > 0);

  return (
    <YesNoInputContainer>
      <FormLabel>{label}</FormLabel>
      <RadioButtonYesNo
        value={'true'}
        name={props.name}
        onBlur={field.onBlur}
        onChange={field.onChange}
      />
      {showError && <FormErrorMessage>{meta.error}</FormErrorMessage>}
    </YesNoInputContainer>
  );
};

const YesNoInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
