import React from 'react';
import { useField, useFormikContext } from 'formik';
import styled from 'styled-components';
import { alertText, revolver } from '../../styling/colours';
import { fontParagraph } from '../../styling/fonts';
import { spacingXS, spacingXXXS } from '../../styling/spacing';
import { isoDatePart } from '../../utils/dates';
import { FormErrorMessage, FormLabel } from './SharedFormElements';

interface DateInputProps {
  label: string;
  name: string;
  max?: string;
  min?: string;
  disabled?: boolean;
}

export const DateInput: React.FC<DateInputProps> = ({ label, min, max, ...props }) => {
  const [field, meta] = useField(props);
  const form = useFormikContext();

  const isError = !!meta.error && (meta.touched || form.submitCount > 0);

  return (
    <TextInputContainer>
      <FormLabel>{label}</FormLabel>
      <Input
        type="date"
        hasError={isError}
        {...field}
        {...props}
        min={min && isoDatePart(min)}
        max={max && isoDatePart(max)}
      />
      {isError && <FormErrorMessage>{meta.error}</FormErrorMessage>}
    </TextInputContainer>
  );
};

const TextInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Input = styled.input<{ hasError: boolean }>`
  width: 100%;
  height: 54px;
  background: #ffffff 0 0 no-repeat padding-box;
  border: 1px solid ${(props) => (props.hasError ? alertText : revolver)};
  border-radius: ${spacingXXXS};
  opacity: 1;

  padding: 0 ${spacingXS};

  font: ${fontParagraph};
  letter-spacing: 0;
  color: #333f48;
`;
