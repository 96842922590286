import styled from 'styled-components/macro';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Header1 } from '../../shared/Headers';
import { CourseDto } from '../../models/course';
import { LargeLoadingIndicator } from '../../shared/LargeLoadingIndicator';
import { TrainingRecordsDto } from '../../models/training-record';
import { useTrainingRecordApi } from '../../hooks/useTrainingRecordApi';
import { useCourseApi } from '../../hooks/useCourseApi';
import { isFetchJsonError } from '../../api';
import { spacingM, spacingS } from '../../styling/spacing';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderItem,
  TableHeaderRow,
  TableItem,
  TableRow,
} from '../../shared/Table';
import { EmployeeItem } from '../../shared/user/EmployeeItem';
import { getPathToUserCourses } from '../view-user/ViewUser';
import { DateTableItem } from '../course-overview/DateTableItem';
import { InlineLink } from '../../shared/Link';
import { ButtonRowSpaceBetween, PrimaryButton } from '../../shared/Button';

type CourseHistoryParams = {
  id: string;
};

export const CourseHistory = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [course, setCourse] = useState<CourseDto | null>(null);
  const [trainingRecordsDto, setTrainingRecordsDto] = useState<TrainingRecordsDto | null>(null);

  const { getCourse } = useCourseApi();
  const { getTrainingRecordsForCourseId } = useTrainingRecordApi();

  const history = useHistory();
  const urlParams = useParams<CourseHistoryParams>();
  const trainingRecords = trainingRecordsDto?.trainingRecords;

  const fetchCourse = useCallback(() => {
    getCourse(urlParams.id).then((courseResponse) => {
      if (!isFetchJsonError(courseResponse)) {
        setCourse(courseResponse);
      }
    });
  }, [getCourse, urlParams.id]);

  const fetchTrainingRecords = useCallback(() => {
    getTrainingRecordsForCourseId(urlParams.id).then((trainingRecords) => {
      if (!isFetchJsonError(trainingRecords)) {
        setTrainingRecordsDto(trainingRecords);
      }
    });
  }, [getTrainingRecordsForCourseId, urlParams.id]);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([fetchCourse(), fetchTrainingRecords()]).finally(() => {
      setIsLoading(false);
    });
  }, [fetchCourse, fetchTrainingRecords]);

  if (isLoading || course == null) {
    return <LargeLoadingIndicator />;
  }

  const handleBackToCourseClick = () => {
    history.push(`/course/${course.id}/overview`);
  };

  return (
    <>
      {trainingRecords?.length === 0 && <>There are no training records for this course.</>}
      {trainingRecords?.length !== 0 && (
        <>
          1
          <TableHeaderContainer>
            <Header1>{course.courseName} - Training Record History</Header1>
          </TableHeaderContainer>
          <TableContainer>
            <Table>
              <TableHeader>
                <TableHeaderRow>
                  <TableHeaderItem colSpan={2}>Employee name</TableHeaderItem>
                  <TableHeaderItem>Completion date</TableHeaderItem>
                  <TableHeaderItem>Expiry date</TableHeaderItem>
                  <TableHeaderItem>Notes</TableHeaderItem>
                  <TableHeaderItem></TableHeaderItem>
                </TableHeaderRow>
              </TableHeader>
              <TableBody>
                {trainingRecords?.map((trainingRecord) => {
                  return (
                    <TableRow key={trainingRecord.id}>
                      <TableItem colSpan={2}>
                        <EmployeeItem
                          name={trainingRecord.user.name}
                          azureId={trainingRecord.user.azureUserId}
                          fishId={trainingRecord.userId}
                          link={getPathToUserCourses(trainingRecord.userId)}
                        />
                      </TableItem>
                      <TableItem>{trainingRecord.completionDate}</TableItem>
                      <DateTableItem dateString={trainingRecord.expiryDate} />
                      <TableItem>{trainingRecord.notes ?? ''}</TableItem>
                      <TableItem>
                        <InlineLink
                          to={{
                            pathname: `/training-record/${trainingRecord.id}/edit`,
                            state: {
                              prevPath: `/course/${course?.id}/history`,
                            },
                          }}
                        >
                          Edit
                        </InlineLink>
                      </TableItem>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <ButtonRowSpaceBetween>
            <PrimaryButton onClick={handleBackToCourseClick}>Back to Course</PrimaryButton>
          </ButtonRowSpaceBetween>
        </>
      )}
    </>
  );
};

const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${spacingM};
`;

const TableContainer = styled.div`
  padding-top: ${spacingS};
`;
