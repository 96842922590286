import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FetchJsonError, isFetchJsonError } from '../../api';
import { useCourseApi } from '../../hooks/useCourseApi';
import { useUserApi } from '../../hooks/useUserApi';
import { CreateUpdateCourseDto, CourseDtoToCreateUpdateCourseDto } from '../../models/course';
import { EmployeeListDto } from '../../models/user';
import { AlertType } from '../../shared/Alert';
import { Header2 } from '../../shared/Headers';
import { LargeLoadingIndicator } from '../../shared/LargeLoadingIndicator';
import { AssertUserHasPermission } from '../../shared/permission/AssertUserHasPermission';
import {
  AlertIfRequiredByLocationState,
  SingleUseAlertIfRequiredByLocation,
} from '../../shared/SingleUseAlertIfRequiredByLocation';
import { CourseForm } from '../../shared/course/CourseForm';

export type EditCourseParams = {
  id: string;
};

export const EditCourse = () => {
  const [course, setCourse] = useState<CreateUpdateCourseDto>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [employeeList, setEmployeeList] = useState<EmployeeListDto | null>(null);

  const { editCourse, getCourse } = useCourseApi();
  const { fetchEmployees } = useUserApi();

  const urlParams = useParams<EditCourseParams>();
  const history = useHistory<AlertIfRequiredByLocationState>();

  const refreshCourse = useCallback(() => {
    setIsLoading(true);
    getCourse(urlParams.id).then((res) => {
      if (!isFetchJsonError(res)) {
        setCourse(CourseDtoToCreateUpdateCourseDto(res));
        setIsLoading(false);
      }
    });
  }, [getCourse, urlParams]);

  useEffect(() => {
    setIsLoading(true);
    refreshCourse();
    fetchEmployees()
      .then((res: EmployeeListDto | FetchJsonError) => {
        if (!isFetchJsonError(res)) {
          setEmployeeList(res);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [refreshCourse, fetchEmployees]);

  const onFormSubmit = (updateCourseDto: CreateUpdateCourseDto) => {
    editCourse(urlParams.id, updateCourseDto).then((res) => {
      if (isFetchJsonError(res)) {
        history.push({
          pathname: `/course/${urlParams.id}/overview`,
          state: {
            alert: {
              title: 'Error',
              children: 'An error occurred whilst saving your changes',
              type: AlertType.Error,
              closeable: true,
            },
          },
        });
      } else {
        history.push({
          pathname: `/course/${urlParams.id}/overview`,
          state: {
            alert: {
              title: 'Success',
              children: 'Your changes were saved successfully',
              type: AlertType.Success,
              closeable: true,
            },
          },
        });
      }
    });
  };

  if (isLoading || course == null || employeeList == null) {
    return <LargeLoadingIndicator />;
  }

  return (
    <>
      <SingleUseAlertIfRequiredByLocation />
      <AssertUserHasPermission permission={'CreateEditCourses'}>
        <Header2>Edit Training Course</Header2>
        <CourseForm
          onSubmit={onFormSubmit}
          initialState={course}
          activeEmployees={employeeList.activeEmployees}
        />
      </AssertUserHasPermission>
    </>
  );
};
