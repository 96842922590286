import React from 'react';

export const AssessmentInstructions = () => (
  <>
    <p>
      To help prevent RSI and other problems associated with prolonged computer use, Ghyston have a
      policy of carrying out periodic workstation assessments to check for common issues and risk
      factors. Full details of the policy can be found{' '}
      <a
        href="https://ghyston.atlassian.net/wiki/spaces/GHYS/pages/1179257/Workstation+assessments"
        target="blank"
      >
        here
      </a>
      , but in brief:
    </p>
    <ul>
      <li>Employees can conduct their own workstation assessments</li>
      <li>
        Below is a simple checklist to help with this which you should complete. The Workstation
        Assessor will then review it
      </li>
      <li>
        Whenever you move to a new workstation or obtain a new piece of equipment (ie. Monitor,
        desk, keyboard, chair etc.) you should ensure that a workstation assessment is carried out.
      </li>
      <li>
        Home workers and contractors also need to ensure they are conducting these assessments and
        updating their workstation assessment form.
      </li>
      <li>
        If you require any assistance filling out the assessment below please talk to the Health and
        Safety team
      </li>
    </ul>
  </>
);
