/*
__ PRIMARY __
 */

export const giant = '#78BE20';
export const giantShade = '#60981A';
export const giantMediumTint = '#A1D263';
export const giantLightTint = '#D7ECBC';

export const midnight = '#333F48';
export const midnightShade = '#29323A';
export const midnightMediumTint = '#70797F';
export const midnightLightTint = '#C2C5C8';

/*
__ SECONDARY __
 */

export const whale = '#328BD9';
export const whaleShade = '#286FAE';
export const whaleMediumTint = '#70AEE4';
export const whaleLightTint = '#C2DCF4';

export const lorax = '#FF5C39';
export const loraxShade = '#CC4A2E';
export const loraxMediumTint = '#FF8D74';
export const loraxLightTint = '#FFCEC4';

export const peacock = '#00838A';
export const peacockShade = '#00696E';
export const peacockMediumTint = '#4DA8AD';
export const peacockLightTint = '#B3DADC';

export const canary = '#FFC72C';
export const canaryShade = '#CC9F23';
export const canaryMediumTint = '#FFD86B';
export const canaryLightTint = '#FFEEC0';

export const barney = '#991E66';
export const barneyShade = '#7A1852';
export const barneyMediumTint = '#B86294';
export const barneyLightTint = '#E0BCD1';

export const lime = '#CEDC00';
export const limeShade = '#A5B000';
export const limeMediumTint = '#DDE74D';
export const limeLightTint = '#F0F5B3';

/*
__ NOTICES AND ALERTS __
 */

export const notice = '#C5DBF2';
export const noticeText = '#565F6A';
export const success = '#D8EACC';
export const successText = '#167539';
export const warning = '#FBEECA';
export const warningText = '#A15B00';
export const alert = '#FBCFBD';
export const alertText = '#B22D00';

/*
__ BUTTONS __
 */

export const secondaryButton = '#757677';
export const secondaryButtonHover = '#606060';
export const dangerButton = '#e74c3c';
export const dangerButtonHover = '#be3b2d';
export const warningButton = '#f1c40f';
export const warningButtonHover = '#cfa90f';
export const successButton = '#2ab866';
export const successButtonHover = '#22a058';
export const radioSelectedBackground = '#99CDD0';
export const radioSelectedOutline = '#00838A';

/*
__ SHADES __
 */

export const revolver = '#DADADA';
export const koala = '#EAEAEA';
export const mushroom = '#F4F4F4';
export const olaf = '#FFFFFF';

export const fadedBlack = 'rgba(0,0,0,0.5)';
