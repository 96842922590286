import { Form } from 'formik';
import styled from 'styled-components/macro';
import { TextAreaInput } from '../../shared/form/TextAreaInput';
import { Header4 } from '../../shared/Headers';
import { alertText, midnight } from '../../styling/colours';
import { spacingS, spacingXS, spacingXXS } from '../../styling/spacing';

export const FormStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SectionHeader = styled(Header4)`
  color: ${midnight};
  display: flex;
  margin: ${spacingS} 0;
`;

export const QuestionContainer = styled.div`
  margin-bottom: ${spacingS};
  width: 100%;
`;

export const QuestionRow = styled.div`
  display: flex;
  flex-direction: column;
`;

export const QuestionAndAnswer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacingXXS};
`;

export const QuestionText = styled.div<{ hasError: boolean }>`
  width: 85%;
  color: ${(props) => (props.hasError ? alertText : null)};
`;

export const QuestionInfo = styled.p`
  margin-bottom: ${spacingXS};
`;

export const CommentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NotesTextAreaInput = styled(TextAreaInput)`
  height: 40px;
  min-height: 40px;
  padding: 4px 15px;
`;

export const AlertContainer = styled.div`
  width: 100%;
`;
