import styled from 'styled-components';
import { alertText, midnight, noticeText, warningText } from '../../styling/colours';
import { fontMicro, fontParagraph } from '../../styling/fonts';
import { spacingXXS } from '../../styling/spacing';

export const FormErrorMessage = styled.p`
  margin: ${spacingXXS} 0;
  color: ${alertText};
  font: ${fontMicro};
`;

export const FormWarningMessage = styled(FormErrorMessage)`
  color: ${warningText};
`;

export const FormNoticeMessage = styled(FormErrorMessage)`
  color: ${noticeText};
`;

export const FormLabel = styled.label`
  margin-bottom: ${spacingXXS};
  text-align: left;
  font: ${fontParagraph};
  letter-spacing: 0px;
  color: ${midnight};
  opacity: 1;
`;
