import React from 'react';
import DefaultModal from 'react-modal';
import styled from 'styled-components';
import { fadedBlack, olaf } from '../styling/colours';

const ModifiedModal: React.FC<DefaultModal.Props> = (props) => (
  <DefaultModal
    style={{
      overlay: {
        display: 'grid',
        placeContent: 'center',
        background: `${fadedBlack}`,
      },
    }}
    {...props}
  />
);

export const Modal = styled(ModifiedModal)`
  margin: auto;
  display: grid;
  place-content: center;
  background-color: ${olaf};
  max-width: 100%;
  border-radius: 5px;
`;
