import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { EmployeeListDtoEmployee } from '../../models/user';
import { MultiSelectField, SelectDropDownOptionValues } from '../form/MultiSelectField';
import { FormLabel } from '../form/SharedFormElements';
import { Avatar } from '../user/avatar/Avatar';
import { spacingXS } from '../../styling/spacing';

interface Props {
  name: string;
  label: string;
  employees: Array<EmployeeListDtoEmployee>;
  defaultEmployeeIds?: number[];
}

export type EmployeeOptionData = {
  azureId: string;
};

export const EmployeeDropDown = ({ name, label, employees, defaultEmployeeIds = [] }: Props) => {
  const options: SelectDropDownOptionValues<EmployeeOptionData>[] = useMemo(
    () =>
      employees.map((employee) => {
        return {
          value: employee.fishId,
          label: employee.name,
          azureId: employee.azureId,
        };
      }),
    [employees],
  );

  const CustomOptionComponent = ({
    label,
    azureId,
  }: SelectDropDownOptionValues<EmployeeOptionData>) => {
    return (
      <EmployeeNameContainer>
        <AvatarContainer>
          <Avatar name={label} azureId={azureId} size="small" />
        </AvatarContainer>
        {label}
      </EmployeeNameContainer>
    );
  };

  return (
    <>
      <FormLabel>{label}</FormLabel>
      <MultiSelectField<EmployeeOptionData>
        name={name}
        options={options}
        defaultOptionValues={defaultEmployeeIds}
        SelectedOptionComponent={CustomOptionComponent}
        DropDownOptionComponent={CustomOptionComponent}
      />
    </>
  );
};

const EmployeeNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AvatarContainer = styled.div`
  margin-right: ${spacingXS};
`;
