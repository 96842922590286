import React from 'react';
import { Route } from 'react-router';
import { ErrorPage } from './ErrorPage';
import { DesksOverview } from './features/desks/DesksOverview';
import { EditCourse } from './features/edit-course/EditCourse';
import { EditUser } from './features/edit-user/EditUser';
import { UserDesksSummary } from './features/desks/UserDesksSummary';
import { CourseOverview } from './features/course-overview/CourseOverview';
import { EmployeeTree } from './features/employee-list/EmployeeTree';
import { HotDesksOverview } from './features/hot-desks/HotDesksOverview';
import { ViewUserCourses } from './features/view-user-courses/ViewUserCourses';
import { ViewUser } from './features/view-user/ViewUser';
import { AddHotDeskAssessment } from './features/workspace-assessment/AddHotDeskAssessment';
import { VerifyAssessment } from './features/workspace-assessment/VerifyAssessment';
import { ViewDeskAssessment } from './features/workspace-assessment/ViewDeskAssessment';
import { ViewHotDeskAssessment } from './features/workspace-assessment/ViewHotDeskAssessment';
import { AuthContextProvider } from './shared/auth/AuthContext';
import { RequiresAuthentication } from './shared/auth/RequiresAuthentication';
import { ConfigContextProvider } from './shared/config/ConfigContext';
import { AddDeskAssessment } from './features/workspace-assessment/AddDeskAssessment';
import { Layout } from './shared/Layout';
import { AvatarContextProvider } from './shared/user/avatar/AvatarContext';
import { CurrentUserContextProvider } from './shared/user/CurrentUserContext';
import { CreateCourse } from './features/create-course/CreateCourse';
import { CreateTrainingRecord } from './features/create-training-record/CreateTrainingRecord';
import { EditTrainingRecord } from './features/edit-training-record/EditTrainingRecord';
import { CourseHistory } from './features/course-history/CourseHistory';

const App = () => {
  return (
    <ConfigContextProvider>
      <AuthContextProvider>
        <RequiresAuthentication>
          <CurrentUserContextProvider>
            <AvatarContextProvider>
              <Layout>
                <Route exact path="/" component={EmployeeTree} />
                <Route path="/desk/:id/add-assessment" component={AddDeskAssessment} />
                <Route path="/desk/overview" component={DesksOverview} />
                <Route path="/hot-desk/overview" component={HotDesksOverview} />
                <Route path="/user/:id/edit" component={EditUser} />
                <Route path="/user/:id/view" component={ViewUser} />
                <Route path="/user/:id/assessments" component={UserDesksSummary} />
                <Route path="/assessments/:id/verify" component={VerifyAssessment} />
                <Route exact path="/assessments/:id" component={ViewDeskAssessment} />
                <Route exact path="/assessments/hot-desk/:id" component={ViewHotDeskAssessment} />
                <Route
                  path="/assessments/user/:id/hot-desk/add-assessment"
                  component={AddHotDeskAssessment}
                />
                <Route exact path="/error" component={ErrorPage} />
                <Route exact path="/course/create" component={CreateCourse} />
                <Route exact path="/course/:id/overview" component={CourseOverview} />
                <Route exact path="/course/:id/history" component={CourseHistory} />
                <Route exact path="/course/:id/edit" component={EditCourse} />
                <Route
                  exact
                  path="/user/:id/training-record/create"
                  component={CreateTrainingRecord}
                />
                <Route exact path="/training-record/:id/edit" component={EditTrainingRecord} />
                <Route exact path="/user/:id/courses" component={ViewUserCourses} />
              </Layout>
            </AvatarContextProvider>
          </CurrentUserContextProvider>
        </RequiresAuthentication>
      </AuthContextProvider>
    </ConfigContextProvider>
  );
};

export default App;
