import React from 'react';
import { useParams } from 'react-router-dom';
import { useWorkspaceAssessmentApi } from '../../hooks/useWorkspaceAssessmentApi';
import { ApproveAssessmentParams, ViewAssessment } from './ViewAssessment';

export const ViewDeskAssessment = () => {
  const {
    approveDeskWorkspaceAssessment,
    fetchDeskWorkspaceAssessment,
  } = useWorkspaceAssessmentApi();
  const { id } = useParams<ApproveAssessmentParams>();

  return (
    <ViewAssessment
      fetchWorkspaceAssessment={fetchDeskWorkspaceAssessment}
      onSubmitApproval={approveDeskWorkspaceAssessment}
      errorPath={`/assessments/${id}`}
      pageTitle="Desk assessment"
    />
  );
};
