import React from 'react';
import styled from 'styled-components/macro';
import { pagePadding, pageWidth } from '../styling/layout';
import { spacingL, spacingXL } from '../styling/spacing';
import { FooterBar } from './footer/FooterBar';
import { NavBar } from './nav/NavBar';

type Props = {
  userExists: boolean;
  children?: React.ReactNode;
};

const BaseLayout: React.FC<Props> = ({ children, userExists }) => (
  <PageContainer>
    <NavBar hideNavLinks={!userExists} />
    <Container>{children}</Container>
    <FooterBar />
  </PageContainer>
);

export const Layout: React.FC = ({ children }) => (
  <BaseLayout userExists={true}>{children}</BaseLayout>
);

export const NoUserLayout: React.FC = ({ children }) => (
  <BaseLayout userExists={false}>{children}</BaseLayout>
);

export const Container = styled.div`
  flex: 1 1 auto;
  width: 100%;
  max-width: ${pageWidth};
  margin: ${spacingL} auto 0;
  padding: ${pagePadding} ${pagePadding} ${spacingXL};
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
`;
