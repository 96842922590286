import { Form, Formik } from 'formik';
import React from 'react';
import styled from 'styled-components/macro';
import { TrainingRecordsDataDto, TrainingRecordDataDto } from '../../models/training-record';
import { ButtonRow, CancelButton, SubmitButton } from '../../shared/Button';
import { InlineLink } from '../../shared/Link';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderItem,
  TableHeaderRow,
  TableItem,
  TableRow,
} from '../../shared/Table';
import { koala, midnight, midnightMediumTint } from '../../styling/colours';
import { spacingS } from '../../styling/spacing';
import { getPathToUserCourses } from '../view-user/ViewUser';
import { EmployeeItem } from '../../shared/user/EmployeeItem';
import { DateTableItem } from './DateTableItem';
type CourseOverviewTableFormProps = {
  showMissing?: boolean;
  onSubmit?: ((values: CourseOverviewTableFormValues) => void) | null;
  onResetChanges: () => void;
  trainingRecordDataDtos: Array<TrainingRecordsDataDto>;
  actionLinkText?: string;
};

export type CourseOverviewTableFormValues = {
  trainingRecordsToBeActioned: { [id: number]: TrainingRecordDataDto };
};

const getInitialFormValues = (): CourseOverviewTableFormValues => {
  return {
    trainingRecordsToBeActioned: {},
  };
};

export const CourseOverviewTableForm = ({
  onSubmit,
  trainingRecordDataDtos,
  actionLinkText = '',
  showMissing = false,
}: CourseOverviewTableFormProps) => {
  return (
    <Formik
      initialValues={getInitialFormValues()}
      onSubmit={
        onSubmit != null
          ? onSubmit
          : (_values) => {
              return;
            }
      }
    >
      {(props) => {
        const isPageChanged = Object.keys(props.values.trainingRecordsToBeActioned).length > 0;

        const handleActionLinkClicked = (trainingRecordListItemDto: TrainingRecordDataDto) => {
          const newTrainingRecordsToBeApproved = { ...props.values.trainingRecordsToBeActioned };
          newTrainingRecordsToBeApproved[trainingRecordListItemDto.id] = trainingRecordListItemDto;
          props.setFieldValue('trainingRecordsToBeActioned', newTrainingRecordsToBeApproved);
        };

        const handleCancelLinkClicked = (trainingRecordListItemDto: TrainingRecordDataDto) => {
          const newTrainingRecordsToBeApproved = { ...props.values.trainingRecordsToBeActioned };
          delete newTrainingRecordsToBeApproved[trainingRecordListItemDto.id];
          props.setFieldValue('trainingRecordsToBeActioned', newTrainingRecordsToBeApproved);
        };

        return (
          <Form>
            <TableContainer>
              <Table>
                <TableHeader>
                  <TableHeaderRow>
                    <TableHeaderItem colSpan={2}>Employee name</TableHeaderItem>
                    <TableHeaderItem>Completion date</TableHeaderItem>
                    <TableHeaderItem>Expiry date</TableHeaderItem>
                    <TableHeaderItem>Notes</TableHeaderItem>
                    <TableHeaderItem></TableHeaderItem>
                    <TableHeaderItem></TableHeaderItem>
                  </TableHeaderRow>
                </TableHeader>
                <TableBody>
                  {trainingRecordDataDtos.map((latestTrainingRecordDataDto) => {
                    if (latestTrainingRecordDataDto.trainingRecordData == null) {
                      return (
                        showMissing && (
                          <MissingTableRow key={latestTrainingRecordDataDto.userId}>
                            <TableItem colSpan={2}>
                              <EmployeeItem
                                name={latestTrainingRecordDataDto.name}
                                azureId={latestTrainingRecordDataDto.azureId}
                                fishId={latestTrainingRecordDataDto.userId}
                                link={getPathToUserCourses(latestTrainingRecordDataDto.userId)}
                              />
                            </TableItem>
                            <TableItem />
                            <TableItem />
                            <TableItem>Missing</TableItem>
                            <TableItem />
                            <TableItem />
                          </MissingTableRow>
                        )
                      );
                    }

                    const recordData = latestTrainingRecordDataDto.trainingRecordData;
                    return (
                      <TableRow key={latestTrainingRecordDataDto.userId}>
                        <TableItem colSpan={2}>
                          <EmployeeItem
                            name={latestTrainingRecordDataDto.name}
                            azureId={latestTrainingRecordDataDto.azureId}
                            fishId={latestTrainingRecordDataDto.userId}
                            link={getPathToUserCourses(latestTrainingRecordDataDto.userId)}
                          />
                        </TableItem>
                        <TableItem>{recordData.completionDate}</TableItem>
                        <DateTableItem dateString={recordData.expiryDate} />
                        <TableItem>{recordData.notes ?? ''}</TableItem>
                        <TableItem>
                          <InlineLink to={`/training-record/${recordData.id}/edit`}>
                            Edit
                          </InlineLink>
                        </TableItem>
                        {recordData.isApproved || onSubmit == null ? (
                          <TableItem />
                        ) : (
                          <TableItem>
                            {!(recordData.id in props.values.trainingRecordsToBeActioned) ? (
                              <InlineLink
                                to={window.location.pathname}
                                onClick={(_event) => handleActionLinkClicked(recordData)}
                              >
                                {actionLinkText}
                              </InlineLink>
                            ) : (
                              <CancelLink
                                to={window.location.pathname}
                                onClick={(_event) => handleCancelLinkClicked(recordData)}
                              >
                                Cancel
                              </CancelLink>
                            )}
                          </TableItem>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {onSubmit != null ? (
              <ButtonRow>
                {isPageChanged ? (
                  <CancelButton onClick={() => props.resetForm()}>Cancel</CancelButton>
                ) : (
                  <></>
                )}
                <SubmitButton isSubmitting={false} disabled={!isPageChanged}>
                  Save
                </SubmitButton>
              </ButtonRow>
            ) : (
              <></>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

const TableContainer = styled.div`
  padding-top: ${spacingS};
`;

const CancelLink = styled(InlineLink)`
  color: ${midnight};
  font-weight: bold;
  &:hover {
    color: ${midnightMediumTint};
  }
`;

const MissingTableRow = styled(TableRow)`
  background-color: ${koala};
  color: ${midnightMediumTint};
`;
