import React from 'react';
import { useField, useFormikContext } from 'formik';
import styled from 'styled-components';
import { alertText, revolver } from '../../styling/colours';
import { fontParagraph } from '../../styling/fonts';
import { spacingXXXS } from '../../styling/spacing';
import { FormErrorMessage, FormLabel } from './SharedFormElements';

interface TextAreaInputProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  label?: string;
}

export const TextAreaInput: React.FC<TextAreaInputProps> = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const form = useFormikContext();

  const showError = !!meta.error && (meta.touched || form.submitCount > 0);
  return (
    <TextAreaContainer>
      {label && <FormLabel>{label}</FormLabel>}
      <TextArea showError={showError} {...field} {...props} />
      {showError && <FormErrorMessage>{meta.error}</FormErrorMessage>}
    </TextAreaContainer>
  );
};

const TextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TextArea = styled.textarea<{ showError: boolean }>`
  width: 100%;
  resize: vertical;
  height: 100px;
  max-height: 300px;
  min-height: 66px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid ${(props) => (props.showError ? alertText : revolver)};
  border-radius: ${spacingXXXS};
  opacity: 1;

  font: ${fontParagraph};
  letter-spacing: 0px;
  color: #333f48;
  opacity: 1;
`;
