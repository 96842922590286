import React from 'react';
import styled from 'styled-components/macro';
import { EmployeeListDtoEmployee, EmployeeTreeDtoEmployeeNode } from '../../models/user';
import { BlockLink } from '../../shared/Link';
import { IfUserHasPermission } from '../../shared/permission/IfUserHasPermission';
import { InactiveTableRow, TableItem, TableRow } from '../../shared/Table';
import { Avatar } from '../../shared/user/avatar/Avatar';
import { EmployeeItem } from '../../shared/user/EmployeeItem';
import { revolver } from '../../styling/colours';
import { spacingS, spacingXS } from '../../styling/spacing';
import { getPathToUser } from '../view-user/ViewUser';

const pluralise = (count: number, singularLabel: string) =>
  `${count} ${singularLabel}${count !== 1 ? 's' : ''}`;
const goToPathUserDeskPage = (id: number) => `/user/${id}/assessments`;

export const testIdDeskCountItem = 'employee-tree-desk-count-item';
export const HierarchyTreeEmployeeNode = (props: { employee: EmployeeTreeDtoEmployeeNode }) => {
  return (
    <HierarchyBranchRoot>
      <HierarchyTreeBranch>
        <HierarchyTreeEmployeeLeaf employee={props.employee.employee} />
      </HierarchyTreeBranch>
      {props.employee.lineManaging.map((employeeNode, index) => (
        <HierarchyTreeEmployeeNode employee={employeeNode} key={index} />
      ))}
    </HierarchyBranchRoot>
  );
};

export const HierarchyTreeEmployeeLeaf = (props: { employee: EmployeeListDtoEmployee }) => (
  <EmployeeNodeContainer>
    <EmployeeItem
      name={props.employee.name}
      azureId={props.employee.azureId}
      fishId={props.employee.fishId}
      link={getPathToUser(props.employee.fishId)}
    />
    <IfUserHasPermission permission={'ReadDeskCount'}>
      {props.employee.fishId != null ? (
        <div data-testid={testIdDeskCountItem}>
          <BlockLink to={goToPathUserDeskPage(props.employee.fishId)}>
            {pluralise(props.employee.deskCount, 'desk')}
          </BlockLink>
        </div>
      ) : (
        <div data-testid={testIdDeskCountItem}>{pluralise(props.employee.deskCount, 'desk')}</div>
      )}
    </IfUserHasPermission>
  </EmployeeNodeContainer>
);

export const ActiveEmployeeNonTreeRow = (props: { employee: EmployeeListDtoEmployee }) => (
  <TableRow>
    <TableItem>
      <EmployeeNameContainer>
        <EmployeeItem
          name={props.employee.name}
          azureId={props.employee.azureId}
          fishId={props.employee.fishId}
          link={getPathToUser(props.employee.fishId)}
        />
      </EmployeeNameContainer>
    </TableItem>
    <TableItem />
    <IfUserHasPermission permission={'ReadDeskCount'}>
      {props.employee.fishId != null ? (
        <TableItem>
          <BlockLink to={goToPathUserDeskPage(props.employee.fishId)}>
            {pluralise(props.employee.deskCount, 'desk')}
          </BlockLink>
        </TableItem>
      ) : (
        <TableItem>{pluralise(props.employee.deskCount, 'desk')}</TableItem>
      )}
    </IfUserHasPermission>
  </TableRow>
);

export const DisabledEmployeeRow = (props: {
  employee: EmployeeListDtoEmployee;
  hasDeskCountPermission: boolean;
}) => (
  <InactiveTableRow>
    <TableItem>
      <Avatar name={props.employee.name} azureId={props.employee.azureId} size="small" />
    </TableItem>
    {props.employee.fishId != null ? (
      <TableItem>
        <BlockLink to={getPathToUser(props.employee.fishId)}>{props.employee.name}</BlockLink>
      </TableItem>
    ) : (
      <TableItem>{props.employee.name}</TableItem>
    )}
    <TableItem colSpan={props.hasDeskCountPermission ? 3 : 2}>
      <InactiveEmployeeTextContainer>
        {props.employee.deactivatedOn == null
          ? 'This employee was deactivated.'
          : 'This employee was deactivated on ' + props.employee.deactivatedOn + '.'}
      </InactiveEmployeeTextContainer>
    </TableItem>
  </InactiveTableRow>
);

const EmployeeNameContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: fit-content;
`;

const EmployeeNodeContainer = styled.div`
  display: flex;
  min-width: 26rem;
  align-items: center;
  justify-content: space-between;
  padding: ${spacingXS} ${spacingS} ${spacingXS} ${spacingS};
  margin: 10px;
  border-style: solid;
  border-color: ${revolver};
  border-width: 1px;
  border-radius: 0.125rem;
`;

const InactiveEmployeeTextContainer = styled.div`
  text-align: right;
`;

const HierarchyBranchRoot = styled.ul`
  list-style: none;
  position: relative;
  &:not(:last-child):after {
    content: '';
    display: block;
    position: absolute;
    height: calc(100% + ${spacingS});
    top: -10px;
    left: 1.5rem;
    border-left: solid;
    border-color: ${revolver};
    border-width: 2px;
  }
  &:last-child:after {
    content: '';
    display: block;
    position: absolute;
    height: 55px;
    top: -10px;
    left: 1.5rem;
    border-left: solid;
    border-color: ${revolver};
    border-width: 2px;
  }
`;

const HierarchyTreeBranch = styled.li`
  list-style: none;
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: -1rem;
    width: 1.5rem;
    border-bottom: solid;
    border-width: 2px;
    border-color: ${revolver};
  }
`;
