import { FieldArray, Formik, FormikErrors, FormikTouched, getIn } from 'formik';
import { findIndex } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import * as yup from 'yup';
import { FetchJsonError, isFetchJsonError } from '../../api';
import {
  ApproveWorkspaceAssessmentDto,
  ApproveWorkspaceAssessmentDtoAnswer,
  maxNotesLength,
  WorkspaceAssessmentDto,
} from '../../models/workspace-assessment';
import { Alert, AlertType } from '../../shared/Alert';
import { SubmitButton } from '../../shared/Button';
import { ErrorAlert } from '../../shared/error/ErrorAlert';
import { Checkbox } from '../../shared/form/Checkbox';
import { DateInput } from '../../shared/form/DateInput';
import { FormButtonContainer } from '../../shared/form/FormButtonContainer';
import { YesNoButton } from '../../shared/form/RadioButtonYesNo';
import { TextInput } from '../../shared/form/TextInput';
import { FormattedDate } from '../../shared/FormattedDate';
import { Header2, Header4, Header6 } from '../../shared/Headers';
import { LargeLoadingIndicator } from '../../shared/LargeLoadingIndicator';
import { InlineLink, InlineVirtualLink } from '../../shared/Link';
import {
  AlertIfRequiredByLocationState,
  SingleUseAlertIfRequiredByLocation,
} from '../../shared/SingleUseAlertIfRequiredByLocation';
import { CurrentUserContext } from '../../shared/user/CurrentUserContext';
import { UserLink } from '../../shared/user/UserLink';
import { alertText } from '../../styling/colours';
import { spacingS, spacingXL, spacingXS, spacingXXXS } from '../../styling/spacing';
import { todayIsoDate } from '../../utils/dates';
import {
  AlertContainer,
  CommentContainer,
  FormStyle,
  NotesTextAreaInput,
  QuestionAndAnswer,
  QuestionContainer,
  QuestionInfo,
  QuestionRow,
  QuestionText,
  SectionHeader,
  StyledForm,
} from './styles';

export const reviewCommentTestId = 'view-assessment-review-comment';
export const submitButtonTestId = 'view-assessment-submit-button';
export const archivedDeskWarningText = 'This assessment relates to an archived desk';

export type ApproveAssessmentParams = {
  id: string;
};

interface FormModel {
  answers: AnswerFormModel[];
  approvalComment: string;
  approvedOn: string;
}

export interface AnswerFormModel {
  questionId: number;
  isYes: boolean;
  isAddressed: boolean;
  reviewComment: string;
}

const validationSchema = yup.object().shape({
  answers: yup.array().of(
    yup.object().shape({
      questionId: yup.number(),
      isAddressed: yup.boolean(),
      reviewComment: yup
        .string()
        .max(maxNotesLength, `Review comment must be shorter than ${maxNotesLength} characters`),
    }),
  ),
  approvedOn: yup.string().required('Approval date required'),
  approvalComment: yup
    .string()
    .max(maxNotesLength, `Approval comment must be shorter than ${maxNotesLength} characters`),
});

const toApproveWorkspaceAssessmentDto = (formModel: FormModel): ApproveWorkspaceAssessmentDto => ({
  answers: formModel.answers.map(toApproveWorkspaceAssessmentAnswerDto),
  approvalComment: formModel.approvalComment,
  approvedOn: allQuestionsAddressed(formModel) ? formModel.approvedOn : null,
});

const toApproveWorkspaceAssessmentAnswerDto = (
  formModel: AnswerFormModel,
): ApproveWorkspaceAssessmentDtoAnswer => ({
  questionId: formModel.questionId,
  reviewComment: formModel.reviewComment,
  isAddressed: formModel.isAddressed,
});

const getQuestionIndexOfFirstError = (errors: FormikErrors<FormModel>): number | undefined => {
  if (Array.isArray(errors.answers)) {
    return findIndex<string | FormikErrors<AnswerFormModel>>(
      errors.answers,
      (answerError) =>
        typeof answerError === 'string' ||
        answerError?.isAddressed != null ||
        answerError?.reviewComment != null,
    );
  }
};

const allQuestionsAddressed = (formModel: FormModel) =>
  formModel.answers.every((a) => a.isYes || a.isAddressed);

const getQuestionIndexOfFirstUnaddressedQuestion = (formModel: FormModel): number | undefined =>
  findIndex(formModel.answers, (a) => !(a.isYes || a.isAddressed));

const scrollToQuestion = (questionIndex: number | undefined) => {
  const questionContainer = document.querySelector(`div[id="questionContainer.${questionIndex}"]`);
  if (questionContainer) {
    const top = questionContainer.getBoundingClientRect().top;
    if (top != null) {
      window.scrollTo({ top: window.scrollY + top, behavior: 'smooth' });
    }
  }
};

const questionHasError = (
  errors: FormikErrors<FormModel>,
  orderIndex: number,
  touched: FormikTouched<FormModel>,
): boolean => getIn(errors, `answers.${orderIndex}`) && getIn(touched, `answers.${orderIndex}`);

export const ViewAssessment: React.FC<{
  fetchWorkspaceAssessment: (
    assessmentId: string,
  ) => Promise<FetchJsonError | WorkspaceAssessmentDto>;
  onSubmitApproval: (
    assessmentId: string,
    approveWorkspaceDto: ApproveWorkspaceAssessmentDto,
  ) => Promise<boolean>;
  errorPath: string;
  pageTitle: string;
}> = ({ fetchWorkspaceAssessment, onSubmitApproval, errorPath, pageTitle }) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [assessment, setAssessment] = useState<WorkspaceAssessmentDto | null>(null);

  const { currentUser } = useContext(CurrentUserContext);
  const { id } = useParams<ApproveAssessmentParams>();

  const history = useHistory<AlertIfRequiredByLocationState>();

  useEffect(() => {
    setIsLoading(true);
    setError(undefined);

    fetchWorkspaceAssessment(id)
      .then((result: WorkspaceAssessmentDto | FetchJsonError) => {
        if (!isFetchJsonError(result)) {
          setAssessment(result);
        } else {
          setError(result.userVisibleErrorMessage);
          setIsLoading(false);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [fetchWorkspaceAssessment, id]);

  const submitWorkspaceAssessment = (approveWorkspaceAssessmentForm: FormModel) => {
    setIsSaving(true);
    setError(undefined);

    const isApproving = allQuestionsAddressed(approveWorkspaceAssessmentForm);

    onSubmitApproval(id, toApproveWorkspaceAssessmentDto(approveWorkspaceAssessmentForm)).then(
      (isRequestSuccessful: boolean) => {
        setIsSaving(false);
        history.push({
          pathname: isRequestSuccessful ? `/user/${assessment?.creator.id}/assessments` : errorPath,
          state: {
            alert: {
              title: isRequestSuccessful ? 'Success' : 'Error',
              children: isRequestSuccessful
                ? `Workstation assessment successfully ${isApproving ? 'approved' : 'saved'}`
                : `An error occurred ${isApproving ? 'approving' : 'saving'} the assessment`,
              type: isRequestSuccessful ? AlertType.Success : AlertType.Error,
              closeable: true,
            },
          },
        });
      },
    );
  };

  const initialValues: FormModel = {
    answers:
      assessment?.sections.flatMap((section) =>
        section.answers.map((a) => ({
          isYes: a.isYes,
          reviewComment: a.reviewComment ?? '',
          isAddressed: a.isAddressed,
          questionId: a.question.id,
        })),
      ) || [],
    approvalComment: assessment?.approvalComment || '',
    approvedOn: todayIsoDate(),
  };

  const sections = assessment?.sections || [];

  const hasApprovePermission =
    currentUser.userPermissions?.includes('ApproveWorkspaceAssessment') || false;
  const assessmentAlreadyApproved = !!assessment?.approvedOn;
  const canApprove = hasApprovePermission && !assessmentAlreadyApproved;

  return (
    <ViewAssessmentPage>
      {assessment?.isOnArchivedDesk && (
        <div>
          <ArchivedDeskWarning>{archivedDeskWarningText}</ArchivedDeskWarning>
        </div>
      )}
      <Header>
        <Title>
          {pageTitle}
          {canApprove ? ' approval' : ''}
        </Title>

        <AssessmentDateInfo>
          <Column>
            <div>
              Completed on <FormattedDate isoDate={assessment?.createdOn} /> by{' '}
              <UserLink user={assessment?.creator} />
            </div>
            {assessment?.approvedOn ? (
              <div>
                Approved on <FormattedDate isoDate={assessment.approvedOn} />
                {assessment.approver ? ' by ' : ''}
                <UserLink user={assessment.approver} />
              </div>
            ) : (
              ''
            )}
          </Column>
        </AssessmentDateInfo>
      </Header>

      {isLoading ? (
        <LargeLoadingIndicator />
      ) : (
        <>
          <SingleUseAlertIfRequiredByLocation />
          {error ? (
            <ErrorAlert userVisibleError={error} />
          ) : (
            <Formik
              onSubmit={canApprove ? submitWorkspaceAssessment : () => void 0}
              initialValues={initialValues}
              validationSchema={validationSchema}
            >
              {(props) => {
                const questionIndexOfFirstError = getQuestionIndexOfFirstError(props.errors);
                return (
                  <StyledForm>
                    {}
                    {
                      <FieldArray name="Answers">
                        {() => (
                          <FormStyle>
                            {sections.map((section, indexSection) => (
                              <div key={indexSection}>
                                <SectionHeader>{section.name}</SectionHeader>
                                {section.answers.map((answer, indexAnswers) => (
                                  <QuestionContainer
                                    id={`questionContainer.${answer.question.orderIndex}`}
                                    key={indexAnswers}
                                  >
                                    <QuestionRow>
                                      <QuestionAndAnswer>
                                        <QuestionText
                                          hasError={
                                            !answer.isYes &&
                                            !props.values.answers[answer.question.orderIndex]
                                              .isAddressed
                                          }
                                        >
                                          <Header6>{answer.question.questionText}</Header6>
                                        </QuestionText>
                                        <QuestionAnswer active={true} touched={true}>
                                          {answer.isYes ? 'Yes' : 'No'}
                                        </QuestionAnswer>
                                      </QuestionAndAnswer>
                                      <QuestionInfo>{answer.question.extraInfo}</QuestionInfo>
                                    </QuestionRow>
                                    {answer.notes && <AnswerNotes>{answer.notes}</AnswerNotes>}
                                    {canApprove ? (
                                      <CommentContainer>
                                        <ReviewComment
                                          hasError={questionHasError(
                                            props.errors,
                                            answer.question.orderIndex,
                                            props.touched,
                                          )}
                                        >
                                          Review Comment
                                        </ReviewComment>
                                        <NotesTextAreaInput
                                          data-testid={reviewCommentTestId}
                                          value={
                                            props.values.answers[answer.question.orderIndex]
                                              .reviewComment
                                          }
                                          name={`answers.${answer.question.orderIndex}.reviewComment`}
                                          onChange={props.handleChange}
                                          onBlur={props.handleBlur}
                                        />
                                      </CommentContainer>
                                    ) : answer.reviewComment ? (
                                      <div>
                                        <Header6>Review Comment</Header6>
                                        <QuestionInfo>{answer.reviewComment}</QuestionInfo>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                    {canApprove && !answer.isYes ? (
                                      <Checkbox
                                        label="Concerns have been addressed"
                                        name={`answers.${answer.question.orderIndex}.isAddressed`}
                                      />
                                    ) : (
                                      ''
                                    )}
                                  </QuestionContainer>
                                ))}
                              </div>
                            ))}
                          </FormStyle>
                        )}
                      </FieldArray>
                    }
                    {canApprove && (
                      <ApprovalInputsContainer>
                        <TextInput
                          label={'Approval Comment'}
                          name={'approvalComment'}
                          value={props.values.approvalComment}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        />
                        {allQuestionsAddressed(props.values) && (
                          <ApprovalDateContainer>
                            <DateInput
                              label={'Approval date'}
                              name={'approvedOn'}
                              min={assessment?.createdOn}
                              max={todayIsoDate()}
                            />
                          </ApprovalDateContainer>
                        )}
                      </ApprovalInputsContainer>
                    )}
                    {questionIndexOfFirstError !== undefined && props.submitCount > 0 ? (
                      <AlertContainer>
                        <Alert
                          title={'Error'}
                          type={AlertType.Error}
                          onClick={() => scrollToQuestion(questionIndexOfFirstError)}
                        >
                          <div>Click here to scroll to invalid input</div>
                        </Alert>
                      </AlertContainer>
                    ) : (
                      ''
                    )}
                    <FormButtonContainer>
                      {!allQuestionsAddressed(props.values) && (
                        <InlineVirtualLink
                          onClick={() =>
                            scrollToQuestion(
                              getQuestionIndexOfFirstUnaddressedQuestion(props.values),
                            )
                          }
                        >
                          There are outstanding questions to be addressed before approving
                        </InlineVirtualLink>
                      )}
                      {canApprove && (
                        <>
                          <InlineLink to={'/desk/overview'}>Cancel</InlineLink>
                          <SubmitButton isSubmitting={isSaving} data-testid={submitButtonTestId}>
                            {allQuestionsAddressed(props.values) ? 'Approve' : 'Save draft'}
                          </SubmitButton>
                        </>
                      )}
                    </FormButtonContainer>
                  </StyledForm>
                );
              }}
            </Formik>
          )}
          {!canApprove && assessment?.approvalComment && (
            <div>
              <Header4>Approval Comment</Header4>
              <QuestionInfo>{assessment.approvalComment}</QuestionInfo>
            </div>
          )}
        </>
      )}
    </ViewAssessmentPage>
  );
};

const ViewAssessmentPage = styled.div`
  position: relative;
  margin: 0 ${spacingXL};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: ${spacingS};
`;

const Title = styled(Header2)`
  align-self: center;
`;

const ArchivedDeskWarning = styled(Title)`
  color: ${alertText};
`;

const AssessmentDateInfo = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const QuestionAnswer = styled(YesNoButton)`
  border-radius: ${spacingXXXS};
  cursor: default;
  width: 60px;
  padding: 5px 15px;
`;

const AnswerNotes = styled.div`
  margin-bottom: ${spacingXS};
  font-weight: bold;
  font-style: italic;
`;

const ReviewComment = styled.span<{ hasError: boolean }>`
  color: ${(props) => (props.hasError ? alertText : null)};
`;

const ApprovalInputsContainer = styled.div`
  width: 100%;
  margin-top: ${spacingS};
  margin-bottom: ${spacingS};
`;

const ApprovalDateContainer = styled.div`
  width: 100%;
  margin-top: ${spacingS};
`;
