import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { isFetchJsonError } from '../../api';
import { useUserApi } from '../../hooks/useUserApi';
import { Permission } from '../../models/permission';
import {
  DisplayNameByEthnicityCode,
  DisplayNameByGenderCode,
  DisplayNameByNationalityCode,
  UserDto,
  UserHasPermission,
} from '../../models/user';
import { Alert, AlertType } from '../../shared/Alert';
import { Dropdown } from '../../shared/Dropdown';
import { ErrorAlert } from '../../shared/error/ErrorAlert';
import {
  AlertIfRequiredByLocationState,
  SingleUseAlertIfRequiredByLocation,
} from '../../shared/SingleUseAlertIfRequiredByLocation';
import { Avatar } from '../../shared/user/avatar/Avatar';
import { ButtonRow, DangerButton, PrimaryButton, SecondaryButton } from '../../shared/Button';
import { Header3, Header4, Header6 } from '../../shared/Headers';
import { BlockLink, InlineLink, InlineVirtualLink } from '../../shared/Link';
import { LargeLoadingIndicator } from '../../shared/LargeLoadingIndicator';
import { IfUserHasPermission } from '../../shared/permission/IfUserHasPermission';
import { CurrentUserContext } from '../../shared/user/CurrentUserContext';
import { dangerButton, dangerButtonHover, midnight } from '../../styling/colours';
import { fontItalic } from '../../styling/fonts';
import { spacingM, spacingS, spacingXS, spacingXXL, spacingXXS } from '../../styling/spacing';
import { ReactComponent as QuestionCircle } from '../../shared/icons/question-circle-regular.svg';
import { ChangeLineManagerModal } from './ChangeLineManagerModal';

export type ViewUserParams = {
  id: string;
};

export const getPathToUser = (id: number) => `/user/${id}/view`;
export const getPathToUserCourses = (id: number) => `/user/${id}/courses`;

export const testIdDropdownButton = 'view-user-page-dropdown-button';
export const testIdEditUserDropdownButton = 'view-user-page-edit-user-dropdown-button';
export const testIdDeleteUserButton = 'view-user-page-delete-user-button';
export const testIdEditUserButton = 'view-user-page-edit-user-button';
export const testIdDeleteConfirmationAlert = 'view-user-page-delete-confirmation-alert';
export const testIdUserConsentNotice = 'view-user-page-user-consent-notice';
export const testIdUserHotDeskStatus = 'view-user-page-hot-desk-status';

export const ViewUser = () => {
  const [user, setUser] = useState<UserDto>();
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [changeLineManagerModalIsOpen, setChangeLineManagerModalIsOpen] = useState<boolean>(false);

  const { currentUser } = useContext(CurrentUserContext);
  const { fetchUser, deleteUser } = useUserApi();
  const { id } = useParams<ViewUserParams>();
  const history = useHistory<AlertIfRequiredByLocationState>();

  useEffect(() => {
    setIsLoading(true);
    fetchUser(id)
      .then((result) => {
        if (!isFetchJsonError(result)) {
          setUser(result);
        } else {
          setError(result.userVisibleErrorMessage);
        }
      })
      .finally(() => setIsLoading(false));
  }, [fetchUser, id]);

  const getEditPermission = (user: UserDto): Permission =>
    currentUser.id === user.id ? 'WriteOwnDetails' : 'WriteUserDetails';

  const currentUserCanEditAndDelete = (user: UserDto) =>
    currentUser.id !== user.id &&
    currentUser.userPermissions?.includes('WriteUserDetails') &&
    currentUser.userPermissions?.includes('DeleteUser');
  const userCanEdit = (user: UserDto) =>
    currentUser.userPermissions?.includes(getEditPermission(user));

  const deleteUserButtonCallback = () => {
    setIsLoading(true);
    deleteUser(id).then((result) => {
      if (isFetchJsonError(result)) {
        setError(result.userVisibleErrorMessage);
        setIsLoading(false);
        history.replace({
          pathname: `/user/${id}/view`,
          state: {
            alert: {
              title: 'Error',
              children: `An error occurred whilst deleting ${user?.name}`,
              type: AlertType.Error,
              closeable: true,
            },
          },
        });
      } else {
        history.push({
          pathname: '/',
          state: {
            alert: {
              title: 'Success',
              children: `Successfully deleted ${user?.name}`,
              type: AlertType.Success,
              closeable: true,
            },
          },
        });
      }
      setIsLoading(false);
      setIsDeleting(false);
    });
  };

  if (error) {
    return <ErrorAlert userVisibleError={error} />;
  }

  if (isLoading || user == null) {
    return <LargeLoadingIndicator />;
  }

  if (isDeleting) {
    return (
      <Container data-testid={testIdDeleteConfirmationAlert}>
        <Alert title={'Warning'} type={AlertType.Error}>
          <p>
            You are about to delete the user <b>"{user.name}"</b>. This change will be irreversible
            and all their data will be lost. Are you sure you wish to delete this user?
          </p>
          <DeleteButtonContainer>
            <ButtonRow>
              <SecondaryButton onClick={() => setIsDeleting(false)}>Back</SecondaryButton>
              <DangerButton onClick={deleteUserButtonCallback}>Delete</DangerButton>
            </ButtonRow>
          </DeleteButtonContainer>
        </Alert>
      </Container>
    );
  }

  const handleEditButtonClick = () => {
    history.push(`/user/${user.id}/edit`);
  };

  return (
    <Container>
      <SingleUseAlertIfRequiredByLocation />
      <Header>
        <AvatarButtonContainer>
          <AvatarContainer>
            <Avatar azureId={user.azureUserId} name={user.name} size="large" />
          </AvatarContainer>
          <ButtonContainer>
            {currentUserCanEditAndDelete(user) ? (
              <Dropdown buttonTestId={testIdDropdownButton}>
                <DropdownMenuItem>
                  <DropdownLink
                    to={`/user/${user.id}/edit`}
                    data-testid={testIdEditUserDropdownButton}
                  >
                    Edit user
                  </DropdownLink>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <DropdownDeleteOption
                    onClick={() => setIsDeleting(true)}
                    data-testid={testIdDeleteUserButton}
                  >
                    Delete user
                  </DropdownDeleteOption>
                </DropdownMenuItem>
              </Dropdown>
            ) : (
              userCanEdit(user) && (
                <PrimaryButton
                  onClick={handleEditButtonClick}
                  type={'button'}
                  data-testid={testIdEditUserButton}
                >
                  Edit
                </PrimaryButton>
              )
            )}
          </ButtonContainer>
        </AvatarButtonContainer>
        <Header3>{`${user.name}${user.pronouns ? ` ${user.pronouns}` : ''}`}</Header3>
        <Header4>{user.ghystonEmailAddress}</Header4>
      </Header>
      <Body>
        <TwoColumnWrapper>
          <Column>
            <InfoContainer>
              <InfoContainerHeader>Personal contact details</InfoContainerHeader>
              {user.discretionaryData !== null && (
                <>
                  <DetailContainer>{user.discretionaryData.personalEmailAddress}</DetailContainer>
                  <DetailContainer>{user.discretionaryData.mobilePhoneNumber}</DetailContainer>
                </>
              )}

              <IfUserHasPermission
                permission={currentUser.id === user.id ? 'ReadOwnDetails' : 'ReadUserSensitiveData'}
              >
                <DetailContainer>
                  <HomeAddressContainer>
                    <HomeAddressRow>{user?.sensitiveData?.homeAddress}</HomeAddressRow>
                    <HomeAddressRow>{user?.sensitiveData?.city}</HomeAddressRow>
                    <HomeAddressRow>{user?.sensitiveData?.postcode}</HomeAddressRow>
                  </HomeAddressContainer>
                </DetailContainer>
              </IfUserHasPermission>
            </InfoContainer>

            <InfoContainer>
              <DetailContainer>
                <LineManagerLabelContainer>
                  <Header6>Line manager</Header6>
                  <StyledQuestionCircle onClick={() => setChangeLineManagerModalIsOpen(true)} />
                </LineManagerLabelContainer>
                {user.lineManagerId != null ? (
                  <BlockLink to={getPathToUser(user?.lineManagerId as number)}>
                    {user.lineManagerName}
                  </BlockLink>
                ) : (
                  user.lineManagerName
                )}
              </DetailContainer>
            </InfoContainer>

            <IfUserHasPermission
              permission={currentUser.id === user.id ? 'ReadOwnDetails' : 'ReadUserHotDeskStatus'}
            >
              <InfoContainer data-testid={testIdUserHotDeskStatus}>
                <InfoContainerHeader>Hot desk status</InfoContainerHeader>
                <DetailContainer>
                  {UserHasPermission(user, 'BeHotDeskUser')
                    ? 'Current hot desk user'
                    : 'Not currently hot desk user'}
                </DetailContainer>
                <DetailContainer>
                  {user.isRapidWorkspaceAssessmentTrained
                    ? 'Rapid workspace assessment training completed'
                    : 'Requires rapid workspace assessment training'}
                </DetailContainer>
              </InfoContainer>
            </IfUserHasPermission>
          </Column>
          <Column>
            <InfoContainer>
              <InfoContainerHeader>Emergency contact details</InfoContainerHeader>
              {user.discretionaryData != null ? (
                <>
                  <DetailContainer>{`${user.discretionaryData?.emergencyContactName} (${user.discretionaryData?.emergencyContactRelationship})`}</DetailContainer>
                  <DetailContainer>{`Daytime: ${user.discretionaryData?.emergencyContactDaytimePhoneNumber}`}</DetailContainer>
                  <DetailContainer>{`Evening: ${user.discretionaryData?.emergencyContactEveningPhoneNumber}`}</DetailContainer>
                  <SpecialDetails>
                    {user.discretionaryData?.emergencyContactSpecialDetails}
                  </SpecialDetails>
                </>
              ) : (
                `Please contact an admin to view the emergency contact details for ${user.name}`
              )}
            </InfoContainer>
            <IfUserHasPermission
              permission={currentUser.id === user.id ? 'ReadOwnDetails' : 'ReadUserProtectedData'}
            >
              <InfoContainer>
                <InfoContainerHeader>Protected Characteristics</InfoContainerHeader>
                {user.protectedData?.dateOfBirth && (
                  <DetailContainer>{user.protectedData?.dateOfBirth}</DetailContainer>
                )}
                {user.protectedData?.genderCode && (
                  <DetailContainer>
                    {user.protectedData.genderCode === 'Other'
                      ? user.protectedData.otherGender
                      : DisplayNameByGenderCode[user.protectedData.genderCode]}
                  </DetailContainer>
                )}
                {user.protectedData?.ethnicityCode && (
                  <DetailContainer>
                    {user.protectedData.ethnicityCode === 'Other'
                      ? user.protectedData.otherEthnicity
                      : DisplayNameByEthnicityCode[user.protectedData.ethnicityCode]}
                  </DetailContainer>
                )}
                {user.protectedData?.nationalityCode && (
                  <DetailContainer>
                    {user.protectedData.nationalityCode === 'Other'
                      ? user.protectedData.otherNationality
                      : DisplayNameByNationalityCode[user.protectedData.nationalityCode]}
                  </DetailContainer>
                )}
              </InfoContainer>
            </IfUserHasPermission>
          </Column>
        </TwoColumnWrapper>

        {!user.sharePersonalDetails && (
          <Alert type={AlertType.Notice} testId={testIdUserConsentNotice}>
            {currentUser.id === user.id
              ? 'You have opted out of sharing your personal details with non-admins'
              : `${user.name} has opted out of sharing their personal details with non-admins`}
          </Alert>
        )}
        <ChangeLineManagerModal
          closeModal={() => setChangeLineManagerModalIsOpen(false)}
          isOpen={changeLineManagerModalIsOpen}
        />
      </Body>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const DeleteButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: ${spacingS};
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: ${spacingM};
`;

const AvatarButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: auto;
  grid-template-areas: '. avatar buttons';
  width: 100%;
  margin-bottom: ${spacingS};
`;

const AvatarContainer = styled.div`
  grid-area: avatar;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  grid-area: buttons;
`;

const DropdownMenuItem = styled.div`
  :not(:first-child) {
    margin-top: ${spacingXXS};
  }
`;

const DropdownLink = styled(InlineLink)`
  font-weight: normal;
  white-space: nowrap;
`;

const DropdownDeleteOption = styled(InlineVirtualLink)`
  font-weight: normal;
  text-decoration: none;
  color: ${dangerButton};
  white-space: nowrap;
  :hover {
    color: ${dangerButtonHover};
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 ${spacingXXL};
`;

const TwoColumnWrapper = styled.div`
  display: flex;
`;

const Column = styled.div`
  flex: 1;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacingS};
`;

const InfoContainerHeader = styled(Header6)`
  margin-bottom: ${spacingXS};
`;

const DetailContainer = styled.div`
  margin-right: ${spacingS};
  margin-bottom: ${spacingXXS};
`;

const LineManagerLabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spacingXXS};
`;

const HomeAddressContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const HomeAddressRow = styled.div``;

const StyledQuestionCircle = styled(QuestionCircle)`
  width: 20px;
  height: 20px;
  margin-right: ${spacingXXS};
  margin-left: ${spacingXXS};
  color: ${midnight};
  cursor: pointer;
`;

const SpecialDetails = styled.p`
  white-space: pre-line;
  font: ${fontItalic};
`;
