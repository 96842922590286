import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import React, { useContext } from 'react';
import { LargeLoadingIndicator } from '../LargeLoadingIndicator';
import { AuthContext } from './AuthContext';

export const RequiresAuthentication: React.FC = (props) => {
  const { requiredScopes, authProvider } = useContext(AuthContext);

  const authenticationParameters = {
    scopes: requiredScopes,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authenticationParameters}
      errorComponent={() => <div>An error occurred while authenticating</div>}
      loadingComponent={() => <LargeLoadingIndicator />}
    >
      {() => {
        const accounts = authProvider.getAllAccounts();
        if (accounts.length > 0) {
          authProvider.setActiveAccount(accounts[0]);
        }

        return <>{props.children}</>;
      }}
    </MsalAuthenticationTemplate>
  );
};
