import { IPublicClientApplication } from '@azure/msal-browser';
import { createRequestInit, fetchJson, from } from '../api';
import { CourseDto } from '../models/course';
import { UserDto } from '../models/user';

export const logOut = async (
  authProvider: IPublicClientApplication,
  acquireAccessToken: () => Promise<string>,
): Promise<void> => {
  const token = await acquireAccessToken();
  await fetchJson<undefined>(from('hangfire/logout'), createRequestInit('GET', token));
  await authProvider.logout();
};

export const isUserCourseReporterOrAdminCourseDto = (
  course: CourseDto,
  currentUser: UserDto,
): boolean => {
  return (
    (currentUser.userPermissions != null &&
      currentUser.userPermissions.includes('CreateEditCourses')) ||
    course.reporters.some((reporter) => reporter.id === currentUser.id)
  );
};

export const isUserCourseReporterOrAdminCourseInCourseListDto = (
  course: CourseDto,
  currentUser: UserDto,
): boolean => {
  return (
    (currentUser.userPermissions != null &&
      currentUser.userPermissions.includes('CreateEditCourses')) ||
    course.reporters.some((reporter) => {
      return reporter.id === currentUser.id;
    })
  );
};
