import React from 'react';
import { useField, Field, useFormikContext } from 'formik';
import styled from 'styled-components';
import {
  midnight,
  midnightLightTint,
  midnightMediumTint,
  olaf,
  peacock,
  peacockShade,
} from '../../styling/colours';
import { fontParagraph } from '../../styling/fonts';
import { spacingXS, spacingXXXS } from '../../styling/spacing';
import { FormErrorMessage } from './SharedFormElements';

export const testIdHiddenCheckboxInput = 'test-hidden-checkbox-input';
export const testIdStyledCheckboxInput = 'test-styled-checkbox-input';
export const testIdCheckedCheckboxInput = 'test-checked-checkbox-input';

interface CheckboxProps {
  name: string;
  label: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({ label, name }) => {
  const [, meta] = useField(name);
  const form = useFormikContext();

  const showError = !!meta.error && (meta.touched || form.submitCount > 0);

  return (
    <CheckboxAndLabelContainer>
      <Label>
        <CheckboxContainer>
          <CheckboxField type="checkbox" name={name} />
        </CheckboxContainer>
        {label}
      </Label>
      {showError && <FormErrorMessage>{meta.error}</FormErrorMessage>}
    </CheckboxAndLabelContainer>
  );
};

const CheckboxAndLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${spacingXS};
`;

const CheckboxContainer = styled.div`
  display: flex;
  margin-right: ${spacingXS};
`;

const CheckboxField = styled(Field)`
  appearance: none;
  outline: 0;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border: 2px solid ${midnightLightTint};
  border-radius: ${spacingXXXS};

  &:hover {
    border-color: ${midnightMediumTint};
    cursor: pointer;
  }

  &:checked {
    background-color: ${peacock};
    border: none;

    &:hover {
      background-color: ${peacockShade};
    }

    &:after {
      position: relative;
      top: 1px;
      left: 4px;
      color: ${olaf};
      content: '\\2714'; /* bold check mark */
    }
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  text-align: left;
  font: ${fontParagraph};
  letter-spacing: 0px;
  color: ${midnight};
  opacity: 1;
`;
