import React from 'react';
import styled from 'styled-components/macro';
import { fontSmall } from '../../styling/fonts';

const footerText = 'Powered By Ghyston';

export const FooterBar = () => (
  <Footer>
    <Container>
      <GhystonLogo src={process.env.PUBLIC_URL + '/GhystonLogo.jpg'} alt="Ghyston logo" />
      <div>
        <StyledText>{footerText}</StyledText>
      </div>
    </Container>
  </Footer>
);

const Footer = styled.footer`
  display: flex;
  justify-content: center;
  height: 150px;
  bottom: 0;
  width: 100%;
`;

const Container = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const GhystonLogo = styled.img`
  width: 100px;
  vertical-align: middle;
  margin: 20px 0;
`;

const StyledText = styled.p`
  font-size: ${fontSmall};
`;
