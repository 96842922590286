import { useCallback } from 'react';
import { isFetchJsonError } from '../api';
import { CreateDeskDto, DesksDto, DeskOverviewDto } from '../models/desk';
import { useAuthorizedFetchJson } from './useAuthorizedFetch';

export const useDeskApi = () => {
  const createDeskAuthorizedFetch = useAuthorizedFetchJson<null, CreateDeskDto>('POST');
  const createDesk = useCallback(
    (userId: number, createDesk: CreateDeskDto) =>
      createDeskAuthorizedFetch(`desk/${userId}/create`, createDesk).then((res) =>
        isFetchJsonError(res) ? res : null,
      ),
    [createDeskAuthorizedFetch],
  );

  const moveDeskAuthorizedFetch = useAuthorizedFetchJson<null, CreateDeskDto>('POST');
  const moveDesk = useCallback(
    (deskId: number, createDesk: CreateDeskDto) =>
      moveDeskAuthorizedFetch(`desk/${deskId}/move`, createDesk).then((res) =>
        isFetchJsonError(res) ? res : null,
      ),
    [moveDeskAuthorizedFetch],
  );

  const fetchDesksAndUserAuthorizedFetch = useAuthorizedFetchJson<DesksDto>('GET');
  const fetchDesksAndUser = useCallback(
    (userId: string) => fetchDesksAndUserAuthorizedFetch(`desk/get/${userId}`),
    [fetchDesksAndUserAuthorizedFetch],
  );

  const fetchOverviewAuthorizedFetch = useAuthorizedFetchJson<DeskOverviewDto>('GET');
  const fetchOverview = useCallback(() => fetchOverviewAuthorizedFetch('desk/overview'), [
    fetchOverviewAuthorizedFetch,
  ]);

  const archiveDeskAuthorizedFetch = useAuthorizedFetchJson<null>('POST');
  const archiveDesk = useCallback(
    (deskId: number) =>
      archiveDeskAuthorizedFetch(`desk/${deskId}/archive`).then((res) =>
        isFetchJsonError(res) ? res : null,
      ),
    [archiveDeskAuthorizedFetch],
  );

  const forceDeskAssessmentRefreshAuthorizedFetch = useAuthorizedFetchJson<null>('POST');
  const forceDeskAssessmentRefresh = useCallback(
    (deskId: number) =>
      forceDeskAssessmentRefreshAuthorizedFetch(`desk/${deskId}/refresh`).then(
        (res) => !isFetchJsonError(res),
      ),
    [forceDeskAssessmentRefreshAuthorizedFetch],
  );

  return {
    createDesk,
    moveDesk,
    fetchDesksAndUser,
    fetchOverview,
    archiveDesk,
    forceDeskAssessmentRefresh,
  };
};
