import { Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import * as yup from 'yup';
import {
  CreateUpdateUserDto,
  EthnicityCode,
  ethnicityCodes,
  EthnicityOptions,
  GenderCode,
  genderCodes,
  GenderOptions,
  NationalityCode,
  nationalityCodes,
  NationalityOptions,
  UserDto,
  UserHasPermission,
} from '../../models/user';
import { midnight, noticeText } from '../../styling/colours';
import { spacingS, spacingXXS } from '../../styling/spacing';
import { yesterdayIsoDate } from '../../utils/dates';
import { Alert, AlertType } from '../Alert';
import { AuthContext } from '../auth/AuthContext';
import { FormButtonContainer } from '../form/FormButtonContainer';
import { SelectField } from '../form/SelectField';
import { YesNoInput } from '../form/YesNoInput';
import { InlineLink } from '../Link';
import { Avatar } from './avatar/Avatar';
import { PrimaryButton, PrimaryIconButton } from '../Button';
import { DateInput } from '../form/DateInput';
import { FormNoticeMessage, FormWarningMessage } from '../form/SharedFormElements';
import { TextAreaInput } from '../form/TextAreaInput';
import { TextInput } from '../form/TextInput';
import { Header2, Header3, Header4 } from '../Headers';
import { ReactComponent as PencilIcon } from '../../shared/icons/pencil-alt.svg';
import { ReactComponent as QuestionCircle } from '../../shared/icons/question-circle-regular.svg';
import { CurrentUserContext } from './CurrentUserContext';
import { EditPronounsInfoModalModal } from './EditPronounsInfoModal';

export interface UserFormProps {
  onSubmit: (createUser: CreateUpdateUserDto) => void;
  initialState?: UserDto;
}

export const testIdEditProfilePicture = 'user-form-edit-profile-picture';
export const testIdConsentRadioButtons = 'user-form-consent-radio-buttons';
export const testIdProtectedCharacteristicsHeader = 'user-form-protected-characteristics-header';

type CreateUserForm = {
  mobilePhoneNumber: string;
  personalEmailAddress: string;
  homeAddress: string;
  city: string;
  postcode: string;
  emergencyContactName: string;
  emergencyContactRelationship: string;
  emergencyContactDaytimePhoneNumber: string;
  emergencyContactEveningPhoneNumber: string;
  emergencyContactSpecialDetails: string | undefined;
  sharePersonalDetails: string;
  dateOfBirth?: string;
  genderCode?: GenderCode | null;
  otherGender: string | undefined;
  ethnicityCode?: EthnicityCode | null;
  otherEthnicity: string | undefined;
  nationalityCode?: NationalityCode | null;
  otherNationality?: string | undefined;
  isRapidWorkspaceAssessmentTrained: string;
};

const mapUserToCreateUserForm = (user?: UserDto): CreateUserForm => {
  return {
    mobilePhoneNumber: user?.discretionaryData?.mobilePhoneNumber ?? '',
    personalEmailAddress: user?.discretionaryData?.personalEmailAddress ?? '',
    homeAddress: user?.sensitiveData?.homeAddress ?? '',
    city: user?.sensitiveData?.city ?? '',
    postcode: user?.sensitiveData?.postcode ?? '',
    emergencyContactName: user?.discretionaryData?.emergencyContactName ?? '',
    emergencyContactRelationship: user?.discretionaryData?.emergencyContactRelationship ?? '',
    emergencyContactDaytimePhoneNumber:
      user?.discretionaryData?.emergencyContactDaytimePhoneNumber ?? '',
    emergencyContactEveningPhoneNumber:
      user?.discretionaryData?.emergencyContactEveningPhoneNumber ?? '',
    emergencyContactSpecialDetails: user?.discretionaryData?.emergencyContactSpecialDetails ?? '',
    sharePersonalDetails:
      user?.sharePersonalDetails === true
        ? 'true'
        : user?.sharePersonalDetails === false
        ? 'false'
        : '',
    dateOfBirth: user?.protectedData?.dateOfBirth.split('/').reverse().join('-') ?? '',
    genderCode: user?.protectedData?.genderCode ?? null,
    otherGender: user?.protectedData?.otherGender ?? '',
    ethnicityCode: user?.protectedData?.ethnicityCode ?? null,
    otherEthnicity: user?.protectedData?.otherEthnicity ?? '',
    nationalityCode: user?.protectedData?.nationalityCode ?? null,
    otherNationality: user?.protectedData?.otherNationality ?? '',
    isRapidWorkspaceAssessmentTrained: user?.isRapidWorkspaceAssessmentTrained
      ? 'true'
      : user?.isRapidWorkspaceAssessmentTrained === false
      ? 'false'
      : '',
  };
};

const mapCreateUserFormToCreateUser = (
  createUserForm: CreateUserForm,
  includeProtectedData: boolean,
): CreateUpdateUserDto => {
  return {
    mobilePhoneNumber: createUserForm.mobilePhoneNumber.trim(),
    personalEmailAddress: createUserForm.personalEmailAddress.trim(),
    homeAddress: createUserForm.homeAddress.trim(),
    city: createUserForm.city.trim(),
    postcode: createUserForm.postcode.trim(),
    emergencyContactName: createUserForm.emergencyContactName.trim(),
    emergencyContactRelationship: createUserForm.emergencyContactRelationship.trim(),
    emergencyContactDaytimePhoneNumber: createUserForm.emergencyContactDaytimePhoneNumber.trim(),
    emergencyContactEveningPhoneNumber: createUserForm.emergencyContactEveningPhoneNumber.trim(),
    emergencyContactSpecialDetails:
      createUserForm.emergencyContactSpecialDetails == null ||
      createUserForm.emergencyContactSpecialDetails.trim() === ''
        ? null
        : createUserForm.emergencyContactSpecialDetails.trim(),
    sharePersonalDetails: createUserForm.sharePersonalDetails === 'true',
    isRapidWorkspaceAssessmentTrained: createUserForm.isRapidWorkspaceAssessmentTrained === 'true',
    protectedDataDto: includeProtectedData
      ? {
          dateOfBirth: createUserForm.dateOfBirth ?? '2022-01-01',
          genderCode: createUserForm.genderCode ?? 'Abstain',
          otherGender:
            createUserForm.otherGender == null ||
            createUserForm.otherGender.trim() === '' ||
            createUserForm.genderCode !== 'Other'
              ? null
              : createUserForm.otherGender.trim(),
          ethnicityCode: createUserForm.ethnicityCode ?? 'Abstain',
          otherEthnicity:
            createUserForm.otherEthnicity == null ||
            createUserForm.otherEthnicity.trim() === '' ||
            createUserForm.ethnicityCode !== 'Other'
              ? null
              : createUserForm.otherEthnicity.trim(),
          nationalityCode: createUserForm.nationalityCode ?? 'Abstain',
          otherNationality:
            createUserForm.otherNationality == null ||
            createUserForm.otherNationality.trim() === '' ||
            createUserForm.nationalityCode !== 'Other'
              ? null
              : createUserForm.otherNationality.trim(),
        }
      : null,
  };
};

const phoneNumberRegEx = /^\+?([0-9] ?){6,15}$/;
const personalEmailAddressMaxLength = 400;
const homeAddressMaxLength = 500;
const cityMaxLength = 64;
const postcodeRegEx = /^[A-Za-z]{1,2}\d[A-Za-z\d]? \d[A-Za-z]{2}$/;
const emergencyContactNameMaxLength = 200;
const emergencyContactRelationshipMaxLength = 200;
const emergencyContactSpecialDetailsMaxLength = 800;
const otherProtectedCharacteristicMaxLength = 200;

const requiredMessage = 'Required!';
const invalidPhoneNumberMessage = 'Must be standard phone number format!';
const getInvalidMessage = (propertyName: string) => `Must be a valid ${propertyName}!`;
const getTooLongMessage = (propertyName: string, maxLength: number) =>
  `${propertyName} can be no longer than ${maxLength} characters`;

const GenerateUserFormValidation = (canEditProtectedCharacteristics: boolean) =>
  yup.object().shape<CreateUserForm>({
    mobilePhoneNumber: yup
      .string()
      .required(requiredMessage)
      .matches(phoneNumberRegEx, invalidPhoneNumberMessage),
    personalEmailAddress: yup
      .string()
      .trim()
      .max(
        personalEmailAddressMaxLength,
        getTooLongMessage('Personal email address', personalEmailAddressMaxLength),
      )
      .email(getInvalidMessage('email address'))
      .required(requiredMessage),
    homeAddress: yup
      .string()
      .trim()
      .max(homeAddressMaxLength, getTooLongMessage('Home address', homeAddressMaxLength))
      .required(requiredMessage),
    city: yup
      .string()
      .trim()
      .max(cityMaxLength, getTooLongMessage('City', cityMaxLength))
      .required(requiredMessage),
    postcode: yup
      .string()
      .trim()
      .matches(postcodeRegEx, getInvalidMessage('postcode'))
      .required(requiredMessage),
    emergencyContactName: yup
      .string()
      .trim()
      .max(
        emergencyContactNameMaxLength,
        getTooLongMessage('Emergency', emergencyContactNameMaxLength),
      )
      .required(requiredMessage),
    emergencyContactRelationship: yup
      .string()
      .trim()
      .max(
        emergencyContactRelationshipMaxLength,
        getTooLongMessage('Emergency contact', emergencyContactRelationshipMaxLength),
      )
      .required(requiredMessage),
    emergencyContactDaytimePhoneNumber: yup
      .string()
      .matches(phoneNumberRegEx, invalidPhoneNumberMessage)
      .required(requiredMessage),
    emergencyContactEveningPhoneNumber: yup
      .string()
      .matches(phoneNumberRegEx, invalidPhoneNumberMessage)
      .required(requiredMessage),
    emergencyContactSpecialDetails: yup
      .string()
      .max(
        emergencyContactSpecialDetailsMaxLength,
        getTooLongMessage('Emergency contact', emergencyContactSpecialDetailsMaxLength),
      )
      .trim()
      .optional(),
    sharePersonalDetails: yup
      .string()
      .oneOf(['true', 'false'], requiredMessage)
      .required(requiredMessage),
    isRapidWorkspaceAssessmentTrained: yup
      .string()
      .oneOf(['true', 'false'], requiredMessage)
      .required(requiredMessage),
    dateOfBirth: yup
      .string()
      .conditionallyRequired(canEditProtectedCharacteristics, requiredMessage),
    otherGender: yup.string().when('genderCode', (genderCode: GenderCode) => {
      if (genderCode === 'Other') {
        return yup
          .string()
          .trim()
          .max(
            otherProtectedCharacteristicMaxLength,
            getTooLongMessage('Other gender', otherProtectedCharacteristicMaxLength),
          )
          .required(requiredMessage);
      } else {
        return yup.string().optional();
      }
    }),
    otherEthnicity: yup.string().when('ethnicityCode', (ethnicityCode: EthnicityCode) => {
      if (ethnicityCode === 'Other') {
        return yup
          .string()
          .trim()
          .max(
            otherProtectedCharacteristicMaxLength,
            getTooLongMessage('Other ethnicity', otherProtectedCharacteristicMaxLength),
          )
          .required(requiredMessage);
      } else {
        return yup.string().optional();
      }
    }),
    otherNationality: yup.string().when('nationalityCode', (nationalityCode: NationalityCode) => {
      if (nationalityCode === 'Other') {
        return yup
          .string()
          .trim()
          .max(
            otherProtectedCharacteristicMaxLength,
            getTooLongMessage('Other nationality', otherProtectedCharacteristicMaxLength),
          )
          .required(requiredMessage);
      } else {
        return yup.string().optional();
      }
    }),
    genderCode: yup
      .mixed()
      .oneOf([null, ...genderCodes])
      .conditionallyRequired(canEditProtectedCharacteristics, requiredMessage),
    ethnicityCode: yup
      .mixed()
      .oneOf([null, ...ethnicityCodes])
      .conditionallyRequired(canEditProtectedCharacteristics, requiredMessage),
    nationalityCode: yup
      .mixed()
      .oneOf([null, ...nationalityCodes])
      .conditionallyRequired(canEditProtectedCharacteristics, requiredMessage),
  });

export const UserForm = ({ initialState, onSubmit }: UserFormProps) => {
  const { authProvider } = useContext(AuthContext);
  const { currentUser } = useContext(CurrentUserContext);
  const [editPronounsInfoModalIsOpen, setEditPronounsInfoModalIsOpen] = useState<boolean>(false);
  const initialValues = mapUserToCreateUserForm(initialState);
  const name = initialState?.name ?? (authProvider.getActiveAccount()?.name || '');
  const pronouns = initialState?.pronouns;

  const isNewUser = initialState == null;
  const isEditingCurrentUser = initialState == null || currentUser.id === initialState.id;
  const canEditProtectedCharacteristics =
    isEditingCurrentUser || UserHasPermission(currentUser, 'WriteUserProtectedData');

  const formSubmit = (createUser: CreateUserForm) => {
    const cast = mapCreateUserFormToCreateUser(createUser, canEditProtectedCharacteristics);
    onSubmit(cast);
  };

  return (
    <UserFormContainer>
      <Header>
        {isNewUser && (
          <>
            <Header2>Welcome to Fish</Header2>
            <SubHeader>Please fill in your details below to get started</SubHeader>
          </>
        )}
        <AvatarContainer>
          <Avatar
            azureId={initialState?.azureUserId ?? currentUser.azureUserId}
            name={name}
            size="large"
          />
          {isEditingCurrentUser && (
            <EditButton
              onClick={() =>
                window.open(
                  'https://outlook.office365.com/owa/?path=/options/myaccount/action/photo',
                )
              }
              data-testid={testIdEditProfilePicture}
            >
              <PencilIcon fill="currentColor" />
            </EditButton>
          )}
        </AvatarContainer>

        <NameContainer>
          <Header3>{`${name} ${pronouns ? pronouns : '(Pronouns not set)'}`}</Header3>
          <StyledQuestionCircle onClick={() => setEditPronounsInfoModalIsOpen(true)} />
        </NameContainer>
      </Header>

      <Formik
        onSubmit={formSubmit}
        initialValues={initialValues}
        validationSchema={GenerateUserFormValidation(canEditProtectedCharacteristics)}
      >
        {(props) => {
          return (
            <StyledForm>
              <TextInput
                type="tel"
                label="Personal Mobile Phone Number"
                name="mobilePhoneNumber"
                value={props.values.mobilePhoneNumber}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              />
              <TextInput
                type="email"
                label="Personal Email Address"
                name="personalEmailAddress"
                value={props.values.personalEmailAddress}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              />
              <TextInput
                label="Home Address"
                value={props.values.homeAddress}
                name="homeAddress"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              />
              <TextInput
                label="City"
                value={props.values.city}
                name="city"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              />
              <TextInput
                label="Postcode"
                value={props.values.postcode}
                name="postcode"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              />
              <YesNoInput
                label="Have you completed rapid workspace assessment training?"
                value={props.values.isRapidWorkspaceAssessmentTrained}
                name="isRapidWorkspaceAssessmentTrained"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              />
              <SectionHeaderContainer>
                <Header4>Emergency Contact Details</Header4>
              </SectionHeaderContainer>
              <TextInput
                label="Contact Name"
                name="emergencyContactName"
                value={props.values.emergencyContactName}
                // Chrome only applies autocomplete="off" when it's on the whole form element, so this is actually just
                // a field it won't recognise
                autoComplete="none"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              />
              <TextInput
                label="Relationship"
                name="emergencyContactRelationship"
                value={props.values.emergencyContactRelationship}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              />
              <TextInput
                type="tel"
                label="Daytime Phone Number"
                name="emergencyContactDaytimePhoneNumber"
                value={props.values.emergencyContactDaytimePhoneNumber}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              />
              <TextInput
                type="tel"
                label="Evening Phone Number"
                name="emergencyContactEveningPhoneNumber"
                value={props.values.emergencyContactEveningPhoneNumber}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              />
              <TextAreaInput
                label="Additional Details (Optional)"
                value={props.values.emergencyContactSpecialDetails}
                name="emergencyContactSpecialDetails"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              />

              {isEditingCurrentUser && (
                <SharePersonalDetailsContainer data-testid={testIdConsentRadioButtons}>
                  <YesNoInput
                    label="Would you like to share your details with all employees?"
                    value={props.values.sharePersonalDetails}
                    name="sharePersonalDetails"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  />
                  {props.values.sharePersonalDetails === 'true' && (
                    <FormNoticeMessage>
                      Your personal email and emergency contact information will be shared with all
                      employees
                    </FormNoticeMessage>
                  )}
                  {props.values.sharePersonalDetails === 'false' && (
                    <FormWarningMessage>
                      In an emergency situation, it could be useful for other employees to be able
                      to see this information - are you sure you want to hide it?
                    </FormWarningMessage>
                  )}
                </SharePersonalDetailsContainer>
              )}

              {canEditProtectedCharacteristics && (
                <>
                  <SectionHeaderContainer data-testid={testIdProtectedCharacteristicsHeader}>
                    <Header4>Protected Characteristics</Header4>
                  </SectionHeaderContainer>
                  <DateInput label="Date Of Birth" name="dateOfBirth" max={yesterdayIsoDate()} />

                  <SelectField name="genderCode" options={GenderOptions} label="Gender" />
                  {props.values.genderCode === 'Other' && (
                    <TextInput
                      type="text"
                      label="Other gender"
                      name="otherGender"
                      value={props.values.otherGender}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                  )}

                  <SelectField name="ethnicityCode" options={EthnicityOptions} label="Ethnicity" />
                  {props.values.ethnicityCode === 'Other' && (
                    <TextInput
                      type="text"
                      label="Other ethnicity"
                      name="otherEthnicity"
                      value={props.values.otherEthnicity}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                  )}

                  <SelectField
                    name="nationalityCode"
                    options={NationalityOptions}
                    label="Nationality"
                  />
                  {props.values.nationalityCode === 'Other' && (
                    <TextInput
                      type="text"
                      label="Other nationality"
                      name="otherNationality"
                      value={props.values.otherNationality}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                  )}
                </>
              )}

              {!props.isValid && props.submitCount > 0 && (
                <Alert title="Error" type={AlertType.Error}>
                  <div>There are errors in the form that must be resolved before submitting</div>
                </Alert>
              )}

              <FormButtonContainer>
                {initialState && (
                  <InlineLink to={`/user/${initialState.id}/view`}>Cancel</InlineLink>
                )}
                <PrimaryButton type="submit">Submit</PrimaryButton>
              </FormButtonContainer>
            </StyledForm>
          );
        }}
      </Formik>
      <EditPronounsInfoModalModal
        closeModal={() => setEditPronounsInfoModalIsOpen(false)}
        isOpen={editPronounsInfoModalIsOpen}
      />
    </UserFormContainer>
  );
};

const UserFormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubHeader = styled.p`
  color: ${noticeText};
  margin-bottom: ${spacingS};
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: ${spacingS};
`;

const AvatarContainer = styled.div`
  position: relative;
  margin-bottom: ${spacingS};
`;

const EditButton = styled(PrimaryIconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 25px;
`;

const NameContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledQuestionCircle = styled(QuestionCircle)`
  width: 20px;
  height: 20px;
  margin-left: ${spacingXXS};
  color: ${midnight};
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  transition: opacity 0.25s ease;
`;

const StyledForm = styled(Form)`
  > * {
    margin-bottom: ${spacingS};
  }
`;

const SharePersonalDetailsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacingS};
  margin-top: ${spacingS};
`;

const SectionHeaderContainer = styled.div`
  margin-top: ${spacingS};
`;
