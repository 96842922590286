export const fontSmall = '12px';
export const fontBase = '16px';
export const fontMedium = '18px';
export const fontLarge = '22px';
export const fontXl = '26px';
export const font2xl = '31px';
export const font3xl = '37px';

export const headingLineHeight = (fontSize: string): string => `calc(${fontSize}*1.4)`;
export const bodyLineHeight = (fontSize: string): string => `calc(${fontSize}*1.6)`;

export const fontFamily = 'proxima-nova, sans-serif';

export const monospacedFontFamily =
  'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;';

export const fontHeading1 = `normal normal bold ${font3xl}/${headingLineHeight(
  font3xl,
)} ${fontFamily}`;
export const fontHeading2 = `normal normal bold ${font2xl}/${headingLineHeight(
  font2xl,
)} ${fontFamily}`;
export const fontHeading3 = `normal normal bold ${fontXl}/${headingLineHeight(
  fontXl,
)} ${fontFamily}`;
export const fontHeading4 = `normal normal bold ${fontLarge}/${headingLineHeight(
  fontLarge,
)} ${fontFamily}`;
export const fontHeading5 = `normal normal bold ${fontMedium}/${headingLineHeight(
  fontMedium,
)} ${fontFamily}`;
export const fontHeading6 = `normal normal bold ${fontBase}/${headingLineHeight(
  fontBase,
)} ${fontFamily}`;

export const fontSubheading1 = `normal normal normal ${font3xl}/${headingLineHeight(
  font3xl,
)} ${fontFamily}`;
export const fontSubheading2 = `normal normal normal ${font2xl}/${headingLineHeight(
  font2xl,
)} ${fontFamily}`;
export const fontSubheading3 = `normal normal normal ${fontXl}/${headingLineHeight(
  fontXl,
)} ${fontFamily}`;
export const fontSubheading4 = `normal normal normal ${fontLarge}/${headingLineHeight(
  fontSmall,
)} ${fontFamily}`;
export const fontSubheading5 = `normal normal normal ${fontMedium}/${headingLineHeight(
  fontMedium,
)} ${fontFamily}`;
export const fontSubheading6 = `normal normal normal ${fontBase}/${headingLineHeight(
  fontBase,
)} ${fontFamily}`;

export const fontParagraph = `normal normal normal ${fontBase}/${bodyLineHeight(
  fontBase,
)} ${fontFamily}`;

export const fontItalic = `italic normal normal ${fontBase}/${bodyLineHeight(
  fontBase,
)} ${fontFamily}`;

export const fontMicro = `normal normal normal ${fontSmall}/${bodyLineHeight(
  fontSmall,
)} ${fontFamily}`;
