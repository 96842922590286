import React, { Context, createContext } from 'react';
import { useUserApi } from '../../../hooks/useUserApi';

export type AvatarContextValue = {
  getAvatar: (azureId: string) => Promise<string>;
};

export const AvatarContext: Context<AvatarContextValue> = createContext<AvatarContextValue>({
  getAvatar: () => new Promise<string>(() => ''),
});

type Props = {
  children?: React.ReactNode;
};

export const AvatarContextProvider = (props: Props) => {
  const avatars: { [azureId: string]: string } = {};

  const { fetchUserImage } = useUserApi();

  const getAvatar = async (azureId: string) => {
    if (azureId in avatars) {
      return avatars[azureId];
    } else {
      avatars[azureId] = await fetchUserImage(azureId);
      return avatars[azureId];
    }
  };

  return <AvatarContext.Provider value={{ getAvatar }}>{props.children}</AvatarContext.Provider>;
};
