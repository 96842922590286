import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { isFetchJsonError } from '../../api';
import { useUserApi } from '../../hooks/useUserApi';
import { CreateUpdateUserDto } from '../../models/user';
import { AlertType } from '../../shared/Alert';
import { GetRequest } from '../../shared/apiHelpers/GetRequest';
import { AssertUserHasPermission } from '../../shared/permission/AssertUserHasPermission';
import { AlertIfRequiredByLocationState } from '../../shared/SingleUseAlertIfRequiredByLocation';
import { CurrentUserContext } from '../../shared/user/CurrentUserContext';
import { UserForm } from '../../shared/user/UserForm';
import { spacingXXL, spacingL } from '../../styling/spacing';

export type EditUserParams = {
  id: string;
};

export const EditUser = () => {
  const { currentUser } = useContext(CurrentUserContext);
  const { fetchUser, updateUser } = useUserApi();
  const { id: userId } = useParams<EditUserParams>();
  const history = useHistory<AlertIfRequiredByLocationState>();
  const onFormSubmit = (updateUserDto: CreateUpdateUserDto) => {
    updateUser(userId, updateUserDto).then((result) => {
      if (isFetchJsonError(result)) {
        history.push({
          pathname: `/user/${userId}/view`,
          state: {
            alert: {
              title: 'Error',
              children: 'An error occurred whilst saving your changes',
              type: AlertType.Error,
              closeable: true,
            },
          },
        });
      } else {
        history.push({
          pathname: `/user/${userId}/view`,
          state: {
            alert: {
              title: 'Success',
              children: 'Your changes were saved successfully',
              type: AlertType.Success,
              closeable: true,
            },
          },
        });
      }
    });
  };

  return (
    <GetRequest request={() => fetchUser(userId)}>
      {(user) => (
        <AssertUserHasPermission
          permission={currentUser.id === user.id ? 'WriteOwnDetails' : 'WriteUserDetails'}
        >
          <EditUserPage>
            <UserForm onSubmit={onFormSubmit} initialState={user} />
          </EditUserPage>
        </AssertUserHasPermission>
      )}
    </GetRequest>
  );
};

const EditUserPage = styled.div`
  position: relative;
  margin: 0 ${spacingXXL};
  margin-bottom: ${spacingL};
`;
