import { Form, Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import * as yup from 'yup';
import { isFetchJsonError } from '../../api';
import { useUserApi } from '../../hooks/useUserApi';
import { EmployeeListDtoEmployee } from '../../models/user';
import { ButtonRow, SubmitButton } from '../../shared/Button';
import { EmployeeDropDown } from '../../shared/course/EmployeeDropDown';
import { DateInput } from '../../shared/form/DateInput';
import { TextInput } from '../../shared/form/TextInput';
import { Header3 } from '../../shared/Headers';
import { InlineLink } from '../../shared/Link';
import { midnight, midnightMediumTint } from '../../styling/colours';
import { shadowHeavy } from '../../styling/shadows';
import { spacingL, spacingM, spacingS, spacingXXS } from '../../styling/spacing';
import { formatDate, getMinExpiryDateString } from '../../utils/dates';
import { SmallLoadingIndicator } from '../../shared/SmallLoadingIndicator';

type BulkAddTrainingRecordsFormProps = {
  defaultExpiryTime: number | undefined;
  onCancelAddEmployeesButtonClicked: () => void;
  onSubmit: (values: EmployeesToBeAddedFormValues) => void;
};

export type EmployeesToBeAddedFormValues = {
  employeeIdsToBeAddedToCourse: Array<number>;
  completionDate: string;
  expiryDate: string;
  notes?: string;
};

const getInitialFormValues = (): EmployeesToBeAddedFormValues => {
  return {
    employeeIdsToBeAddedToCourse: [],
    completionDate: '',
    expiryDate: '',
    notes: '',
  };
};

const notesMaxLength = 2000;
const requiredMessage = 'Required!';
const getTooLongMessage = (propertyName: string, maxLength: number) =>
  `${propertyName} can be no longer than ${maxLength} characters`;
const BulkTrainingRecordFormValidation = yup.object().shape<EmployeesToBeAddedFormValues>({
  employeeIdsToBeAddedToCourse: yup
    .array(yup.number().required(requiredMessage))
    .min(1, requiredMessage)
    .required(requiredMessage),
  completionDate: yup.string().required(requiredMessage),
  expiryDate: yup.string(),
  notes: yup.string().max(notesMaxLength, getTooLongMessage('Notes', notesMaxLength)),
});

export const BulkAddTrainingRecordsForm = ({
  defaultExpiryTime,
  onCancelAddEmployeesButtonClicked,
  onSubmit,
}: BulkAddTrainingRecordsFormProps) => {
  const [activeEmployees, setActiveEmployees] = useState<Array<EmployeeListDtoEmployee> | null>(
    null,
  );
  const { fetchEmployees } = useUserApi();
  const fishUsers = useMemo(() => activeEmployees?.filter((e) => e.fishId) || [], [
    activeEmployees,
  ]);

  useEffect(() => {
    fetchEmployees().then((response) => {
      if (!isFetchJsonError(response)) {
        setActiveEmployees(response.activeEmployees);
      }
    });
  }, [fetchEmployees]);

  if (activeEmployees == null) {
    return <SmallLoadingIndicator />;
  }

  return (
    <Formik
      initialValues={getInitialFormValues()}
      onSubmit={onSubmit}
      validationSchema={BulkTrainingRecordFormValidation}
    >
      {(props) => {
        const getExpiryDate = () => {
          if (props.values.completionDate === '' || defaultExpiryTime === undefined) {
            return null;
          }
          const date = new Date(props.values.completionDate);
          date.setDate(date.getDate() + defaultExpiryTime);
          return formatDate(date.toISOString());
        };

        const getExpirationDateString = () => {
          const expiryDate = getExpiryDate();
          return expiryDate ? expiryDate.substr(0, 10) : 'dd / mm / yyyy';
        };

        return (
          <BulkAddTrainingCoursesFormContainer>
            <Header3>Add Multiple New Training Records</Header3>
            <StyledForm>
              <EmployeeDropDown
                name={'employeeIdsToBeAddedToCourse'}
                label={'Employees to be added to the course'}
                employees={fishUsers}
                defaultEmployeeIds={props.initialValues.employeeIdsToBeAddedToCourse}
              />

              <div>
                <DateInput label={'Completion date'} name={'completionDate'} />
                {defaultExpiryTime !== null && (
                  <ExpirationDate>
                    Training records expiary: {getExpirationDateString()}
                  </ExpirationDate>
                )}
              </div>

              {defaultExpiryTime === null && (
                <DateInput
                  label={'Expiry date'}
                  name={'expiryDate'}
                  min={getMinExpiryDateString(props.values.completionDate)}
                />
              )}

              <TextInput
                name={'notes'}
                label={'Notes'}
                value={props.values.notes}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              />

              <ButtonRow>
                <SubmitButton isSubmitting={false}>Submit</SubmitButton>
                <CancelLink
                  to={window.location.pathname}
                  onClick={() => onCancelAddEmployeesButtonClicked()}
                >
                  Cancel
                </CancelLink>
              </ButtonRow>
            </StyledForm>
          </BulkAddTrainingCoursesFormContainer>
        );
      }}
    </Formik>
  );
};

const CancelLink = styled(InlineLink)`
  color: ${midnight};
  font-weight: bold;
  &:hover {
    color: ${midnightMediumTint};
  }
`;

const BulkAddTrainingCoursesFormContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding-left: ${spacingL};
  padding-right: ${spacingL};
  padding-top: ${spacingM};
  padding-bottom: ${spacingS};

  border-radius: ${spacingXXS};
  box-shadow: ${shadowHeavy};

  margin-bottom: ${spacingM};
`;

const StyledForm = styled(Form)`
  margin-top: ${spacingS};

  > * {
    margin-bottom: ${spacingS};
  }
`;

const ExpirationDate = styled.p`
  color: ${midnightMediumTint};
  margin-top: ${spacingXXS};
  margin-left: ${spacingXXS};
`;
