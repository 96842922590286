import React from 'react';
import { UserNameDto } from '../../models/user';
import { InlineLink } from '../Link';

export interface UserLinkProps {
  user?: UserNameDto | null;
}

export const UserLink = ({ user }: UserLinkProps) =>
  user ? (
    user.id ? (
      <InlineLink to={`/user/${user.id}/view`}>{user.name}</InlineLink>
    ) : (
      <>{user.name}</>
    )
  ) : null;
