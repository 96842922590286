export type CourseDto = {
  id: number;
  courseName: string;
  reporters: Array<ReporterDto>;
  requiredForAllEmployees: boolean;
  defaultExpiryTime?: number;
};

export type CourseWithExpiredCountDto = CourseDto & {
  expiryCount: number;
};

export type CreateUpdateCourseDto = {
  courseName: string;
  defaultExpiryTime?: number | null;
  reporterUserIds: number[];
  requiredForAllEmployees: boolean;
};

export const CourseDtoToCreateUpdateCourseDto = (courseDto: CourseDto) => {
  const createUpdateCourseDto: CreateUpdateCourseDto = {
    courseName: courseDto.courseName,
    defaultExpiryTime: courseDto.defaultExpiryTime,
    reporterUserIds: courseDto.reporters.map((reporter) => reporter.id),
    requiredForAllEmployees: courseDto.requiredForAllEmployees,
  };
  return createUpdateCourseDto;
};

export type CoursesDto = {
  courses: Array<CourseDto>;
};

export type CoursesWithExpiredCountDto = {
  courses: Array<CourseWithExpiredCountDto>;
};

export type ReporterDto = {
  id: number;
  azureId: string;
  name: string;
};
