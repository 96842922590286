import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { peacock, peacockShade } from '../styling/colours';

export const BlockLink = styled(Link)`
  display: flex;
  flex: 1;
  align-items: center;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  color: ${peacock};
  :hover {
    color: ${peacockShade};
  }
`;

export const InlineLink = styled(Link)`
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  color: ${peacock};
  :hover {
    color: ${peacockShade};
  }
`;

export const InlineVirtualLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
  color: ${peacock};
  :hover {
    color: ${peacockShade};
  }
`;
