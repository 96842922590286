import React from 'react';
import styled from 'styled-components/macro';
import { ButtonRow, SecondaryButton } from '../Button';
import { Header3 } from '../Headers';
import { Modal } from '../Modal';
import { spacingS, spacingXS } from '../../styling/spacing';

type ChangeLineManagerModalProps = {
  closeModal: () => void;
  isOpen: boolean;
};

export const EditPronounsInfoModalModal = ({ closeModal, isOpen }: ChangeLineManagerModalProps) => {
  return (
    <Modal isOpen={isOpen} contentLabel="Edit pronouns info modal" onRequestClose={closeModal}>
      <ModalContentContainer>
        <Header3>Editing Pronouns</Header3>
        <TextContainer>
          <p>Pronouns cannot currently be updated through FISH.</p>
          <p>If the information shown here is incorrect, please contact helpdesk.</p>
        </TextContainer>
        <ButtonRow>
          <SecondaryButton onClick={closeModal}>Close</SecondaryButton>
        </ButtonRow>
      </ModalContentContainer>
    </Modal>
  );
};

const ModalContentContainer = styled.div`
  margin: ${spacingS};
`;

const TextContainer = styled.div`
  margin: ${spacingXS} 0;
`;
