import React from 'react';
import { Alert, AlertType } from '../Alert';

type Props = {
  userVisibleError?: string;
  closeable?: boolean;
};

export const ErrorAlert = ({ userVisibleError, closeable = false }: Props) => (
  <Alert title={userVisibleError || 'Error'} type={AlertType.Error} closeable={closeable}>
    Please contact{' '}
    <a href={'mailto:helpdesk@ghyston.com?subject=FISH Error'}>helpdesk@ghyston.com</a> for
    assistance.
  </Alert>
);
