import { Form, Formik } from 'formik';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import * as yup from 'yup';
import { createTrainingRecordParams } from '../../features/create-training-record/CreateTrainingRecord';
import { CoursesDto } from '../../models/course';
import { TrainingRecordDto } from '../../models/training-record';
import { spacingS } from '../../styling/spacing';
import { getMinExpiryDateString } from '../../utils/dates';
import { Alert, AlertType } from '../Alert';
import { DateInput } from '../form/DateInput';
import { FormButtonContainer } from '../form/FormButtonContainer';
import { PrimaryButton } from '../Button';
import { SelectField, SelectOption } from '../form/SelectField';
import { TextInput } from '../form/TextInput';

export interface TrainingRecordFormProps {
  onSubmit: (
    createTrainingRecordForm: CreateTrainingRecordForm,
    userId: string,
    hasDefault: boolean,
  ) => void;
  initialState?: TrainingRecordDto;
  courses: CoursesDto;
  disableSelect: boolean;
}

export type CreateTrainingRecordForm = {
  courseId: number;
  completionDate: string;
  expiryDate: string;
  notes?: string;
};

const mapCreateUpdateTrainingRecordToCreateTrainingRecordForm = (
  trainingRecord?: TrainingRecordDto,
): CreateTrainingRecordForm => {
  return {
    courseId: trainingRecord?.courseId ?? -1,
    completionDate: trainingRecord?.completionDate ?? '',
    expiryDate: trainingRecord?.expiryDate ?? '',
    notes: trainingRecord?.notes ?? '',
  };
};

const notesMaxLength = 2000;
const requiredMessage = 'Required!';
const getTooLongMessage = (propertyName: string, maxLength: number) =>
  `${propertyName} can be no longer than ${maxLength} characters`;

const CourseFormValidation = yup.object().shape<CreateTrainingRecordForm>({
  courseId: yup.number().required(requiredMessage),
  completionDate: yup.string().required(requiredMessage),
  expiryDate: yup.string(),
  notes: yup.string().max(notesMaxLength, getTooLongMessage('Notes', notesMaxLength)),
});

const checkIfCourseHasDefault = (courseId: number, courses: CoursesDto): boolean => {
  const courseToFind = courses.courses.find((course) => course.id === courseId);
  if (courseToFind == null) {
    return false;
  }
  return courseToFind.defaultExpiryTime != null;
};

export const TrainingRecordForm = ({
  initialState,
  onSubmit,
  courses,
}: TrainingRecordFormProps) => {
  const initialValues = mapCreateUpdateTrainingRecordToCreateTrainingRecordForm(initialState);
  const { id } = useParams<createTrainingRecordParams>();

  const formSubmit = (createTrainingRecord: CreateTrainingRecordForm) => {
    onSubmit(
      createTrainingRecord,
      id,
      checkIfCourseHasDefault(createTrainingRecord.courseId, courses),
    );
  };

  const courseOptions = courses.courses.map(
    (c) => ({ value: c.id, label: c.courseName } as SelectOption<number>),
  );

  return (
    <TrainingRecordFormContainer>
      <Formik
        onSubmit={formSubmit}
        initialValues={initialValues}
        validationSchema={CourseFormValidation}
      >
        {(props) => {
          return (
            <StyledForm>
              <SelectField name="courseId" label="Training Course Name" options={courseOptions} />
              <DateInput
                label="Completion Date"
                name="completionDate"
                max={new Date().toISOString()}
              />
              {!checkIfCourseHasDefault(props.values.courseId, courses) && (
                <div>
                  <DateInput
                    label="Expiry Date (optional)"
                    name="expiryDate"
                    min={getMinExpiryDateString(props.values.completionDate)}
                  />
                  <p>Leave this blank if it doesn't expire</p>
                </div>
              )}
              <TextInput
                label="Notes (optional)"
                name="notes"
                value={props.values.notes}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              />

              {!props.isValid && props.submitCount > 0 && (
                <Alert title="Error" type={AlertType.Error}>
                  <div>There are errors in the form that must be resolved before submitting</div>
                </Alert>
              )}

              <FormButtonContainer>
                <PrimaryButton type="submit">Submit</PrimaryButton>
              </FormButtonContainer>
            </StyledForm>
          );
        }}
      </Formik>
    </TrainingRecordFormContainer>
  );
};

const TrainingRecordFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${spacingS};
`;

const StyledForm = styled(Form)`
  > * {
    margin-bottom: ${spacingS};
  }
`;
