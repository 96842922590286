import * as yup from 'yup';

yup.addMethod<yup.MixedSchema>(yup.mixed, 'conditionallyRequired', function (
  required: boolean,
  message?: string,
) {
  return required ? this.required(message) : this.optional();
});

yup.addMethod<yup.StringSchema>(yup.string, 'conditionallyRequired', function (
  required: boolean,
  message?: string,
) {
  return required ? this.required(message) : this.optional();
});
