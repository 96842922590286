import React, { useState } from 'react';
import styled from 'styled-components';
import { pagePadding } from '../styling/layout';
import { spacingS, spacingXS, spacingXXXS } from '../styling/spacing';
import { ReactComponent as ChevronDown } from './icons/chevron-down-solid.svg';
import { ReactComponent as ChevronUp } from './icons/chevron-up-solid.svg';
import { koala, olaf, peacock, revolver } from '../styling/colours';

interface ExpandableCardProps {
  header: React.ReactNode;
  initiallyExpanded?: boolean;
  isDisabled?: boolean;
  icon?: React.ReactNode;
  children?: React.ReactNode;
}

export const ExpandableComponent: React.FC<ExpandableCardProps> = (props) => {
  const [hidden, setHidden] = useState<boolean>(!props.initiallyExpanded);

  return (
    <Container isDisabled={props.isDisabled}>
      <Header>
        <IconContainer>{props.icon}</IconContainer>
        <HeaderContent onClick={() => setHidden(!hidden)}>
          {hidden ? <Down /> : <Up />}
          {props.header}
        </HeaderContent>
      </Header>
      <Body hidden={hidden}>{props.children}</Body>
    </Container>
  );
};

const iconSize = '16px';

const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const HeaderContent = styled.div`
  padding: ${spacingS};
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  cursor: pointer;
`;

const Container = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacingXS};
  background-color: ${(props) => (props.isDisabled ? koala : olaf)};
  border: 1px solid ${revolver};
  border-radius: ${spacingXXXS};
`;

const Body = styled.div<{ hidden: boolean }>`
  border-radius: ${spacingXXXS};
  padding: 0 ${spacingS} ${spacingS} ${spacingS};
  display: ${(props) => (props.hidden ? 'none' : 'block')};
`;

const Up = styled(ChevronUp)`
  height: 20px;
  width: 20px;
  margin-right: ${spacingXS};
  color: ${peacock};
`;

const Down = styled(ChevronDown)`
  height: 20px;
  width: 20px;
  margin-right: ${spacingXS};
  color: ${peacock};
`;

const IconContainer = styled.div`
  height: ${iconSize};
  width: ${iconSize};
  position: absolute;
  left: calc(-1 * (${iconSize} + ${pagePadding}) / 2);
`;
