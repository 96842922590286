import { useCallback } from 'react';
import { isFetchJsonError } from '../api';
import { HotDeskAssessmentsSummaryDto } from '../models/hot-desk';
import {
  ApproveWorkspaceAssessmentDto,
  CreateWorkspaceAssessmentDto,
  WorkspaceAssessmentDto,
  WorkspaceAssessmentSectionsDto,
} from '../models/workspace-assessment';
import { useAuthorizedFetchJson } from './useAuthorizedFetch';

export const useWorkspaceAssessmentApi = () => {
  const fetchWorkspaceAssessmentSectionsAuthorizedFetch = useAuthorizedFetchJson<
    WorkspaceAssessmentSectionsDto
  >('GET');
  const fetchWorkspaceAssessmentSections = useCallback(
    () =>
      fetchWorkspaceAssessmentSectionsAuthorizedFetch(`workspace-assessment`).then((res) =>
        isFetchJsonError(res) ? res : res.sections,
      ),
    [fetchWorkspaceAssessmentSectionsAuthorizedFetch],
  );

  const createWorkspaceAssessmentAuthorizedFetch = useAuthorizedFetchJson<
    null,
    CreateWorkspaceAssessmentDto
  >('POST');
  const createWorkspaceAssessment = useCallback(
    (deskId: number, createWorkspaceAssessmentDto: CreateWorkspaceAssessmentDto) =>
      createWorkspaceAssessmentAuthorizedFetch(
        `desk/${deskId}/add-assessment`,
        createWorkspaceAssessmentDto,
      ).then((res) => (isFetchJsonError(res) ? res : null)),
    [createWorkspaceAssessmentAuthorizedFetch],
  );

  const createHotDeskWorkspaceAssessmentAuthorizedFetch = useAuthorizedFetchJson<
    null,
    CreateWorkspaceAssessmentDto
  >('POST');
  const createHotDeskWorkspaceAssessment = useCallback(
    (userId: number, createWorkspaceAssessmentDto: CreateWorkspaceAssessmentDto) =>
      createHotDeskWorkspaceAssessmentAuthorizedFetch(
        `workspace-assessment/user/${userId}/hot-desk/add-assessment`,
        createWorkspaceAssessmentDto,
      ).then((res) => (isFetchJsonError(res) ? res : null)),
    [createHotDeskWorkspaceAssessmentAuthorizedFetch],
  );

  const fetchDeskWorkspaceAssessmentAuthorizedFetch = useAuthorizedFetchJson<
    WorkspaceAssessmentDto
  >('GET');
  const fetchDeskWorkspaceAssessment = useCallback(
    (assessmentId: string) =>
      fetchDeskWorkspaceAssessmentAuthorizedFetch(`workspace-assessment/${assessmentId}`),
    [fetchDeskWorkspaceAssessmentAuthorizedFetch],
  );

  const approveDeskWorkspaceAssessmentAuthorizedFetch = useAuthorizedFetchJson<
    null,
    ApproveWorkspaceAssessmentDto
  >('POST');
  const approveDeskWorkspaceAssessment = useCallback(
    (assessmentId: string, approveWorkspaceAssessmentDto: ApproveWorkspaceAssessmentDto) =>
      approveDeskWorkspaceAssessmentAuthorizedFetch(
        `workspace-assessment/${assessmentId}/approve`,
        approveWorkspaceAssessmentDto,
      ).then((res) => !isFetchJsonError(res)),
    [approveDeskWorkspaceAssessmentAuthorizedFetch],
  );

  const verifyWorkspaceAssessmentAuthorizedFetch = useAuthorizedFetchJson<null>('GET');
  const verifyWorkspaceAssessment = useCallback(
    (assessmentId: string) =>
      verifyWorkspaceAssessmentAuthorizedFetch(`workspace-assessment/${assessmentId}/verify`).then(
        (res) => !isFetchJsonError(res),
      ),
    [verifyWorkspaceAssessmentAuthorizedFetch],
  );

  const fetchHotDeskWorkspaceAssessmentsSummaryAuthorizedFetch = useAuthorizedFetchJson<
    HotDeskAssessmentsSummaryDto
  >('GET');
  const fetchHotDeskWorkspaceAssessmentsSummary = useCallback(
    (userId: string) =>
      fetchHotDeskWorkspaceAssessmentsSummaryAuthorizedFetch(
        `workspace-assessment/user/${userId}/hot-desk`,
      ),
    [fetchHotDeskWorkspaceAssessmentsSummaryAuthorizedFetch],
  );

  const createRapidWorkspaceAssessmentAuthorizedFetch = useAuthorizedFetchJson<null>('POST');
  const createRapidWorkspaceAssessment = useCallback(
    (userId: number) =>
      createRapidWorkspaceAssessmentAuthorizedFetch(
        `workspace-assessment/user/${userId}/hot-desk/add-rapid-assessment`,
      ).then((res) => !isFetchJsonError(res)),
    [createRapidWorkspaceAssessmentAuthorizedFetch],
  );

  const fetchHotDeskWorkspaceAssessmentAuthorizedFetch = useAuthorizedFetchJson<
    WorkspaceAssessmentDto
  >('GET');
  const fetchHotDeskWorkspaceAssessment = useCallback(
    (assessmentId: string) =>
      fetchHotDeskWorkspaceAssessmentAuthorizedFetch(
        `workspace-assessment/hot-desk/${assessmentId}`,
      ),
    [fetchHotDeskWorkspaceAssessmentAuthorizedFetch],
  );

  const approveHotDeskWorkspaceAssessmentAuthorizedFetch = useAuthorizedFetchJson<
    null,
    ApproveWorkspaceAssessmentDto
  >('POST');
  const approveHotDeskWorkspaceAssessment = useCallback(
    (assessmentId: string, approveWorkspaceAssessmentDto: ApproveWorkspaceAssessmentDto) =>
      approveHotDeskWorkspaceAssessmentAuthorizedFetch(
        `workspace-assessment/hot-desk/${assessmentId}/approve`,
        approveWorkspaceAssessmentDto,
      ).then((res) => !isFetchJsonError(res)),
    [approveHotDeskWorkspaceAssessmentAuthorizedFetch],
  );

  return {
    fetchWorkspaceAssessmentSections,
    createWorkspaceAssessment,
    fetchDeskWorkspaceAssessment,
    approveDeskWorkspaceAssessment,
    verifyWorkspaceAssessment,
    createHotDeskWorkspaceAssessment,
    fetchHotDeskWorkspaceAssessmentsSummary,
    fetchHotDeskWorkspaceAssessment,
    approveHotDeskWorkspaceAssessment,
    createRapidWorkspaceAssessment,
  };
};
