import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useDeskApi } from '../../hooks/useDeskApi';
import { AlertType } from '../../shared/Alert';
import { ButtonRow, PrimaryButton, SecondaryButton } from '../../shared/Button';
import { Header3 } from '../../shared/Headers';
import { Modal } from '../../shared/Modal';

type DeskRefreshModalProps = {
  closeModal: () => void;
  isOpen: boolean;
  deskId: number;
};

export const DeskRefreshModal = ({ closeModal, isOpen, deskId }: DeskRefreshModalProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const history = useHistory();
  const { forceDeskAssessmentRefresh } = useDeskApi();

  const handleRefreshClick = () => {
    setIsSaving(true);
    forceDeskAssessmentRefresh(deskId).then((successfullyRefreshedDesk: boolean) => {
      setIsSaving(false);
      closeModal();
      history.replace({
        state: {
          alert: {
            title: successfullyRefreshedDesk ? 'Success' : 'Error',
            children: successfullyRefreshedDesk
              ? 'Email reminder sent'
              : 'An error occurred refreshing the desk',
            type: successfullyRefreshedDesk ? AlertType.Success : AlertType.Error,
            closeable: true,
          },
        },
      });
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="request a new assessment on a desk modal"
      onRequestClose={closeModal}
    >
      {deskId !== null && (
        <ModalContentContainer>
          <Header3>Request Desk Refresh</Header3>
          <p>
            This will send the employee an email asking them to submit a new assessment for this
            desk and set the due date for this desk to today
          </p>
          <p>Are you sure you want to continue?</p>
          <ButtonRow>
            <CancelButton disabled={isSaving} onClick={closeModal}>
              Cancel
            </CancelButton>
            <SendReminderButton onClick={handleRefreshClick}>Send Reminder</SendReminderButton>
          </ButtonRow>
        </ModalContentContainer>
      )}
    </Modal>
  );
};

const ModalContentContainer = styled.div`
  margin: 20px;
`;

const CancelButton = styled(SecondaryButton)`
  margin: 0;
`;

const SendReminderButton = styled(PrimaryButton)`
  margin: 0;
`;
