import React from 'react';
import styled from 'styled-components/macro';
import { BlockLink } from '../Link';
import { Avatar } from './avatar/Avatar';

type EmployeeItemProps = {
  name: string;
  azureId: string;
  fishId: number;
  link: string;
};

export const EmployeeItem = ({ name, azureId, fishId, link }: EmployeeItemProps) => {
  return (
    <EmployeeNameItem>
      <AvatarContainer>
        <Avatar name={name} azureId={azureId} size={'small'} />
      </AvatarContainer>
      {fishId != null ? <BlockLink to={link}>{name}</BlockLink> : name}
    </EmployeeNameItem>
  );
};

const EmployeeNameItem = styled.div`
  display: flex;
  align-items: center;
`;

const AvatarContainer = styled.div`
  padding-right: 20px;
`;
