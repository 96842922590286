import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FetchJsonError, isFetchJsonError } from '../../api';
import { useCourseApi } from '../../hooks/useCourseApi';
import { useUserApi } from '../../hooks/useUserApi';
import { CreateUpdateCourseDto } from '../../models/course';
import { EmployeeListDto } from '../../models/user';
import { AlertType } from '../../shared/Alert';
import { Header2 } from '../../shared/Headers';
import { LargeLoadingIndicator } from '../../shared/LargeLoadingIndicator';
import { AssertUserHasPermission } from '../../shared/permission/AssertUserHasPermission';
import {
  AlertIfRequiredByLocationState,
  SingleUseAlertIfRequiredByLocation,
} from '../../shared/SingleUseAlertIfRequiredByLocation';
import { CourseForm } from '../../shared/course/CourseForm';

export const CreateCourse = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [employeeList, setEmployeeList] = useState<EmployeeListDto | null>(null);

  const { fetchEmployees } = useUserApi();

  const history = useHistory<AlertIfRequiredByLocationState>();
  const { createCourse } = useCourseApi();

  useEffect(() => {
    setIsLoading(true);
    fetchEmployees()
      .then((res: EmployeeListDto | FetchJsonError) => {
        if (!isFetchJsonError(res)) {
          setEmployeeList(res);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [fetchEmployees]);

  if (isLoading || employeeList == null) {
    return <LargeLoadingIndicator />;
  }

  const onFormSubmit = (updateCourseDto: CreateUpdateCourseDto) => {
    createCourse(updateCourseDto).then((res) => {
      if (isFetchJsonError(res)) {
        history.push({
          pathname: `/course/create`,
          state: {
            alert: {
              title: 'Error',
              children: 'An error occurred whilst saving your changes',
              type: AlertType.Error,
              closeable: true,
            },
          },
        });
      } else {
        history.push({
          pathname: `/course/${res.id}/overview`,
          state: {
            alert: {
              title: 'Success',
              children: 'Your changes were saved successfully',
              type: AlertType.Success,
              closeable: true,
            },
          },
        });
      }
    });
  };

  return (
    <>
      <SingleUseAlertIfRequiredByLocation />
      <AssertUserHasPermission permission={'CreateEditCourses'}>
        <Header2>Add New Training Course</Header2>
        <CourseForm onSubmit={onFormSubmit} activeEmployees={employeeList.activeEmployees} />
      </AssertUserHasPermission>
    </>
  );
};
