import React from 'react';
import { useParams } from 'react-router-dom';
import { useWorkspaceAssessmentApi } from '../../hooks/useWorkspaceAssessmentApi';
import { AddAssessmentForm } from './AddAssessmentForm';

export type AddHotDeskAssessmentParams = {
  id: string;
};

export const AddHotDeskAssessment = () => {
  const { createHotDeskWorkspaceAssessment } = useWorkspaceAssessmentApi();
  const { id } = useParams<AddHotDeskAssessmentParams>();

  return (
    <AddAssessmentForm deskOrUserId={Number(id)} onSubmit={createHotDeskWorkspaceAssessment} />
  );
};
