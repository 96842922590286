import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import * as yup from 'yup';
import { useDeskApi } from '../../hooks/useDeskApi';
import { CreateDeskDto } from '../../models/desk';
import { Alert, AlertType } from '../../shared/Alert';
import { ButtonRow, SecondaryButton, SubmitButton } from '../../shared/Button';
import { TextInput } from '../../shared/form/TextInput';
import { Header3 } from '../../shared/Headers';
import { Modal } from '../../shared/Modal';
import { spacingS, spacingXS } from '../../styling/spacing';

interface CompleteNewDeskFormModel {
  Name: string;
}

const maxNameLength = 30;
const newDeskValidationSchema = yup.object().shape({
  Name: yup.string().max(maxNameLength).trim().min(1).required('Your new desk must have a name'),
});

const addDesk = (formModel: CompleteNewDeskFormModel): CreateDeskDto => ({
  name: formModel.Name,
});

const initialValues: CompleteNewDeskFormModel = {
  Name: '',
};

type CreateDeskModalProps = {
  closeModal: () => void;
  isOpen: boolean;
  refreshDeskList: () => void;
  isMovingFromDeskId: number | null;
};
export const testIdMoveDesksButton = 'create-or-move-desk-modal';

export const CreateOrMoveDeskModal = ({
  closeModal,
  isOpen,
  refreshDeskList,
  isMovingFromDeskId,
}: CreateDeskModalProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const history = useHistory();

  const { createDesk, moveDesk } = useDeskApi();

  const submitNewDeskForm = (addNewDeskForm: CompleteNewDeskFormModel) => {
    setIsSaving(true);

    if (isMovingFromDeskId !== null) {
      moveDesk(isMovingFromDeskId, addDesk(addNewDeskForm)).then((response) => {
        setIsSaving(false);
        const successfullyMovedDesk = response === null;
        closeModal();
        refreshDeskList();

        history.replace({
          state: {
            alert: {
              title: successfullyMovedDesk ? 'Success' : 'Error',
              children: successfullyMovedDesk
                ? 'You have successfully moved desks, now create a workstation assessment for your new desk'
                : response !== null
                ? response.userVisibleErrorMessage
                : 'An error occurred adding your desk',
              type: successfullyMovedDesk ? AlertType.Success : AlertType.Error,
              closeable: true,
            },
          },
        });
      });
    } else {
      createDesk(userId, addDesk(addNewDeskForm)).then((response) => {
        setIsSaving(false);
        const successfullyAddedNewDesk = response === null;
        closeModal();
        refreshDeskList();

        history.replace({
          state: {
            alert: {
              title: successfullyAddedNewDesk ? 'Success' : 'Error',
              children: successfullyAddedNewDesk
                ? 'New desk added, now complete a workstation assessment for your desk'
                : response !== null
                ? response.userVisibleErrorMessage
                : 'An error occurred adding your desk',
              type: successfullyAddedNewDesk ? AlertType.Success : AlertType.Error,
              closeable: true,
            },
          },
        });
      });
    }
  };

  const urlString = useLocation().pathname.toString();
  const splitString = urlString.split('/', 4);
  const userId = parseInt(splitString[2]);

  return (
    <Modal isOpen={isOpen} contentLabel="make a desk modal" onRequestClose={closeModal}>
      <Formik
        onSubmit={submitNewDeskForm}
        initialValues={initialValues}
        validationSchema={newDeskValidationSchema}
      >
        {(props) => {
          return (
            <StyledForm>
              <Header3>{isMovingFromDeskId === null ? 'Create Desk' : 'Move Desks'}</Header3>
              <TextInputContainer>
                <NewDeskInput
                  name="Name"
                  value={props.values.Name}
                  label={'Name'}
                  autoFocus
                  onBlur={props.handleBlur}
                  onChange={props.handleChange}
                  disabled={isSaving}
                  placeholder="New desk name"
                  type="text"
                />
              </TextInputContainer>
              {isMovingFromDeskId !== null && (
                <WarningContainer>
                  <Alert title={'Warning'} type={AlertType.Warning}>
                    <p>
                      Moving desks will archive your old desk, are you sure you want to continue?
                    </p>
                  </Alert>
                </WarningContainer>
              )}
              <ButtonRow>
                <CancelButton disabled={isSaving} onClick={closeModal}>
                  Cancel
                </CancelButton>
                {isMovingFromDeskId !== null ? (
                  <StyledSubmitButton isSubmitting={isSaving} data-testid={testIdMoveDesksButton}>
                    Move Desks
                  </StyledSubmitButton>
                ) : (
                  <StyledSubmitButton isSubmitting={isSaving}>Add Desk</StyledSubmitButton>
                )}
              </ButtonRow>
            </StyledForm>
          );
        }}
      </Formik>
    </Modal>
  );
};

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  margin: ${spacingS};
`;

const StyledSubmitButton = styled(SubmitButton)`
  margin: 0;
`;

const CancelButton = styled(SecondaryButton)`
  margin: 0;
`;

const NewDeskInput = styled(TextInput)`
  top: ${spacingS};
  margin-right: ${spacingS}
  justify-content: center;
`;

const TextInputContainer = styled.div`
  margin-top: ${spacingXS};
  width: 600px;
  min-height: 120px;
`;

const WarningContainer = styled.div`
  width: 600px;
`;
