import { useCallback, useContext } from 'react';
import {
  createRequestInit,
  fetchJObjectURL,
  fetchJson,
  FetchJsonError,
  from,
  HttpMethod,
  withBody,
} from '../api';
import { AuthContext } from '../shared/auth/AuthContext';

export type OptionalBodyArgument<TRequest> = TRequest extends never ? [never?] : [TRequest];
export const useAuthorizedFetchJson = <TResponse, TRequest = never>(
  method: HttpMethod,
): ((
  path: string,
  ...[requestBody]: OptionalBodyArgument<TRequest>
) => Promise<FetchJsonError | TResponse>) => {
  const { acquireAccessToken } = useContext(AuthContext);

  return useCallback(
    async (path: string, ...[requestBody]: OptionalBodyArgument<TRequest>) =>
      await fetchJson<TResponse>(
        from(path),
        withBody(createRequestInit(method, await acquireAccessToken()), requestBody),
      ),
    [acquireAccessToken, method],
  );
};

export const useAuthorizedFetchJObjectURL = <TRequest = never>(
  method: HttpMethod,
): ((
  path: string,
  ...[requestBody]: OptionalBodyArgument<TRequest>
) => Promise<FetchJsonError | string>) => {
  const { acquireAccessToken } = useContext(AuthContext);

  return useCallback(
    async (path: string, ...[requestBody]: OptionalBodyArgument<TRequest>) => {
      return await fetchJObjectURL(
        from(path),
        withBody(createRequestInit(method, await acquireAccessToken()), requestBody),
      );
    },
    [acquireAccessToken, method],
  );
};
