import { useCallback } from 'react';
import { isFetchJsonError } from '../api';
import { HotDesksOverviewDto, HotDeskUserStatusDto } from '../models/hot-desk';
import { CreateUpdateUserDto, EmployeeListDto, EmployeeTreeDto, UserDto } from '../models/user';
import { useAuthorizedFetchJObjectURL, useAuthorizedFetchJson } from './useAuthorizedFetch';

export const useUserApi = () => {
  const fetchEmployeesAuthorizedFetch = useAuthorizedFetchJson<EmployeeListDto>('GET');
  const fetchEmployees = useCallback(() => fetchEmployeesAuthorizedFetch(`user/employee-list`), [
    fetchEmployeesAuthorizedFetch,
  ]);

  const fetchEmployeeTreeAuthorizedFetch = useAuthorizedFetchJson<EmployeeTreeDto>('GET');
  const fetchEmployeeTree = useCallback(
    () => fetchEmployeeTreeAuthorizedFetch(`user/employee-tree`),
    [fetchEmployeeTreeAuthorizedFetch],
  );

  const fetchCurrentUserAuthorizedFetch = useAuthorizedFetchJson<UserDto>('GET');
  const fetchCurrentUser = useCallback(() => fetchCurrentUserAuthorizedFetch(`user/me`), [
    fetchCurrentUserAuthorizedFetch,
  ]);

  const fetchUserAuthorizedFetch = useAuthorizedFetchJson<UserDto>('GET');
  const fetchUser = useCallback((userId: string) => fetchUserAuthorizedFetch(`user/${userId}`), [
    fetchUserAuthorizedFetch,
  ]);

  const fetchUserImageAuthorizedFetch = useAuthorizedFetchJson<string>('GET');
  const fetchUserImage = useCallback(
    (azureUserId: string) =>
      fetchUserImageAuthorizedFetch(`user/image/${azureUserId}`).then((res) =>
        isFetchJsonError(res) ? '' : res,
      ),
    [fetchUserImageAuthorizedFetch],
  );

  const createUserAuthorizedFetch = useAuthorizedFetchJson<UserDto, CreateUpdateUserDto>('POST');
  const createUser = useCallback(
    (createUpdateUserDto: CreateUpdateUserDto) =>
      createUserAuthorizedFetch(`user/create`, createUpdateUserDto),
    [createUserAuthorizedFetch],
  );

  const updateUserAuthorizedFetch = useAuthorizedFetchJson<UserDto, CreateUpdateUserDto>('POST');
  const updateUser = useCallback(
    (userId: string, createUpdateUserDto: CreateUpdateUserDto) =>
      updateUserAuthorizedFetch(`user/${userId}/update`, createUpdateUserDto),
    [updateUserAuthorizedFetch],
  );

  const deleteUserAuthorizedFetch = useAuthorizedFetchJson<undefined>('DELETE');
  const deleteUser = useCallback((userId: string) => deleteUserAuthorizedFetch(`user/${userId}`), [
    deleteUserAuthorizedFetch,
  ]);

  const exportUsersAuthorizedFetch = useAuthorizedFetchJObjectURL('GET');
  const exportUsers = useCallback(
    () =>
      exportUsersAuthorizedFetch('user/export').then((res) => {
        if (!isFetchJsonError(res)) {
          window.location.assign(res);
        }
      }),
    [exportUsersAuthorizedFetch],
  );

  const fetchUserHotDeskStatusAuthorizedFetch = useAuthorizedFetchJson<HotDeskUserStatusDto>('GET');
  const fetchUserHotDeskStatus = useCallback(
    () => fetchUserHotDeskStatusAuthorizedFetch(`user/me/hot-desk`),
    [fetchUserHotDeskStatusAuthorizedFetch],
  );

  const fetchHotDesksOverviewAuthorizedFetch = useAuthorizedFetchJson<HotDesksOverviewDto>('GET');
  const fetchHotDesksOverview = useCallback(
    () => fetchHotDesksOverviewAuthorizedFetch(`user/hot-desks-overview`),
    [fetchHotDesksOverviewAuthorizedFetch],
  );

  return {
    fetchEmployees,
    fetchEmployeeTree,
    fetchCurrentUser,
    fetchUser,
    fetchUserImage,
    createUser,
    updateUser,
    deleteUser,
    exportUsers,
    fetchUserHotDeskStatus,
    fetchHotDesksOverview,
  };
};
