import { useField, useFormikContext } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import {
  midnight,
  midnightLightTint,
  midnightMediumTint,
  olaf,
  peacockLightTint,
  peacockMediumTint,
  revolver,
} from '../../styling/colours';
import { shadowHeavy } from '../../styling/shadows';
import { spacingM, spacingXS, spacingXXS, spacingXXXS } from '../../styling/spacing';
import { PrimaryButton, PrimaryIconButton } from '../Button';
import { ReactComponent as ChevronDown } from '../icons/chevron-down-solid.svg';
import { ReactComponent as ChevronUp } from '../icons/chevron-up-solid.svg';
import { ReactComponent as UncheckedSquare } from '../icons/square.svg';
import { ReactComponent as CheckedSquare } from '../icons/check-square.svg';
import { ReactComponent as Close } from '../icons/close-icon.svg';

export type SelectDropDownOptionValues<T> = {
  label: string;
  value: number;
} & T;

interface MultiSelectFieldProps<T> {
  name: string;
  options: Array<SelectDropDownOptionValues<T>>;
  defaultOptionValues?: Array<number>;
  SelectedOptionComponent?: React.ComponentType<SelectDropDownOptionValues<T>>;
  DropDownOptionComponent?: React.ComponentType<SelectDropDownOptionValues<T>>;
  onOptionSelected?: (options: Array<SelectDropDownOptionValues<T>>) => void;
  maxNumberOfSelectedOptionsDisplayed?: number;
}

const DefaultSelectDropDownOptionComponent = <T extends Record<string, unknown>>({
  label,
}: SelectDropDownOptionValues<T>) => {
  return <p>{label}</p>;
};

const DefaultSelectedOptionComponent = <T extends Record<string, unknown>>({
  label,
}: SelectDropDownOptionValues<T>) => {
  return <p>{label}</p>;
};

export const MultiSelectField = <T extends Record<string, unknown>>({
  options,
  defaultOptionValues = [],
  SelectedOptionComponent = DefaultSelectedOptionComponent,
  DropDownOptionComponent = DefaultSelectDropDownOptionComponent,
  onOptionSelected = () => {
    return;
  },
  maxNumberOfSelectedOptionsDisplayed = 2,
  ...props
}: MultiSelectFieldProps<T>) => {
  const [currentSelectedOptions, setCurrentSelectedOptions] = useState<
    Array<SelectDropDownOptionValues<T>>
  >([]);
  const [isOptionsListHidden, setIsOptionsListHidden] = useState<boolean>(true);
  const [filteredOptions, setFilteredOptions] = useState<Array<SelectDropDownOptionValues<T>>>([]);
  const [dropDownIndex, setDropDownIndex] = useState<number | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const dropDownOptionRefs = useRef<(HTMLLIElement | null)[]>([]);

  const [field] = useField(props);
  const form = useFormikContext();

  useEffect(() => {
    setFilteredOptions(options);
    const newSelectedOptions: Array<SelectDropDownOptionValues<T>> = [];
    options.forEach((option) => {
      if (defaultOptionValues.find((value) => value === option.value)) {
        newSelectedOptions.push(option);
      }
    });
    setCurrentSelectedOptions(newSelectedOptions);
    dropDownOptionRefs.current = dropDownOptionRefs.current.slice(0, options.length);
  }, [defaultOptionValues, options]);

  useEffect(() => {
    const newFilteredOptions = options.filter(
      (option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1,
    );
    setDropDownIndex(0);
    setFilteredOptions(newFilteredOptions);
  }, [options, inputValue]);

  useEffect(() => {
    const optionElement = dropDownOptionRefs.current[dropDownIndex || 0];
    if (optionElement) {
      optionElement.scrollIntoView({ block: 'nearest' });
    }
  }, [dropDownIndex]);

  const isSelectingAll = filteredOptions.length === currentSelectedOptions.length;

  const handleSelectAllButtonPressed = () => {
    isSelectingAll ? deselectAllOptions() : selectAllOptions();
  };

  const selectAllOptions = () => {
    const newSelectedOptions = options.slice();
    updateCurrentSelectedOptions(newSelectedOptions);
  };

  const deselectAllOptions = () => {
    const newSelectedOptions: Array<SelectDropDownOptionValues<T>> = [];
    updateCurrentSelectedOptions(newSelectedOptions);
  };

  const handleSelectedOptionRemoveButtonPressed = (option: SelectDropDownOptionValues<T>) => {
    if (currentSelectedOptions.find((selectedOption) => option.value === selectedOption.value)) {
      toggleOptionSelected(option);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && inputValue.length === 0) {
      deleteLastSelectedOption();
    } else if (event.key === 'ArrowUp') {
      if (isOptionsListHidden) {
        setIsOptionsListHidden(false);
        setDropDownIndex(filteredOptions.length - 1);
      } else {
        let newIndex: number;
        if (dropDownIndex == null || dropDownIndex === 0) {
          newIndex = filteredOptions.length - 1;
        } else {
          newIndex = dropDownIndex - 1;
        }
        setDropDownIndex(newIndex);
      }
    } else if (event.key === 'ArrowDown') {
      if (isOptionsListHidden) {
        setIsOptionsListHidden(false);
        setDropDownIndex(0);
      } else {
        let newIndex: number;
        if (dropDownIndex == null || dropDownIndex === filteredOptions.length - 1) {
          newIndex = 0;
        } else {
          newIndex = dropDownIndex + 1;
        }
        setDropDownIndex(newIndex);
      }
    } else if (event.key === 'Enter') {
      if (dropDownIndex != null && !isOptionsListHidden) {
        toggleOptionSelected(filteredOptions[dropDownIndex]);
      }
      setInputValue('');
      event.preventDefault();
    } else if (event.key === 'Escape') {
      setIsOptionsListHidden(true);
    }
  };

  const toggleOptionSelected = (option: SelectDropDownOptionValues<T>) => {
    const newSelectedOptions = currentSelectedOptions.slice();
    const index = currentSelectedOptions.findIndex((o) => option.value === o.value);

    if (index === -1) {
      newSelectedOptions.push(option);
    } else {
      newSelectedOptions.splice(index, 1);
    }

    updateCurrentSelectedOptions(newSelectedOptions);
  };

  const deleteLastSelectedOption = () => {
    const newSelectedOptions = currentSelectedOptions.slice();
    newSelectedOptions.pop();
    updateCurrentSelectedOptions(newSelectedOptions);
  };

  const updateCurrentSelectedOptions = (
    newSelectedOptions: Array<SelectDropDownOptionValues<T>>,
  ) => {
    setCurrentSelectedOptions(newSelectedOptions);
    form.setFieldValue(
      field.name,
      newSelectedOptions.map((option) => option.value),
    );
    onOptionSelected(newSelectedOptions);
  };

  const handleOptionClick = (option: SelectDropDownOptionValues<T>, index: number) => {
    setDropDownIndex(index);
    toggleOptionSelected(option);
  };

  const handleInputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    setIsOptionsListHidden(false);
  };

  const handleControlSelected = () => {
    if (inputRef.current != null) {
      inputRef.current.focus();
    }
  };

  const numberOfHiddenSelectedOptions =
    currentSelectedOptions.length - maxNumberOfSelectedOptionsDisplayed;

  return (
    <SelectDropDownContainer>
      <ControlContainer onClick={() => handleControlSelected()}>
        <SelectedOptionsContainer>
          {numberOfHiddenSelectedOptions > 0 && (
            <>
              <OverflowOptionsText data-tip={'React-tooltip'}>
                {numberOfHiddenSelectedOptions} more +
              </OverflowOptionsText>
              <ReactTooltip place={'top'} effect={'solid'}>
                <ControlInfoTooltipText>
                  {currentSelectedOptions
                    .slice(0, numberOfHiddenSelectedOptions)
                    .map((option, index) => {
                      let newString = option.label;
                      if (index < numberOfHiddenSelectedOptions - 1) {
                        newString += ', ';
                      }
                      return (
                        <>
                          {newString}
                          {index % 3 === 0 && index !== 0 && <br />}
                        </>
                      );
                    })}
                </ControlInfoTooltipText>
              </ReactTooltip>
            </>
          )}
          {currentSelectedOptions.slice(numberOfHiddenSelectedOptions).map((option) => (
            <SelectedOption key={option.value}>
              <SelectedOptionComponentContainer>
                <SelectedOptionComponent {...option} />
              </SelectedOptionComponentContainer>
              <SelectedOptionRemoveButton
                onClick={() => handleSelectedOptionRemoveButtonPressed(option)}
              >
                <CloseIcon />
              </SelectedOptionRemoveButton>
            </SelectedOption>
          ))}
          <SelectedOptionsInput
            ref={inputRef}
            value={inputValue}
            onChange={(event) => handleInputChanged(event)}
            onKeyDown={(event) => handleKeyDown(event)}
          />
        </SelectedOptionsContainer>
        <DropDownButton onClick={() => setIsOptionsListHidden(!isOptionsListHidden)}>
          {isOptionsListHidden ? <ChevronDownIcon /> : <ChevronUpIcon />}
        </DropDownButton>
      </ControlContainer>
      {!isOptionsListHidden && (
        <OptionsContainer>
          <SelectAllButton onClick={() => handleSelectAllButtonPressed()}>
            {isSelectingAll ? <CheckedSquareIcon /> : <UncheckedSquareIcon />}
            Select All
          </SelectAllButton>
          <OptionsList>
            {filteredOptions.map((optionValues, i) => {
              const isSelected =
                currentSelectedOptions.find((o) => o.value === optionValues.value) !== undefined;
              return (
                <DropDownOption
                  key={optionValues.value}
                  ref={(el) => (dropDownOptionRefs.current[i] = el)}
                  onClick={() => handleOptionClick(optionValues, i)}
                  isSelected={isSelected}
                  isFocused={
                    dropDownIndex != null
                      ? filteredOptions[dropDownIndex].value === optionValues.value
                      : false
                  }
                >
                  {isSelected ? <CheckedSquareIcon /> : <UncheckedSquareIcon />}
                  <DropDownOptionComponent {...optionValues} />
                </DropDownOption>
              );
            })}
          </OptionsList>
        </OptionsContainer>
      )}
    </SelectDropDownContainer>
  );
};

const SelectDropDownContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 54px;
`;

const SelectedOptionsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-grow: 1;
`;

const SelectedOptionsInput = styled.input`
  border: none;
  flex-grow: 1;
  margin: 0 ${spacingXS};

  &:focus {
    outline: none;
  }
`;

const SelectedOption = styled.div`
  display: flex;
  border: 1px solid ${midnightLightTint};
  border-radius: ${spacingXXXS};
  overflow: hidden;
  margin-right: ${spacingXXXS};
`;

const SelectedOptionComponentContainer = styled.div`
  padding: ${spacingXXS};
`;

const OverflowOptionsText = styled.span`
  display: flex;
  align-items: center;
  margin: 0 ${spacingXS};
`;

const ControlInfoTooltipText = styled.p`
  text-align: center;
`;

const SelectedOptionRemoveButton = styled(PrimaryIconButton)`
  color: ${midnight};
  background-color: ${olaf};
  margin-left: ${spacingXXS};
  height: 100%;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;

  &:hover {
    background-color: ${revolver};
  }
`;

const DropDownButton = styled(PrimaryIconButton)`
  justify-content: flex-end;
  margin-left: auto;
  margin-right: 0;
  color: ${midnight};
  background-color: ${olaf};
  border-radius: ${spacingXXS};

  &:hover {
    background-color: ${revolver};
  }
`;

const OptionsContainer = styled.div`
  opacity: 1;
  z-index: 1;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 100%;
  width: 100%;
  border-radius: ${spacingXXXS};
  border: 1px solid ${midnightLightTint};
  box-shadow: ${shadowHeavy};
  background-color: ${olaf};
`;

const OptionsList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  overflow-y: scroll;
  height: 300px;
  resize: vertical;
`;

const ControlContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  border: 1px solid ${midnightLightTint};
  border-radius: ${spacingXXXS};
  padding: ${spacingXXXS};
  min-height: ${spacingM};

  &:hover {
    cursor: text;
  }
`;

const DropDownOption = styled.li<{ isSelected?: boolean; isFocused?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: ${spacingXXS};

  background-color: ${(props) => (props.isSelected ? peacockMediumTint : 'none')};
  background-color: ${(props) => (props.isFocused ? peacockLightTint : 'none')};

  &:hover {
    background-color: ${peacockLightTint};
    cursor: pointer;
  }
`;

const SelectAllButton = styled(PrimaryButton)`
  background-color: ${olaf};
  color: ${midnightMediumTint};
  text-align: start;
  width: 100%;
  border-radius: 0;
  padding-left: ${spacingXXS};

  &:hover {
    background-color: ${revolver};
  }
`;

const closeIconSize = '16px';

const CloseIcon = styled(Close)`
  width: ${closeIconSize};
  height: ${closeIconSize};
`;

const iconSize = '20px';

const ChevronDownIcon = styled(ChevronDown)`
  width: ${iconSize};
  height: ${iconSize};
`;

const ChevronUpIcon = styled(ChevronUp)`
  width: ${iconSize};
  height: ${iconSize};
`;

const UncheckedSquareIcon = styled(UncheckedSquare)`
  display: inline;
  width: ${iconSize};
  height: ${iconSize};
  color: ${midnightMediumTint};
  vertical-align: middle;
  padding-right: ${spacingXXXS};
  margin-right: ${spacingXXS};
`;

const CheckedSquareIcon = styled(CheckedSquare)`
  display: inline;
  width: ${iconSize};
  height: ${iconSize};
  color: ${midnightMediumTint};
  vertical-align: middle;
  padding-right: ${spacingXXXS};
  margin-right: ${spacingXXS};
`;
