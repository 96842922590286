import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { Config } from '../../models/config';

export const createAuthProvider = async (MsalConfig: Config) => {
  const config: Configuration = {
    auth: {
      authority: MsalConfig.authority,
      clientId: MsalConfig.clientId,
      redirectUri: MsalConfig.redirectUri,
      navigateToLoginRequestUrl: false,
    },
  };
  return await PublicClientApplication.createPublicClientApplication(config);
};
