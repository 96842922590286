import React from 'react';
import styled from 'styled-components/macro';
import { TableItem } from '../../shared/Table';
import { alertText } from '../../styling/colours';

type DateTableItemProps = {
  dateString: string | undefined | null;
};

export const DateTableItem = ({ dateString }: DateTableItemProps) => {
  if (dateString == null) {
    return <TableItem>N/A</TableItem>;
  }
  const date = new Date(dateString);
  return date >= new Date() ? (
    <TableItem>{dateString}</TableItem>
  ) : (
    <ExpiredDateTableItem>Expired {dateString}</ExpiredDateTableItem>
  );
};

const ExpiredDateTableItem = styled(TableItem)`
  color: ${alertText};
`;
