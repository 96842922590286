import styled from 'styled-components/macro';
import {
  fontHeading1,
  fontHeading2,
  fontHeading3,
  fontHeading4,
  fontHeading5,
  fontHeading6,
} from '../styling/fonts';
import { spacingXS } from '../styling/spacing';

export const Header1 = styled.h1`
  font: ${fontHeading1};
  margin-bottom: ${spacingXS};
`;
export const Header2 = styled.h2`
  font: ${fontHeading2};
`;

export const Header3 = styled.h3`
  font: ${fontHeading3};
`;

export const Header4 = styled.h4`
  font: ${fontHeading4};
`;

export const Header5 = styled.h5`
  font: ${fontHeading5};
`;

export const Header6 = styled.h6`
  font: ${fontHeading6};
`;
