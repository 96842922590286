import React, { useContext } from 'react';
import { Permission } from '../../models/permission';
import { UserHasPermission } from '../../models/user';
import { CurrentUserContext } from '../user/CurrentUserContext';

interface Props {
  children: React.ReactNode;
  permission: Permission;
}

export const IfUserHasPermission: React.FC<Props> = ({ children, permission }: Props) => {
  const { currentUser } = useContext(CurrentUserContext);

  return UserHasPermission(currentUser, permission) ? <>{children}</> : null;
};
