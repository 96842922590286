import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useWorkspaceAssessmentApi } from '../../hooks/useWorkspaceAssessmentApi';
import { Alert, AlertType } from '../../shared/Alert';
import { LargeLoadingIndicator } from '../../shared/LargeLoadingIndicator';

export type VerifyAssessmentParams = {
  id: string;
};

export const VerifyAssessment = () => {
  const { id: assessmentId } = useParams<VerifyAssessmentParams>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState<boolean>(false);

  const { verifyWorkspaceAssessment } = useWorkspaceAssessmentApi();

  useEffect(() => {
    setIsLoading(true);

    verifyWorkspaceAssessment(assessmentId)
      .then((requestSuccessful: boolean) => {
        setIsError(!requestSuccessful);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [assessmentId, verifyWorkspaceAssessment]);

  return (
    <>
      {isLoading ? (
        <LargeLoadingIndicator />
      ) : (
        <>
          {isError ? (
            <Alert title={'Error'} type={AlertType.Error}>
              There was an error trying to update your workstation assessment. Please contact
              Helpdesk at team-helpdesk@ghyston.com
            </Alert>
          ) : (
            <Alert title={'Thank you!'} type={AlertType.Success}>
              You're all up to date. Remember to update your workstation assessment if anything
              changes!
            </Alert>
          )}
        </>
      )}
    </>
  );
};
