import React, { Context, createContext, useEffect, useState } from 'react';
import { FetchJsonError, isFetchJsonError } from '../../api';
import { Config, fetchConfig } from '../../models/config';
import { LargeLoadingIndicator } from '../LargeLoadingIndicator';

export type ConfigContextValue = {
  config: Config;
};

export const ConfigContext: Context<ConfigContextValue> = createContext<ConfigContextValue>({
  config: {} as Config,
});

type Props = {
  children?: React.ReactNode;
};

export const ConfigContextProvider = (props: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [config, setConfig] = useState<Config | null>(null);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    refreshConfigData();
  }, []);

  const refreshConfigData = () => {
    setError(undefined);
    setIsLoading(true);
    fetchConfig().then((res: Config | FetchJsonError) => {
      if (isFetchJsonError(res)) {
        setError(res.userVisibleErrorMessage ?? 'An error has occured'); // TODO: don't rely on ?? to pass tests
        setIsLoading(false);
      } else {
        setConfig(res);
        setIsLoading(false);
      }
    });
  };

  if (error) {
    return (
      <div>
        <p>There was an error loading the site, please press the button below to try again.</p>
        <button onClick={refreshConfigData}>Retry</button>
      </div>
    );
  }

  if (isLoading || config == null) {
    return <LargeLoadingIndicator />;
  }

  return <ConfigContext.Provider value={{ config }}>{props.children}</ConfigContext.Provider>;
};
