import { useCallback } from 'react';
import {
  EditTrainingRecordDto,
  TrainingRecordListDto,
  TrainingRecordDto,
  EditMultipleTrainingRecordsDto,
  GetLatestTrainingRecordsByCourseIdDto,
  CreateTrainingRecordDto,
  CreateAndApproveMultipleTrainingRecordsDto,
  TrainingRecordsDto,
} from '../models/training-record';
import { useAuthorizedFetchJson } from './useAuthorizedFetch';

export const useTrainingRecordApi = () => {
  const getTrainingRecordAuthorizedFetch = useAuthorizedFetchJson<TrainingRecordDto>('GET');
  const getTrainingRecord = useCallback(
    (trainingRecordId: string) =>
      getTrainingRecordAuthorizedFetch(`trainingrecord/${trainingRecordId}`),
    [getTrainingRecordAuthorizedFetch],
  );

  const getTrainingRecordsByUserAuthorizedFetch = useAuthorizedFetchJson<TrainingRecordListDto>(
    'GET',
  );
  const getTrainingRecordsByUser = useCallback(
    (userId: string) => getTrainingRecordsByUserAuthorizedFetch(`trainingrecord/user/${userId}`),
    [getTrainingRecordsByUserAuthorizedFetch],
  );

  const getTrainingRecordsForCourseIdAuthorizedFetch = useAuthorizedFetchJson<TrainingRecordsDto>(
    'GET',
  );
  const getTrainingRecordsForCourseId = useCallback(
    (courseId: string) =>
      getTrainingRecordsForCourseIdAuthorizedFetch(`trainingrecord/course/${courseId}`),
    [getTrainingRecordsForCourseIdAuthorizedFetch],
  );

  const getLatestTrainingRecordsPerActiveUserFromCourseIdAuthorizedFetch = useAuthorizedFetchJson<
    GetLatestTrainingRecordsByCourseIdDto
  >('GET');
  const getLatestTrainingRecordsPerActiveUserFromCourseId = useCallback(
    (courseId: string) =>
      getLatestTrainingRecordsPerActiveUserFromCourseIdAuthorizedFetch(
        `trainingrecord/course/${courseId}/latest`,
      ),
    [getLatestTrainingRecordsPerActiveUserFromCourseIdAuthorizedFetch],
  );

  const createTrainingRecordAuthorizedFetch = useAuthorizedFetchJson<
    TrainingRecordDto,
    CreateTrainingRecordDto
  >('POST');
  const createTrainingRecord = useCallback(
    (createTrainingRecordDto: CreateTrainingRecordDto, userId: string) =>
      createTrainingRecordAuthorizedFetch(
        `trainingrecord/user/${userId}/training-record`,
        createTrainingRecordDto,
      ),
    [createTrainingRecordAuthorizedFetch],
  );

  const createMultipleTrainingRecordsForCourseIdAuthorizedFetch = useAuthorizedFetchJson<
    CreateAndApproveMultipleTrainingRecordsDto,
    CreateAndApproveMultipleTrainingRecordsDto
  >('POST');
  const createMultipleTrainingRecordsForCourseId = useCallback(
    (
      createAndApproveMultipleTrainingRecordsDto: CreateAndApproveMultipleTrainingRecordsDto,
      courseId: string,
    ) =>
      createMultipleTrainingRecordsForCourseIdAuthorizedFetch(
        `trainingrecord/course/${courseId}/approve`,
        createAndApproveMultipleTrainingRecordsDto,
      ),
    [createMultipleTrainingRecordsForCourseIdAuthorizedFetch],
  );

  const editTrainingRecordAuthorizedFetch = useAuthorizedFetchJson<
    TrainingRecordDto,
    EditTrainingRecordDto
  >('PUT');
  const editTrainingRecord = useCallback(
    (editTrainingRecordDto: EditTrainingRecordDto, trainingRecordId: string) =>
      editTrainingRecordAuthorizedFetch(
        `trainingrecord/${trainingRecordId}`,
        editTrainingRecordDto,
      ),
    [editTrainingRecordAuthorizedFetch],
  );

  const editMultipleTrainingRecordsAuthorizedFetch = useAuthorizedFetchJson<
    EditMultipleTrainingRecordsDto,
    EditMultipleTrainingRecordsDto
  >('PUT');
  const editMultipleTrainingRecords = useCallback(
    (editMultipleTrainingRecordsDto: EditMultipleTrainingRecordsDto) =>
      editMultipleTrainingRecordsAuthorizedFetch(
        `trainingrecord/edit/multiple`,
        editMultipleTrainingRecordsDto,
      ),
    [editMultipleTrainingRecordsAuthorizedFetch],
  );

  return {
    getTrainingRecord,
    getTrainingRecordsByUser,
    getTrainingRecordsForCourseId,
    getLatestTrainingRecordsPerActiveUserFromCourseId,
    createTrainingRecord,
    editTrainingRecord,
    createMultipleTrainingRecordsForCourseId,
    editMultipleTrainingRecords,
  };
};
