import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useWorkspaceAssessmentApi } from '../../hooks/useWorkspaceAssessmentApi';
import { Alert, AlertType } from '../../shared/Alert';
import { ButtonRow, PrimaryButton, SecondaryButton } from '../../shared/Button';
import { Modal } from '../../shared/Modal';

type CreateHotDeskAssessmentModalProps = {
  closeModal: () => void;
  userId: number;
  isOpen: boolean;
  refreshHotDeskAssessmentList: () => void;
  showWarning: boolean;
};

export const testIdRapidAssessmentWarning = 'rapid-assessment-warning';
export const rapidAssessmentButtonText =
  'I am satisfied with my desk and want to submit a rapid workspace assessment';
export const fullAssessmentButtonText = 'I want to fill out a full assessment';

export const CreateHotDeskAssessmentModal: React.FC<CreateHotDeskAssessmentModalProps> = ({
  closeModal,
  userId,
  isOpen,
  refreshHotDeskAssessmentList,
  showWarning,
}) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const history = useHistory();
  const { createRapidWorkspaceAssessment } = useWorkspaceAssessmentApi();
  const handleCreateRapidAssessment = () => {
    setIsSaving(true);
    createRapidWorkspaceAssessment(userId)
      .then((successfullyCreatedRapidAssessment: boolean) => {
        closeModal();
        refreshHotDeskAssessmentList();
        history.replace({
          state: {
            alert: {
              title: successfullyCreatedRapidAssessment ? 'Success' : 'Error',
              children: successfullyCreatedRapidAssessment
                ? 'Rapid assessment successfully created'
                : 'An error occurred creating the rapid assessment',
              type: successfullyCreatedRapidAssessment ? AlertType.Success : AlertType.Error,
              closeable: true,
            },
          },
        });
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleCreateFullAssessment = () => {
    closeModal();
    history.push(`/assessments/user/${userId}/hot-desk/add-assessment`);
  };

  return (
    <Modal isOpen={isOpen} contentLabel="Create a hot desk assessment modal">
      <ModalContentContainer>
        <AssessmentOptionsContainer>
          <AssessmentOptionButton onClick={handleCreateRapidAssessment}>
            <ButtonText>{rapidAssessmentButtonText}</ButtonText>
          </AssessmentOptionButton>
          <RightAssessmentOptionButton onClick={handleCreateFullAssessment}>
            <ButtonText>{fullAssessmentButtonText}</ButtonText>
          </RightAssessmentOptionButton>
        </AssessmentOptionsContainer>
        {showWarning && (
          <WarningContainer data-testid={testIdRapidAssessmentWarning}>
            <Alert title={'Warning'} type={AlertType.Warning}>
              <p>
                The rapid workspace assessment option is available to you because you have
                self-assigned as having received training. If you have not, please change your
                training status by editing your profile. You <b>must not</b> fill in a rapid
                workspace assessment without first receiving training.
              </p>
            </Alert>
          </WarningContainer>
        )}
        <ButtonRow>
          <CancelButton disabled={isSaving} onClick={closeModal}>
            Cancel
          </CancelButton>
        </ButtonRow>
      </ModalContentContainer>
    </Modal>
  );
};

const ModalContentContainer = styled.div`
  margin: 20px;
`;

const WarningContainer = styled.div`
  width: 600px;
`;

const CancelButton = styled(SecondaryButton)`
  margin: 0;
`;

const AssessmentOptionsContainer = styled.div`
  display: flex;
  align-items: space-between;
  width: 600px;
  margin-bottom: 20px;
`;

const AssessmentOptionButton = styled(PrimaryButton)`
  flex: 1;
  height: 98px;
  margin-right: 4px;
`;

const RightAssessmentOptionButton = styled(PrimaryButton)`
  flex: 1;
  height: 98px;
  margin-left: 4px;
`;

const ButtonText = styled.span`
  padding: 12px;
`;
