import React, { useEffect, useState } from 'react';
import { FetchJsonError, isFetchJsonError } from '../../api';
import { ErrorAlert } from '../error/ErrorAlert';
import { LargeLoadingIndicator } from '../LargeLoadingIndicator';

type Props<TResponse> = {
  request: () => Promise<TResponse | FetchJsonError>;
  children: (response: TResponse) => React.ReactElement;
};

export const GetRequest = <TResponse,>({ request, children }: Props<TResponse>) => {
  const [response, setResponse] = useState<TResponse | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    setIsLoading(true);
    request()
      .then((result) => {
        if (!isFetchJsonError(result)) {
          setResponse(result);
        } else {
          setError(result.userVisibleErrorMessage);
        }
      })
      .finally(() => setIsLoading(false));
  }, [request]);

  if (isLoading || response === null) {
    return <LargeLoadingIndicator />;
  }

  if (error) {
    return <ErrorAlert userVisibleError={error} />;
  }

  return <>{children(response)}</>;
};
