import React from 'react';
import { useParams } from 'react-router-dom';
import { useWorkspaceAssessmentApi } from '../../hooks/useWorkspaceAssessmentApi';
import { ApproveAssessmentParams, ViewAssessment } from './ViewAssessment';

export const ViewHotDeskAssessment = () => {
  const {
    approveHotDeskWorkspaceAssessment,
    fetchHotDeskWorkspaceAssessment,
  } = useWorkspaceAssessmentApi();
  const { id } = useParams<ApproveAssessmentParams>();

  return (
    <ViewAssessment
      fetchWorkspaceAssessment={fetchHotDeskWorkspaceAssessment}
      onSubmitApproval={approveHotDeskWorkspaceAssessment}
      errorPath={`/assessments/hot-desk/${id}`}
      pageTitle="Hot Desk assessment"
    />
  );
};
