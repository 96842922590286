import React from 'react';
import { useField, FieldInputProps, useFormikContext } from 'formik';
import styled from 'styled-components';
import {
  olaf,
  radioSelectedBackground,
  radioSelectedOutline,
  revolver,
} from '../../styling/colours';
import { spacingXXXS } from '../../styling/spacing';
import { FormErrorMessage } from './SharedFormElements';

export const RadioButtonYesNo: React.FC<FieldInputProps<string>> = (props) => {
  const [fieldProps, meta, helpers] = useField(props);
  const form = useFormikContext();

  const setValue = (value: string) => {
    helpers.setValue(value);
  };

  const showError = !!meta.error && (meta.touched || form.submitCount > 0);

  return (
    <RadioButtonYesNoContainer>
      <RadioButtonContainer>
        <YesNoButton
          onClick={() => setValue('true')}
          active={fieldProps.value === 'true'}
          touched={fieldProps.value !== ''}
          type="button"
        >
          Yes
        </YesNoButton>
        <YesNoButton
          onClick={() => setValue('false')}
          active={fieldProps.value === 'false'}
          touched={fieldProps.value !== ''}
          type="button"
        >
          No
        </YesNoButton>
      </RadioButtonContainer>
      {showError && <FormErrorMessage>{meta.error}</FormErrorMessage>}
    </RadioButtonYesNoContainer>
  );
};

const RadioButtonYesNoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const RadioButtonContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const YesNoButton = styled.button<{ active: boolean; touched: boolean }>`
  width: 50%;
  padding: 8px 16px;
  text-align: center;
  cursor: pointer;
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  background-color: ${({ active }) => (active ? radioSelectedBackground : olaf)};
  border: 1px solid;
  border-color: ${({ active }) => (active ? radioSelectedOutline : revolver)};

  &:first-child {
    border-top-left-radius: ${spacingXXXS};
    border-bottom-left-radius: ${spacingXXXS};
    border-right: ${({ active }) => (active ? '1px solid ' + radioSelectedOutline : 'none')};
  }

  &:last-child {
    border-top-right-radius: ${spacingXXXS};
    border-bottom-right-radius: ${spacingXXXS};
    border-left: ${({ active, touched }) =>
      active ? '1px solid ' + radioSelectedOutline : touched ? 'none' : '1px solid ' + revolver};
  }
`;
