import styled, { css, keyframes } from 'styled-components';
import { ReactComponent as Fish } from './icons/fish-solid.svg';

const animation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(720deg); }
`;

export const loadingSpinnerAnimation = css`
  ${animation} 1.5s ease infinite
`;

export const SmallLoadingIndicator = styled(Fish)<{ colour?: string }>`
  animation: ${loadingSpinnerAnimation};
  color: ${(props) => props.colour};

  width: 70px;
  height: 70px;
`;
