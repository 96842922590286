import React from 'react';
import styled from 'styled-components/macro';
import { CourseWithExpiredCountDto } from '../../models/course';
import { BlockLink } from '../../shared/Link';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderItem,
  TableHeaderRow,
  TableItem,
  TableRow,
} from '../../shared/Table';
import { Avatar } from '../../shared/user/avatar/Avatar';
import { loraxShade, midnightMediumTint } from '../../styling/colours';
import { fontSmall } from '../../styling/fonts';
import { spacingM, spacingXS } from '../../styling/spacing';
import { getPathToUser } from '../view-user/ViewUser';

interface AdminCourseTableProps {
  coursesWithExpiredCount: Array<CourseWithExpiredCountDto>;
}

interface CourseReporterProps {
  course: CourseWithExpiredCountDto;
}

export const AdminCourseTable = ({ coursesWithExpiredCount }: AdminCourseTableProps) => (
  <TableContainer>
    <Table>
      <TableHeader>
        <TableHeaderRow>
          <TableHeaderItem>Course name</TableHeaderItem>
          <TableHeaderItem>Reporter</TableHeaderItem>
          <TableHeaderItem>Required for all employees</TableHeaderItem>
          <TableHeaderItem>Default expiry time</TableHeaderItem>
          <TableHeaderItem></TableHeaderItem>
        </TableHeaderRow>
      </TableHeader>
      <TableBody>
        {coursesWithExpiredCount.map((course, index) => (
          <TableRow key={index}>
            <TableItem>
              <BlockLink to={`/course/${course.id}/overview`}>{course.courseName}</BlockLink>
            </TableItem>
            <TableItem>
              <CourseReporters course={course} />
            </TableItem>
            <TableItem>{course.requiredForAllEmployees ? 'Yes' : 'No'}</TableItem>
            <TableItem>{course.defaultExpiryTime}</TableItem>
            {course.expiryCount === 0 ? (
              <TableItemExpiry></TableItemExpiry>
            ) : (
              <TableItemExpiry>{course.expiryCount} expired</TableItemExpiry>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export const CourseReporters = ({ course }: CourseReporterProps) => {
  const displayedReporters = course.reporters.slice(0, 3);

  return (
    <>
      {displayedReporters.map((reporter) => (
        <ReporterNameContainer>
          <AvatarContainer>
            <Avatar name={reporter.name} azureId={reporter.azureId} size="small" />
          </AvatarContainer>
          {reporter.id != null ? (
            <BlockLink to={getPathToUser(reporter.id)}>{reporter.name}</BlockLink>
          ) : (
            reporter.name
          )}
        </ReporterNameContainer>
      ))}
      {course.reporters.length > 3 && (
        <AvatarSubText>+ {course.reporters.length - 3} more</AvatarSubText>
      )}
    </>
  );
};

const TableContainer = styled.div`
  margin-bottom: ${spacingM};
`;

const TableItemExpiry = styled(TableItem)`
  color: ${loraxShade};
`;

const ReporterNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AvatarContainer = styled.div`
  margin-right: ${spacingXS};
  margin-bottom: ${spacingXS};
`;

const AvatarSubText = styled.div`
  color: ${midnightMediumTint};
  font-size: ${fontSmall};
`;
