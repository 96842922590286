import React from 'react';
import { useField, useFormikContext } from 'formik';
import styled from 'styled-components';
import { alertText, revolver } from '../../styling/colours';
import { fontParagraph } from '../../styling/fonts';
import { spacingXS, spacingXXXS } from '../../styling/spacing';
import { FormErrorMessage, FormLabel } from './SharedFormElements';

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  type: 'text' | 'email' | 'tel' | 'url' | 'password';
  label: string;
}

export const TextInput = ({ label, type, ...props }: TextInputProps) => {
  const [field, meta] = useField(props);
  const form = useFormikContext();

  const showError = !!meta.error && (meta.touched || form.submitCount > 0);

  return (
    <TextInputContainer>
      <FormLabel>{label}</FormLabel>
      <Input type={type} showError={showError} {...field} {...props} />
      {showError && <FormErrorMessage>{meta.error}</FormErrorMessage>}
    </TextInputContainer>
  );
};

TextInput.defaultProps = {
  type: 'text',
};

const TextInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Input = styled.input<{ showError: boolean }>`
  width: 100%;
  height: 54px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid ${(props) => (props.showError ? alertText : revolver)};
  border-radius: ${spacingXXXS};
  opacity: 1;

  padding: ${spacingXS};

  font: ${fontParagraph};
  letter-spacing: 0px;
  color: #333f48;
  opacity: 1;
`;
