export const todayIsoDate = () => isoDatePart(new Date().toISOString());
export const yesterdayIsoDate = () => {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  return isoDatePart(date.toISOString());
};
export const tomorrowIsoDate = () => {
  const date = new Date();
  date.setDate(date.getDate() + 1);
  return isoDatePart(date.toISOString());
};

export const maxDate = new Date(8640000000000000);

export const formatDate = (isoDate: string | null) => formatParsedDate(parseDate(isoDate));

export const formatDateTime = (isoDate: string | null) => {
  const date = parseDate(isoDate);
  return date ? `${formatParsedDate(date)} at ${formatParsedTime(date)}` : null;
};

export const isoDatePart = (isoDate: string) => {
  const timeMarkerIndex = isoDate.indexOf('T');
  return timeMarkerIndex > 0 ? isoDate.substr(0, timeMarkerIndex) : isoDate;
};

export const getMinExpiryDateString = (completionDateString: string) => {
  if (completionDateString === '') {
    return '0000-00-00';
  }
  const date = new Date(completionDateString);
  date.setDate(date.getDate() + 1);
  return date.toISOString();
};

export const getTimeStringInYearsMonthsWeeksAndDays = (days: number) => {
  let numberOfDays = days;
  const numberOfYears = Math.floor(numberOfDays / 365);
  numberOfDays %= 365;
  const numberOfMonths = Math.floor(numberOfDays / 30);
  numberOfDays %= 30;
  const numberOfWeeks = Math.floor(numberOfDays / 7);
  numberOfDays %= 7;

  let yearString: string;
  if (numberOfYears > 1) {
    yearString = `${numberOfYears} years `;
  } else {
    yearString = numberOfYears === 1 ? '1 year ' : '';
  }

  let monthString: string;
  if (numberOfMonths > 1) {
    monthString = `${numberOfMonths} months `;
  } else {
    monthString = numberOfMonths === 1 ? '1 month ' : '';
  }

  let weekString: string;
  if (numberOfWeeks > 1) {
    weekString = `${numberOfWeeks} weeks `;
  } else {
    weekString = numberOfWeeks === 1 ? '1 week ' : '';
  }

  let dayString: string;
  if (numberOfDays > 1) {
    dayString = `${numberOfDays} days`;
  } else {
    dayString = numberOfDays === 1 ? '1 day' : '';
  }

  return yearString + monthString + weekString + dayString;
};

const formatParsedDate = (date: Date | null) => date?.toLocaleDateString('en-GB');
const formatParsedTime = (date: Date | null) => date?.toLocaleTimeString('en-GB');

const parseDate = (isoDate: string | null) => (isoDate ? new Date(isoDate) : null);
