import { DeskAssessmentDto } from './desk';
import { UserSummaryDto } from './user';
import { HotDeskAssessmentDto } from './workspace-assessment';

export type HotDesksOverviewDto = {
  hotDeskUsers: Array<HotDeskUserStatusDto>;
  permanentDeskUsers: Array<HotDeskUserStatusDto>;
};

export type HotDeskUserStatusDto = {
  user: UserSummaryDto;
  isHotDeskUser: boolean;
  isRapidWorkspaceAssessmentTrained: boolean;
  isMostRecentHotDeskAssessmentToday: boolean;
};

export type HotDeskAssessmentsSummaryDto = {
  isRapidWorkspaceAssessmentTrained: boolean;
  isCurrentHotDeskUser: boolean;
  isMostRecentAssessmentToday: boolean;
  assessments: Array<HotDeskAssessmentDto>;
};

export const isHotDeskAssessment = (
  assessment: HotDeskAssessmentDto | DeskAssessmentDto,
): assessment is HotDeskAssessmentDto => {
  return (assessment as HotDeskAssessmentDto).isRapidWorkspaceAssessment !== undefined;
};
