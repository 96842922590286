import React from 'react';
import styled, { css, keyframes } from 'styled-components';

interface LoadingIndicatorProps {
  imageWidth: string;
  jumpHeight: string;
  jumpWidth: string;
  animationSpeed: string;
}

export const loadingAltText = 'Loading...';

export const LargeLoadingIndicator = (props: LoadingIndicatorProps) => (
  <LoadingContainer
    imageWidth={props.imageWidth}
    jumpHeight={props.jumpHeight}
    jumpWidth={props.jumpWidth}
  >
    <LoadingImage
      src={process.env.PUBLIC_URL + '/fish-no-bowl.svg'}
      alt={loadingAltText}
      {...props}
    />
  </LoadingContainer>
);

LargeLoadingIndicator.defaultProps = {
  imageWidth: '100px',
  jumpHeight: '50px',
  jumpWidth: '200px',
  animationSpeed: '4s',
};

type LoadingContainerProps = Pick<LoadingIndicatorProps, 'imageWidth' | 'jumpHeight' | 'jumpWidth'>;

const LoadingContainer = styled.div<LoadingContainerProps>`
  width: 100%;
  height: ${(props) => css`calc(${props.imageWidth} + ${props.jumpHeight})`};
  --sidePadding: ${(props) =>
    css`calc(calc(100% - calc(${props.imageWidth} + ${props.jumpWidth})) / 2)`};
  padding: 0 var(--sidePadding) 0 var(--sidePadding);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: fixed;
  left: 0;
  top: 35%;
`;

const loadingAnimation = (jumpHeight: string, jumpWidth: string) => keyframes`
    0%   { transform: scale(1,1)      translateY(0)                                                     rotateY(180deg) ;}
    5%   { transform: scale(1.1,.9)   translateY(0)                                                     rotateY(180deg) ;}
    15%  { transform: scale(.9,1.1)   translateY(-${jumpHeight}) translateX(calc(${jumpWidth} - 10px))  rotateY(180deg) ;}
    25%  { transform: scale(1.05,.95) translateY(0)              translateX(${jumpWidth})               rotateY(180deg) ;}
    30%  { transform: scale(1,1)      translateY(-7px)           translateX(${jumpWidth})               rotateY(180deg) ;}
    33%  { transform: scale(1,1)      translateY(0)              translateX(${jumpWidth})               rotateY(180deg) ;}
    50%  { transform: scale(1,1)      translateY(0)              translateX(${jumpWidth})                               ;}
    55%  { transform: scale(1.1,.9)   translateY(0)              translateX(${jumpWidth})                               ;}
    65%  { transform: scale(.9,1.1)   translateY(-${jumpHeight}) translateX(10px)                                       ;}
    75%  { transform: scale(1.05,.95) translateY(0)                                                                     ;}
    80%  { transform: scale(1,1)      translateY(-7px)                                                                  ;}
    83%  { transform: scale(1,1)      translateY(0)                                                                     ;}
    100% { transform: scale(1,1)      translateY(0)                                                     rotateY(180deg) ;}
  `;

type LoadingImageProps = Pick<
  LoadingIndicatorProps,
  'imageWidth' | 'animationSpeed' | 'jumpHeight' | 'jumpWidth'
>;

const LoadingImage = styled.img<LoadingImageProps>`
  height: ${(props) => props.imageWidth};
  width: ${(props) => props.imageWidth};
  animation: ${(props) => loadingAnimation(props.jumpHeight, props.jumpWidth)}
    ${(props) => props.animationSpeed} ease infinite;
`;
