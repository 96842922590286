import { isEmpty, orderBy } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { FetchJsonError, isFetchJsonError } from '../../api';
import { useCourseApi } from '../../hooks/useCourseApi';
import { useTrainingRecordApi } from '../../hooks/useTrainingRecordApi';
import { CoursesWithExpiredCountDto } from '../../models/course';
import { TrainingRecordByCourseDto, TrainingRecordListDto } from '../../models/training-record';
import { UserHasPermission } from '../../models/user';
import { Header2 } from '../../shared/Headers';
import { LargeLoadingIndicator } from '../../shared/LargeLoadingIndicator';
import { CurrentUserContext } from '../../shared/user/CurrentUserContext';
import { peacock, peacockShade } from '../../styling/colours';
import { fontBase, fontParagraph } from '../../styling/fonts';
import { spacingL, spacingM, spacingS, spacingXS, spacingXXS } from '../../styling/spacing';
import { AdminCourseTable } from './AdminCourseTable';
import { UserCourseTable } from './UserCourseTable';

export type viewUserCoursesParams = {
  id: string;
};

export const ViewUserCourses = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [trainingRecordList, setTrainingRecordList] = useState<TrainingRecordListDto | null>(null);
  const [courses, setCourses] = useState<CoursesWithExpiredCountDto | null>(null);

  const { id } = useParams<viewUserCoursesParams>();
  const { currentUser } = useContext(CurrentUserContext);
  const { getTrainingRecordsByUser } = useTrainingRecordApi();
  const { getAllCoursesWithExpiry } = useCourseApi();

  const refreshTrainingRecordList = useCallback(() => {
    setIsLoading(true);
    getTrainingRecordsByUser(id)
      .then((res: TrainingRecordListDto | FetchJsonError) => {
        if (!isFetchJsonError(res) && !isEmpty(res)) {
          let sortedCourseList = res.courseList.map(
            (course: TrainingRecordByCourseDto): TrainingRecordByCourseDto => ({
              courseName: course.courseName,
              trainingRecords: orderBy(
                course.trainingRecords,
                [
                  (singleCourse) => {
                    return new Date(singleCourse.completionDate);
                  },
                ],
                ['desc'],
              ),
            }),
          );
          sortedCourseList = orderBy(sortedCourseList, ['courseName'], ['asc']);
          const userCourseListDto: TrainingRecordListDto = {
            courseList: sortedCourseList,
          };
          setTrainingRecordList(userCourseListDto);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [getTrainingRecordsByUser, id]);

  const refreshCourseList = useCallback(() => {
    setIsLoading(true);
    getAllCoursesWithExpiry().then((res) => {
      if (!isFetchJsonError(res)) {
        setCourses(res);
        setIsLoading(false);
      } else {
        alert('Failed to get course list');
      }
    });
  }, [getAllCoursesWithExpiry]);

  useEffect(() => {
    setIsLoading(true);
    refreshCourseList();
    refreshTrainingRecordList();
    setIsLoading(false);
  }, [refreshCourseList, refreshTrainingRecordList]);

  if (isLoading) {
    return <LargeLoadingIndicator />;
  }

  const userAdminCourses = UserHasPermission(currentUser, 'CreateEditCourses')
    ? courses?.courses
    : courses?.courses.filter((c) => c.reporters.some((r) => r.id === currentUser.id));

  return (
    <div>
      <TopBarContainer>
        <HeaderContainer>
          <Header2WithPadding>Your Training Courses</Header2WithPadding>
        </HeaderContainer>
        <NavLinkContainer>
          <NavLink to={`/user/${currentUser.id}/training-record/create`}>
            Record new training course
          </NavLink>
        </NavLinkContainer>
      </TopBarContainer>
      {trainingRecordList == null ? (
        <p>User has no courses</p>
      ) : (
        <>
          <TableHeaderGroup>
            <TableHeader>Course name</TableHeader>
            <TableHeader>Completion date</TableHeader>
            <TableHeader>Expiry date</TableHeader>
            <TableHeader>Notes</TableHeader>
          </TableHeaderGroup>
          {trainingRecordList.courseList.map((course) => (
            <UserCourseTable course={course} />
          ))}
        </>
      )}
      {userAdminCourses && userAdminCourses.length > 0 && (
        <>
          <TopBarContainer>
            <HeaderContainer>
              <Header2WithPadding>All Training Courses</Header2WithPadding>
            </HeaderContainer>
            <NavLinkContainer>
              <NavLink to={`/course/create`}>Create new training course</NavLink>
            </NavLinkContainer>
          </TopBarContainer>
          <AdminCourseTable coursesWithExpiredCount={userAdminCourses} />
        </>
      )}
    </div>
  );
};

const TableHeaderGroup = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-evenly;
  padding: ${spacingS};
  padding-left: ${spacingL};
  padding-bottom: 0;
`;

const TableHeader = styled.th`
  margin-bottom: ${spacingXXS};
  text-align: center;
  width: 25%;
  font: ${fontParagraph};
`;

const Header2WithPadding = styled(Header2)`
  padding-top: ${spacingM};
`;

const HeaderContainer = styled.div`
  display: flex;
  text-align: left;
`;

const TopBarContainer = styled.div`
  display: inline-flex;
  flex: 1;
  justify-content: space-between;
  width: 100%;
  align-content: stretch;
  align-items: stretch;
`;

const NavLinkContainer = styled.div<{ active?: boolean }>`
  display: flex;
  &:not(:last-of-type) {
    margin-right: ${spacingS};
  }
  text-align: right;
`;

const NavLink = styled(Link)`
  color: ${peacock};
  font-size: ${fontBase};
  text-decoration: none;
  &:hover {
    color: ${peacockShade};
  }
  &:hover,
  &:focus {
    opacity: 1;
  }
  align-self: flex-end;
  padding-bottom: ${spacingXS};
`;
