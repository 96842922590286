import React from 'react';
import styled from 'styled-components/macro';
import {
  dangerButton,
  dangerButtonHover,
  midnight,
  midnightLightTint,
  olaf,
  peacock,
  peacockShade,
  secondaryButton,
  secondaryButtonHover,
  successButton,
  successButtonHover,
  warningButton,
  warningButtonHover,
} from '../styling/colours';
import { fontHeading6 } from '../styling/fonts';
import { spacingXS, spacingXXS } from '../styling/spacing';
import { SmallLoadingIndicator } from './SmallLoadingIndicator';

type ButtonProps = {
  onClick?: () => void;
  autoFocus?: boolean;
  disabled?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
};

const BaseButton = styled.button.attrs((props) => ({ type: props.type || 'button' }))<ButtonProps>`
  padding: 4px;
  width: 133px;
  height: 54px;
  border-radius: 3px;
  border: none;
  opacity: 1;
  font: ${fontHeading6};
  color: ${olaf};
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const IconButton = styled.button.attrs((props) => ({ type: props.type || 'button' }))<ButtonProps>`
  width: 40px;
  height: 40px;
  border-radius: 3px;
  border: none;
  opacity: 1;
  color: ${olaf};
  cursor: pointer;
  text-align: center;

  & > * {
    vertical-align: middle;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const LinkButton = styled.button.attrs((props) => ({ type: props.type || 'button' }))<
  ButtonProps
>`
  border: none;
  background-color: ${olaf};
  font: ${fontHeading6};
  cursor: pointer;
  color: ${peacock};
  :hover {
    color: ${peacockShade};
  }
`;

export const PrimaryButton = styled(BaseButton)`
  background: ${peacock};

  &:hover {
    background: ${(props) => !props.disabled && peacockShade};
  }
`;

export const DangerButton = styled(BaseButton)`
  background: ${dangerButton};

  &:hover {
    background: ${(props) => !props.disabled && dangerButtonHover};
  }
`;

export const WarningButton = styled(BaseButton)`
  background: ${warningButton};

  &:hover {
    background: ${(props) => !props.disabled && warningButtonHover};
  }
`;

export const SuccessButton = styled(BaseButton)`
  background: ${successButton};

  &:hover {
    background: ${(props) => !props.disabled && successButtonHover};
  }
`;

export const SecondaryButton = styled(BaseButton)`
  background: ${secondaryButton};

  &:hover {
    background: ${(props) => !props.disabled && secondaryButtonHover};
  }
`;

export const CancelButton = styled(BaseButton)`
  background: ${olaf};
  color: ${midnight};
  border: solid ${midnight} 1px;

  &:hover {
    background: ${(props) => !props.disabled && midnightLightTint};
  }
`;

export const PrimaryIconButton = styled(IconButton)`
  background: ${peacock};

  &:hover {
    background: ${(props) => !props.disabled && peacockShade};
  }
`;

type ProgressButtonProps = ButtonProps & {
  inProgress: boolean;
  isSecondary?: boolean;
  children?: React.ReactNode;
};

export const ProgressButton: React.FC<ProgressButtonProps> = ({
  inProgress,
  className,
  disabled,
  children,
  isSecondary = false,
  ...otherProps
}) => {
  const ButtonComponent = isSecondary ? SecondaryButton : PrimaryButton;
  return (
    <ButtonComponent className={className} disabled={disabled || inProgress} {...otherProps}>
      {inProgress ? (
        <SpinnerContainer>
          <ButtonLoadingSpinner />
        </SpinnerContainer>
      ) : (
        children
      )}
    </ButtonComponent>
  );
};

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ButtonLoadingSpinner = styled(SmallLoadingIndicator)`
  height: 30px;
`;

type SubmitButtonProps = Omit<ButtonProps, 'type'> & {
  isSubmitting: boolean;
  children?: React.ReactNode;
};

export const SubmitButton: React.FC<SubmitButtonProps> = ({
  isSubmitting,
  disabled,
  children,
  ...otherProps
}) => (
  <ProgressButton
    inProgress={isSubmitting}
    disabled={disabled || isSubmitting}
    type="submit"
    {...otherProps}
  >
    {children}
  </ProgressButton>
);

/*
export const DangerButtonWithSpinner: React.FC<SubmitButtonProps> =
({
  isSubmitting,
  disabled,
  children,
  ...otherProps
}) => (
<ProgressButton
inProgress={isSubmitting}
  disabled={disabled || isSubmitting}
  type="submit"
{...otherProps}
  >
  {children}
</ProgressButton>
);
*/

type IconLinkButtonProps = ButtonProps & {
  title: string;
  isDanger?: boolean;
  children?: React.ReactNode;
};

export const IconLinkButton: React.FC<IconLinkButtonProps> = ({
  title,
  children,
  isDanger = false,
  ...buttonProps
}) => (
  <StyledIconLinkButton isDanger={isDanger} {...buttonProps}>
    <span>{title}</span>
    {children}
  </StyledIconLinkButton>
);

const StyledIconLinkButton = styled(LinkButton)<{ isDanger?: boolean }>`
  display: flex;
  align-items: center;
  & > *:not(:first-child) {
    margin-left: ${spacingXXS};
  }
  color: ${(props) => (props.isDanger ? dangerButton : peacock)};
  &:hover {
    color: ${(props) => (props.isDanger ? dangerButtonHover : peacockShade)};
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > *:not(:last-child) {
    margin-right: ${spacingXS};
  }
`;

export const ButtonRowSpaceBetween = styled(ButtonRow)`
  justify-content: space-between;
`;
