import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { peacock, peacockShade } from '../../styling/colours';
import { fontBase } from '../../styling/fonts';
import { pagePadding, pageWidth } from '../../styling/layout';
import { spacingS } from '../../styling/spacing';
import { logOut } from '../../utils/auth';
import { AuthContext } from '../auth/AuthContext';
import { IfUserHasPermission } from '../permission/IfUserHasPermission';
import { Avatar } from '../user/avatar/Avatar';
import { CurrentUserContext } from '../user/CurrentUserContext';

type NavBarProps = {
  hideNavLinks: boolean;
};

export const NavBar: React.FC<NavBarProps> = ({ hideNavLinks }) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { authProvider, acquireAccessToken } = useContext(AuthContext);

  return (
    <Header>
      <Navbar>
        <NavLinkContainer>
          <NavLink to="/">
            <Logo src={process.env.PUBLIC_URL + '/logo.svg'} alt={'FISH logo'} />
          </NavLink>
        </NavLinkContainer>
        {!hideNavLinks && (
          <>
            <NavLinkContainer>
              <NavLink to="/">Directory</NavLink>
            </NavLinkContainer>
            <NavLinkContainer>
              <NavLink to={`/user/${currentUser.id}/assessments`}>Desks</NavLink>
            </NavLinkContainer>
            <NavLinkContainer>
              <NavLink to={`/user/${currentUser.id}/courses`}>Training</NavLink>
            </NavLinkContainer>
            <IfUserHasPermission permission={'ReadDeskOverview'}>
              <NavLinkContainer>
                <NavLink to="/desk/overview">Desk Overview</NavLink>
              </NavLinkContainer>
            </IfUserHasPermission>
            <IfUserHasPermission permission={'ReadUserHotDeskStatus'}>
              <NavLinkContainer>
                <NavLink to="/hot-desk/overview">Hot Desk Overview</NavLink>
              </NavLinkContainer>
            </IfUserHasPermission>
          </>
        )}

        <NavbarWhitespace />

        <NavLinkContainer>
          <NavLink
            to={window.location.pathname}
            onClick={() => logOut(authProvider, acquireAccessToken)}
          >
            Logout
          </NavLink>
        </NavLinkContainer>
        <NavLinkContainer>
          <NavLink to={`/user/${currentUser.id}/view`}>
            <Avatar azureId={currentUser.azureUserId} name={currentUser.name} size="medium" />
          </NavLink>
        </NavLinkContainer>
      </Navbar>
    </Header>
  );
};

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
`;

const Navbar = styled.nav`
  display: flex;
  flex-grow: 1;
  height: 100%;
  max-width: ${pageWidth};
  padding: 0 ${pagePadding};
`;

const Logo = styled.img`
  width: 75px;
  vertical-align: middle;
  margin-right: 50px;
`;

const NavLinkContainer = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  height: 100%;
  &:not(:last-of-type) {
    margin-right: ${spacingS};
  }
`;

const NavLink = styled(Link)`
  color: ${peacock};
  font-size: ${fontBase};
  text-decoration: none;
  &:hover {
    color: ${peacockShade};
  }
  &:hover,
  &:focus {
    opacity: 1;
  }
`;

const NavbarWhitespace = styled.div`
  flex: 1;
`;
