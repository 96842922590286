import React from 'react';
import { Alert, AlertType } from './shared/Alert';

export const ErrorPage = () => {
  return (
    <Alert title={'Error'} type={AlertType.Error}>
      An error has occurred. Please contact{' '}
      <a href={'mailto:helpdesk@ghyston.com?subject=FISH Error'}>helpdesk@ghyston.com</a> for
      assistance
    </Alert>
  );
};
